import { Box } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { IRatingReport } from 'models/CompanyDetails.model';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RiskCard, RiskContainer, RiskHeading } from './RiskCard/RiskCard';
import { textStyle } from '../../../../theme/themeConstants';

interface Props {
  isMarketpage?: boolean;
  explanationText?: string;
  report: { rating: IRatingReport['rating']; createdAt?: IRatingReport['createdAt'] } | undefined;
  heading: string;
  tooltip?: string;
  headerPadding?: number;
  isRiskAnalysisPage?: boolean;
}
export const Rating: React.FC<Props> = ({
  isMarketpage,
  explanationText,
  report,
  heading,
  tooltip,
  headerPadding = 1,
  isRiskAnalysisPage,
}: Props) => {
  const t = useTranslations();
  return (
    <RiskCard
      isMarketpage={isMarketpage}
      timeOfMeasurement={report?.createdAt}
      isRiskAnalysisPage={isRiskAnalysisPage}
      isRating
    >
      <RiskHeading>
        <Box pt={headerPadding}>{heading}</Box>
        {tooltip && (
          <Box position="absolute" right={1} top={1}>
            <PopoverComponent
              text={tooltip}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} display="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          </Box>
        )}
      </RiskHeading>

      <RiskContainer>
        <Box pt={isMarketpage ? '15px' : '10px'} h={'100%'}>
          <TextComponent
            as="div"
            {...textStyle.sizeXxl}
            fontWeight={FONT_WEIGHT_BOLD}
            color={isMarketpage ? 'brand.default' : 'text.primary'}
          >
            {report?.rating ?? '–'}
          </TextComponent>
          {isMarketpage && (
            <TextComponent
              as="div"
              {...textStyle.sizeS}
              fontWeight={FONT_WEIGHT_BOLD}
              color="text.tertiary"
              mt={'25px'}
            >
              {explanationText}
            </TextComponent>
          )}
        </Box>
      </RiskContainer>
    </RiskCard>
  );
};
