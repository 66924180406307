import { AxiosResponse } from 'axios';
import normalize from 'json-api-normalizer';
import { createMutation, createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { ApiResponse } from 'models/ApiResponse';
import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { deepCamelcaseKeys } from 'utils/object';

import { Signee } from '../../../../models/types/Signee.type';
import endpoints from '../../endpoints';

interface InquiryQueryVariables {
  id: string;
}

interface IndicativeConditionsSuggestionResponse {
  factoringFee: string;
  factoringLine: number;
  payoutRatio: string;
}

export type IndicativeConditionsApiDataProps = {
  'offer-factoring-fee': string;
  'offer-factoring-line': number;
  'offer-payout-ratio': string;
};

export const useInquiry = createQuery<ApiResponse, InquiryQueryVariables>({
  primaryKey: queryKeys.inquiries.details,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.DETAILS.compose({
        params: { id: variables.id },
      }),
    ),
});

interface InquirySigneesQueryResponse {
  data: {
    customer: Signee[];
    legalRepresentative: Signee[];
  };
}

export const useInquirySignees = createQuery<InquirySigneesQueryResponse, InquiryQueryVariables>({
  primaryKey: queryKeys.inquiries.signees,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.SIGNEES.compose({
        params: { id: variables.id },
      }),
    ).then((response) => ({
      ...response,
      data: normalize(response.data, {
        camelizeTypeValues: false,
      }),
    })),
});

export const useGetIndicativeConditionSuggestion = createQuery<
  IndicativeConditionsSuggestionResponse,
  InquiryQueryVariables
>({
  primaryKey: queryKeys.partners.cbBank.indicativeConditionsSuggestion,
  queryFn: async ({ queryKey: [_, variables] }) => {
    const response = await EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.INDICATIVE_CONDITIONS_SUGGESTION.compose({
        params: { id: variables.id },
      }),
    );

    return deepCamelcaseKeys(response.data.data.attributes);
  },
  refetchOnWindowFocus: false,
});

export const useCreateCbBankIndicativeConditionsMutation = createMutation({
  mutationFn: async (variables: {
    inquiryId: string;
    apiData: IndicativeConditionsApiDataProps;
  }) => {
    return EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.INDICATIVE_CONDITIONS.compose({
        params: {
          id: variables.inquiryId,
        },
      }),
      {
        data: {
          attributes: {
            factoring_fee: variables.apiData[OFFER_FACTORING_FEE],
            factoring_line: variables.apiData[OFFER_FACTORING_LINE],
            payout_ratio: variables.apiData[OFFER_PAYOUT_RATIO],
          },
        },
      },
    );
  },
});

export const useCbBankRepresentativesSignatureApproval = createMutation<
  unknown,
  InquiryQueryVariables
>({
  mutationKey: [queryKeys.partners.cbBank.representativesSignaturesApproval],
  mutationFn: ({ id }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.REPRESENTATIVES_SIGNATURES_APPROVAL.compose({
        params: { id },
      }),
    ),
});

export const useGenerateCbBankContract = createMutation<
  AxiosResponse<undefined>,
  InquiryQueryVariables
>({
  mutationKey: [queryKeys.partners.cbBank.generateContract],
  mutationFn: ({ id }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.CONTRACTS.GENERATE.compose({
        params: { id },
      }),
    ),
});

export const useCancelInquiry = createMutation<ApiResponse, InquiryQueryVariables>({
  mutationKey: [queryKeys.inquiries.cancel],
  mutationFn: ({ id }) =>
    EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.BFS.CANCEL.compose({ params: { id } }),
    ),
});

export const useDzbResendCustomerInvitation = createMutation<
  AxiosResponse<number>,
  InquiryQueryVariables
>({
  mutationKey: [queryKeys.partners.dzb.resendCustomerInvitation],
  mutationFn: ({ id }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.DZB.CUSTOMER.RESEND_INVITATION.compose({ params: { id } }),
    ),
});
