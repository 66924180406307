import React, { ReactElement } from 'react';

import _get from 'lodash/get';

import { AriaDescription } from 'components/AriaDescription';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { List, ListItem } from 'modules/Footer/FooterContent/FooterLinkList/List';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';
import { scrollTop } from 'utils/scroll';

const { privacyPolicy, impressum, termsAndConditions, genderNote } = translations.footerLinks;

interface FooterLink {
  href: string;
  text: string;
}

const LinkList = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();

  const footerLinks: FooterLink[] = [
    {
      href: _get(paths, `${selectedInquiryType}.termsAndConditions`) || paths.termsAndConditions,
      text: termsAndConditions,
    },
    {
      href: _get(paths, `${selectedInquiryType}.privacyPolicy`) || paths.privacyPolicy,
      text: privacyPolicy,
    },
    {
      href: _get(paths, `${selectedInquiryType}.imprint`) || paths.imprint,
      text: impressum,
    },
    {
      // @ts-ignore
      // FIXME this is incorrectly typed
      href: _get(paths, `${selectedInquiryType}.genderNote`),
      text: genderNote,
    },
  ];

  return (
    <List>
      {footerLinks.map(({ href, text }) => {
        const translation = t(text);
        let url, children;

        if (typeof translation === 'object') {
          let element: ReactElement<{ href: string; children: string }>;
          // Check if the translation is an array of react elements, for example a newline and a link and extract just the link.
          if (Array.isArray(translation)) {
            element = (translation as Array<any>).find((item) => Object.hasOwn(item, 'props'));
          } else {
            element = translation;
          }

          url = element.props.href;
          children = element.props.children;
        } else {
          if (href) {
            url = href;
            children = translation;
          }
        }

        if (url && children) {
          return (
            <ListItem key={url}>
              <a href={url} target="_blank" rel="noreferrer" onClick={() => scrollTop()} key={text}>
                {children}
                <AriaDescription>{t('other.opensInANewTab')}</AriaDescription>
              </a>
            </ListItem>
          );
        }

        return null;
      })}
    </List>
  );
};

export default LinkList;
