import React from 'react';

import { Asset } from 'components/Asset';
import Page from 'components/Page/Page';
import { translations } from 'new/form/common/types';
import Header from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage/Header';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

import { HausbankFinalDocumentsAction } from './HausbankFinalDocumentsAction';
import { HausbankFinalOfferAction } from './HausbankFinalOfferAction';
import { HausbankFinalRequestAction } from './HausbankFinalRequestAction';
import { HausbankFinalSignatureAction } from './HausbankFinalSignatureAction';
import { HausbankFinalPageList } from './styles';

export const HausbankFinalPage = () => {
  const t = useTranslations();
  const { heading } = translations.inquiryType.hausbank.pages.final;
  useScrollToTop();

  return (
    <Page>
      <Header
        asset={<Asset type="inquiry" value={InquiryAssetKeys.HEADER} />}
        heading={t(heading)}
        sx={{ pb: 16, mb: 16, borderBottom: '1px solid', borderColor: 'border.lightGrey' }}
      />
      <HausbankFinalPageList>
        <HausbankFinalRequestAction />
        <HausbankFinalOfferAction />
        <HausbankFinalDocumentsAction />
        <HausbankFinalSignatureAction />
      </HausbankFinalPageList>
    </Page>
  );
};
