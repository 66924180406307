import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { borderRadius, chakraDefaultFocusStyles } from '../../../themeConstants';

const helpers = createMultiStyleConfigHelpers([
  'RadioButtonInput',
  'RadioButtonOuter',
  'RadioButtonInner',
  'RadioButtonLabel',
  'RadioButtonContainer',
]);

const RadioButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    RadioButtonInput: {
      '&:focus-within + label': {
        boxShadow: chakraDefaultFocusStyles.boxShadow,
      },
    },
    RadioButtonOuter: {
      alignItems: 'center',
      borderRadius: borderRadius.circle,
      borderStyle: 'solid',
      borderWidth: '2px',
      cursor: 'pointer',
      height: '1.5rem',
      marginX: '0.7rem',
      justifyContent: 'center',
      width: '1.5rem',
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    RadioButtonInner: {
      cursor: 'pointer',
      borderRadius: borderRadius.circle,
      boxShadow: 'none',
      height: '1.2rem',
      width: '1.2rem',
      border: '3px solid',
      _checked: {
        bg: 'background.white',
        boxShadow: 'none',
      },
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    RadioButtonLabel: {
      cursor: 'pointer',
      maxW: '12rem',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      hyphens: 'auto',
      paddingRight: '0.7rem',
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    RadioButtonContainer: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      flexGrow: 0,
      flexShrink: 0,
      minWidth: '6.5rem',
      minHeight: '3rem',
      width: 'auto',
      height: 'auto',
      borderRadius: '2.5rem',
      border: '2px solid',
      _disabled: {
        cursor: 'not-allowed',
      },
      _hover: {
        '.checkbox-inner': {
          backgroundColor: 'background.white',
        },
      },
    },
  },
  variants: {
    checked: {
      RadioButtonOuter: {
        borderColor: 'border.white',
      },
      RadioButtonInner: {
        borderColor: 'brand.default',
      },
      RadioButtonLabel: {
        color: 'white',
      },
      RadioButtonContainer: {
        borderColor: 'brand.default',
        backgroundColor: 'brand.default',
        _hover: {
          backgroundColor: 'brand.default_L50',
        },
      },
    },
    notChecked: {
      RadioButtonOuter: {
        borderColor: 'border.lightGrey',
      },
      RadioButtonInner: {
        borderColor: 'white',
      },
      RadioButtonLabel: {
        color: 'text.tertiary',
      },
      RadioButtonContainer: {
        borderColor: 'background.darkGrey',
        backgroundColor: 'background.white',
        _hover: {
          backgroundColor: 'brand.default_L93',
        },
      },
    },
  },
});

export default RadioButton;
