import React, { ReactNode } from 'react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { HeadingComponent, HeadingVariantType } from 'theme/components/Heading';

type FileSectionTitleProps = {
  children: ReactNode;
  mb?: number;
  variant?: HeadingVariantType;
};

const FileSectionTitle = ({ children, mb = 6, variant = 'secondary' }: FileSectionTitleProps) => (
  <HeadingComponent variant={variant} fontWeight={FONT_WEIGHT_BOLD} color="brand.default" mb={mb}>
    {children}
  </HeadingComponent>
);

export default FileSectionTitle;
