import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import { TextAreaWithField } from 'components/TextArea';
import Unit from 'components/Unit/Unit';
import { MAX_ZIP_CODE_LENGTH } from 'constants/globalConstants';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import { translations } from 'new/form/common/types';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useYearValidation } from './hooks';

export const GeneralData = () => {
  const t = useTranslations();

  const {
    address,
    zipCode,
    city,
    description,
    lotSize,
    year,
    yearOfModernization,
    plannedProjectStart,
    plannedProjectEnd,
  } = mittweidaFields.financingNeedPage.objectInformationSection;

  const {
    lotSize: lotSizeTranslations,
    plannedProjectStart: plannedProjectStartTranslations,
    plannedProjectEnd: plannedProjectEndTranslations,
  } = translations.inquiryType.mittweida.pages.financingNeed.sections.objectInformation.fields;

  const { handleYearChange, validateYearOfModernization } = useYearValidation(
    year,
    yearOfModernization,
  );

  return (
    <>
      <FormRow>
        <InputWithField name={address} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={zipCode}
          type={'tel'}
          pattern={'[0-9]*'}
          inputMaxLimit={MAX_ZIP_CODE_LENGTH}
          separateThousands={false}
        />
        <InputWithField name={city} />
      </FormRow>
      <FormRow>
        <TextAreaWithField name={description} />
      </FormRow>
      <FormRow>
        <InputWithField
          name={lotSize}
          type={'number'}
          sideSymbol={() => <Unit type={FIELD_UNIT_TYPE.SQUARE_METERS} />}
          tooltip={t(lotSizeTranslations.tooltip)}
        />
      </FormRow>
      <FormRow>
        <InputWithField
          name={year}
          type={'number'}
          separateThousands={false}
          onChange={handleYearChange}
        />
        <InputWithField
          name={yearOfModernization}
          type={'number'}
          separateThousands={false}
          validate={validateYearOfModernization}
          optional
        />
      </FormRow>
      <FormRow alignItems={'flex-end'}>
        <DayPickerWithField
          name={plannedProjectStart}
          minDate={InquiryConstants.MIN_PLANNED_DATE}
          maxDate={InquiryConstants.MAX_PLANNED_DATE}
          tooltip={t(plannedProjectStartTranslations.tooltip)}
          optional
        />
        <DayPickerWithField
          name={plannedProjectEnd}
          minDate={InquiryConstants.MIN_PLANNED_DATE}
          maxDate={InquiryConstants.MAX_PLANNED_DATE}
          tooltip={t(plannedProjectEndTranslations.tooltip)}
          optional
        />
      </FormRow>
    </>
  );
};
