import React from 'react';

import { useSelector } from 'react-redux';

import ProgressStage from 'components/ProgressStage';
import { useFormConfig } from 'config/formConfig/hooks';
import {
  PURPOSE_KIND__KFINANZ,
  USER_PROFILE,
  CONTACT_PERSON_DETAILS,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import { choosePurposeKindSpecificComponent } from 'shared/choosePurposeKindSpecificComponent';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { getCurrentStageSelector, getStagesSelector } from 'store/progress/selectors';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

import Wrapper from './Wrapper';
import { chooseSelectedInquiryTypeSpecificComponent } from '../../shared/chooseSelectedInquiryTypeSpecificComponent';

const ProgressBar = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const isProfiInquiry = selectedInquiryType === InquiryType.profiRLL;
  const inquiryId = useSelector(getInquiryIdSelector);
  const contentIsScrolledToTop = useIsScrolledToTop();
  const currentStage = useSelector(getCurrentStageSelector);
  const progress = useSelector(getStagesSelector);
  const { stepSegments } = useInquiryStepData();
  const stepsForProgressBar = stepSegments.filter(
    (segment) =>
      segment.progressBarTitle &&
      !(
        (isProfiInquiry && segment.id === CONTACT_PERSON_DETAILS && !inquiryId) ||
        (isProfiInquiry && segment.id === USER_PROFILE && inquiryId)
      ),
  );

  if (stepsForProgressBar.length === 0) {
    return null;
  }

  return (
    <Wrapper scrollToTop={contentIsScrolledToTop} data-testid="progress-bar">
      {stepsForProgressBar.map((stage, index) => (
        <ProgressStage
          key={stage.progressBarTitle}
          stepName={t(stage.progressBarTitle)}
          validSections={progress[stage.id].currentStep}
          numberOfSections={progress[stage.id].stepCount}
          stageIndex={index}
          active={index <= currentStage}
          isCurrentStage={index === currentStage}
        />
      ))}
    </Wrapper>
  );
};

/**
 * There is an updated progress bar for new InquiryTypes which is rendered instead of this old ProgressBar. So
 * for all newly created InquiryTypes we return null here.
 */
const InquirySpecificProgressBar = chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiMittweida]: null,
  [InquiryType.mmv]: null,
  default: ProgressBar,
});

export default choosePurposeKindSpecificComponent({
  [PURPOSE_KIND__KFINANZ]: null,
  default: InquirySpecificProgressBar,
});
