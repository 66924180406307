/**
 * Generates a string of one or multiple element IDs.
 *
 * @param {string} fieldName - Unique string to identify the field or element.
 * @param {string} infix - Additional string to narrow down the scope of the element.
 * @param {any[]} [items] - Array of items to generate IDs for. Can be used for example if multiple other elements refer to this element by different IDs differentiated by an index.
 * @returns {string} A string of one or multiple element IDs.
 */
export const buildElementIdString = (fieldName: string, infix: string, items?: any) => {
  if (Array.isArray(items)) {
    const ids = Object.keys(items).map((index) => `${fieldName}-${infix}-${index}`);
    return ids.join(' ');
  }
  return `${fieldName}-${infix}`;
};
