import React from 'react';

import _isNil from 'lodash/isNil';
import { bool, func, string, object } from 'prop-types';
import NumberFormat from 'react-number-format';

import { getInputDescribedByIds } from 'utils/accessibility/getInputDescribedByIds';

import InputWrapper from './InputWrapper';

export const removeMinuses = (value) => value.replace('-', '');

const MaskedInput = ({
  label,
  onChange,
  placeholder,
  onBlur,
  onFocus,
  separateThousands,
  sideSymbol,
  value,
  disabled,
  valid,
  autoComplete,
  'data-name': dataName,
  decimalProps,
  name,
  errorMessage,
  sideSymbolIsButton,
}) => {
  /**
   * We need to check for nil values because Math.abs(undefined) returns NaN and we cannot really do any calculations
   * with NaN throughout the application
   * @param floatValue
   * @param integer
   */
  const handleChange = ({ floatValue, value: integer }) => {
    if (decimalProps?.decimalSeparator) {
      if (_isNil(floatValue)) {
        onChange('');
      } else {
        onChange(Math.abs(floatValue));
      }
    } else {
      if (_isNil(integer)) {
        onChange('');
      } else {
        onChange(removeMinuses(integer));
      }
    }
  };
  return (
    <InputWrapper sideSymbol={sideSymbol} valid={valid} disabled={disabled}>
      <NumberFormat
        id={`input-${name}`}
        name={name}
        data-testid={dataName}
        value={typeof value === 'object' ? value.value : value}
        thousandSeparator={separateThousands ? ' ' : ''}
        onValueChange={handleChange}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        onKeyPress={(e) => {
          if (['Enter', '-'].includes(e.key)) e.preventDefault();
        }}
        autoComplete={autoComplete}
        data-name={dataName}
        {...decimalProps}
        aria-describedby={getInputDescribedByIds(name, errorMessage, !sideSymbolIsButton)}
      />
    </InputWrapper>
  );
};

MaskedInput.propTypes = {
  label: string,
  onChange: func,
  placeholder: string,
  onBlur: func,
  onFocus: func,
  separateThousands: bool,
  sideSymbol: func,
  value: string,
  disabled: bool,
  valid: bool,
  autoComplete: string,
  'data-name': string,
  decimalProps: object,
  name: string,
  sideSymbolIsButton: bool,
};

MaskedInput.defaultProps = {
  label: null,
  onChange: () => null,
  placeholder: null,
  onBlur: () => null,
  onFocus: () => null,
  separateThousands: false,
  sideSymbol: () => null,
  value: '',
  disabled: false,
  valid: false,
  autoComplete: 'off',
  'data-name': undefined,
  decimal: {},
  name: null,
  sideSymbolIsButton: false,
};

export default MaskedInput;
