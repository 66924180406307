import { useFormState } from 'react-final-form';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import StaticField from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import ContactPersonDetailsHeader from 'pages/inquiryFlow/CompanyDetails/ContactPersonDetailsHeader/ContactPersonDetailsHeader';
import { useTranslations } from 'utils/hooks/useTranslations';

import { MittweidaFieldTypes } from '../../fields';

export const MittweidaStaticUserProfileStep = () => {
  const t = useTranslations();
  const { values } = useFormState<MittweidaFieldTypes>();
  const { gender, firstName, lastName, email, phone } = values.userProfilePage.contactPersonSection;
  const { contactPerson } = translations.inquiryType.mittweida.pages.userDetails.sections;
  const genderText = gender ? t(contactPerson.gender.options[gender]) : '-';

  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mittweida.pages.userDetails.progressBarTitle
      }
      hero={<ContactPersonDetailsHeader />}
    >
      <FormSection
        title={t(translations.inquiryType.mittweida.pages.userDetails.sections.contactPerson.title)}
        name={'contactPerson'}
        sectionNumber={1}
      >
        <FormRow>
          <StaticField caption={t(contactPerson.gender.caption)} text={genderText} />
          <StaticField caption={t(contactPerson.name.caption)} text={`${firstName} ${lastName}`} />
        </FormRow>
        <FormRow>
          <StaticField caption={t(contactPerson.email.caption)} text={email} />
        </FormRow>
        <FormRow>
          <StaticField caption={t(contactPerson.phone.caption)} text={phone} />
        </FormRow>
      </FormSection>
    </Page>
  );
};
