import { Box, Flex } from '@chakra-ui/react';

import { SummaryPageField } from 'new/summary/hooks/useBuildFieldsForSummary';

type Props = {
  summaryPageField: SummaryPageField;
};
export const SummaryField = ({ summaryPageField }: Props) => {
  const textDisplay = summaryPageField.render ? (
    summaryPageField.render(summaryPageField.text)
  ) : (
    <Box as={'dd'} data-testId={summaryPageField.testId} color={'text.primary'}>
      {summaryPageField.text}
    </Box>
  );

  return (
    <Flex as={'dl'} flexDirection={'column'} gap={3}>
      <Box as={'dt'} color={'text.tertiary'}>
        {summaryPageField.caption}
      </Box>
      {textDisplay}
    </Flex>
  );
};
