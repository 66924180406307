import React from 'react';

import { FieldWrapper } from 'components/ControlWrapper';
import PriceInputAdapter from 'components/PortalFormFields/InputFields/PriceInput/PriceInputAdapter';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';

const AmountComponent = ({
  input,
  meta,
  errorMessage,
  caption,
  tooltip,
  optional,
  placeholder,
  onChange,
}) => {
  return (
    <FieldWrapper
      caption={caption}
      tooltip={tooltip}
      errorMessage={errorMessage}
      optional={optional}
      name={input.name}
    >
      <PriceInputAdapter
        label={caption}
        input={input}
        meta={meta}
        placeholder={placeholder}
        onChange={onChange ?? input.onChange}
      />
    </FieldWrapper>
  );
};

AmountComponent.propTypes = FinalFormFieldPropTypes;

export const AmountWithField = withFormField(AmountComponent);
