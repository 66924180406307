import { Td, Tr } from '@chakra-ui/react';

import { SIGNEE_STATUS } from 'models/SigneeStatus.type';
import { Signee } from 'models/types/Signee.type';
import { SIGN_CONTRACT_BUTTON } from 'modules/Inquiry/Form/formFields';
import Button from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SignContractStepSigneeCopyLink } from './SignContractStepSigneeCopyLink';

const getStatusLabel = (status: SIGNEE_STATUS | null) => {
  if (status === SIGNEE_STATUS.INVITATION_SENT) {
    return 'table.statuses.invitationSent';
  }
  if (status === SIGNEE_STATUS.READY_FOR_SIGNATURE) {
    return 'table.statuses.readyForSignature';
  }
  if (status === SIGNEE_STATUS.SUCCESSFULLY_SIGNED) {
    return 'table.statuses.successfullySigned';
  }
  if (status === SIGNEE_STATUS.UNDER_REVIEW) {
    return 'table.statuses.underReview';
  }
  if (status === null) {
    return 'table.statuses.invitationPending';
  }
  return 'table.statuses.failure';
};

const highlightedRowStyles = {
  border: '3px solid',
  borderColor: 'brand.default',
  boxShadow: 'highlightedRow',
  // default background color hides the shadow
  pos: 'relative',
  zIndex: '1',
  td: {
    bgColor: 'transparent',
  },
};

const statusesToHighlight = [SIGNEE_STATUS.READY_FOR_SIGNATURE, SIGNEE_STATUS.INVITATION_SENT];

interface SignContractStepSigneeProps extends Signee {
  isReadonly?: boolean;
}

export const SignContractStepSignee = ({
  id,
  attributes,
  isReadonly,
}: SignContractStepSigneeProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.signContract');

  const showSignButton = attributes.status === SIGNEE_STATUS.READY_FOR_SIGNATURE && !isReadonly;
  const showCopyButton = attributes.status === SIGNEE_STATUS.INVITATION_SENT;

  return (
    <Tr sx={statusesToHighlight.includes(attributes.status) ? highlightedRowStyles : undefined}>
      <Td>
        {attributes.firstName} {attributes.lastName}
      </Td>
      <Td>
        {attributes.expirationInDays
          ? t('table.deadline', { count: attributes.expirationInDays })
          : ''}
      </Td>
      <Td>{t(getStatusLabel(attributes.status))}</Td>
      <Td isNumeric>
        {showSignButton ? (
          <Button
            as="a"
            size="sm"
            href={attributes.url}
            width="full"
            data-testid={id ? `${SIGN_CONTRACT_BUTTON}-${id}` : undefined}
            target="_blank"
          >
            {t('table.sign')}
          </Button>
        ) : null}
        {showCopyButton ? <SignContractStepSigneeCopyLink url={attributes.url} /> : null}
      </Td>
    </Tr>
  );
};
