import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { defaultFocusStyles, transition, visuallyHiddenStyles } from 'theme/themeConstants';

export const StyledElementsPerPageInput = styled.input<{ checked: boolean }>`
  ${visuallyHiddenStyles}

  &:focus + label {
    ${defaultFocusStyles}
  }
`;

export const StyledElementsPerPageLabel = styled.label<{ active: boolean }>`
  color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.text.tertiary)};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  border-left: 1px solid ${({ theme }) => theme.colors.border.lightGrey};
  padding: 0 1rem;
  transition: color ${transition.default};

  &:focus,
  &:hover {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary : rgba(theme.colors.primary, 0.8)};
  }
`;

export const StyledElementsPerPageList = styled.fieldset`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  ${mqMedium`
    margin-top: 0;
  `}
`;

export const StyledElementsPerPageOption = styled.div`
  &:first-of-type {
    ${StyledElementsPerPageLabel} {
      border: 0;
    }
  }
`;
