import { chakra, OrderedList } from '@chakra-ui/react';

export const HausbankFinalPageTitle = chakra('p', {
  baseStyle: {
    fontSize: '20px',
    lineHeight: '1.5',
    color: 'text.secondary',
    marginBottom: 4,
    '-webkit-font-smoothing': 'antialiased',
  },
});

export const HausbankFinalPageList = chakra(OrderedList, {
  baseStyle: {
    listStyleType: 'none',
    marginInlineStart: 0,

    li: {
      borderBottom: '1px solid',
      borderColor: 'border.lightGrey',
      marginBottom: 16,

      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  },
});
