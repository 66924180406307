import React, { createContext, useContext } from 'react';

import { isEqual } from 'lodash';

import { PARTNERS } from 'constants/partner';
import { COMPEON_PARTNER, USER_PROFILE_COMPANY_CREFO_ID } from 'modules/Inquiry/Form/formFields';
import { useInquiryStepData } from 'modules/Inquiry/inquiryNavigation/useInquiryStepData';
import { getParsedItemFromSessionStorage } from 'utils/sessionStorage/helpers';

type InquiryValuesContextType = {
  inquiryValues: Record<string, any>;
  setInquiryValues: (values: Record<string, any>) => void;
};

const InquiryValuesContext = createContext<InquiryValuesContextType>({
  inquiryValues: {},
  setInquiryValues: () => {},
});

export const useInquiryValuesContext = () => {
  const context = useContext(InquiryValuesContext);

  if (!context) {
    throw new Error('useInquiryValuesContext must be used within an InquiryValuesProvider');
  }

  return context;
};

export const useInquiryValues = (partnerShortName: PARTNERS | undefined): any => {
  const { inquiryValues } = useInquiryValuesContext();
  const initialInquiryValues = {
    [COMPEON_PARTNER]: partnerShortName,
    [USER_PROFILE_COMPANY_CREFO_ID]: 'not-found',
  };

  return {
    ...initialInquiryValues,
    ...inquiryValues,
  } as any;
};

const getInitialInquiryValues = (stepsWithForm: string[]) => {
  let sessionStorageInquiryValuesState: Record<string, any> = {};
  for (const stepName of stepsWithForm) {
    sessionStorageInquiryValuesState = {
      ...sessionStorageInquiryValuesState,
      ...getParsedItemFromSessionStorage(stepName),
    };
  }
  return sessionStorageInquiryValuesState;
};

export const InquiryValuesProvider = ({ children }: { children: React.ReactNode }) => {
  const { stepsWithForm } = useInquiryStepData();

  const [inquiryValues, setInquiryValues] = React.useState<Record<string, any>>(() =>
    getInitialInquiryValues(stepsWithForm),
  );

  const updateFormValues = (newValues: Record<string, any>) => {
    if (!isEqual(newValues, inquiryValues)) {
      setInquiryValues((prevValues) => ({ ...prevValues, ...newValues }));
    }
  };

  return (
    <InquiryValuesContext.Provider value={{ inquiryValues, setInquiryValues: updateFormValues }}>
      {children}
    </InquiryValuesContext.Provider>
  );
};
