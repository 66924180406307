import { ReactNode } from 'react';

import { LinkIcon } from '@chakra-ui/icons';
import { Link, LinkProps } from '@chakra-ui/react';

import { chakraDefaultFocusStyles } from 'theme/themeConstants';

type VariantType = 'primary' | 'secondary';

type Props = {
  variant?: VariantType;
  children: ReactNode;
  showLinkIcon?: boolean;
} & Pick<LinkProps, 'href' | 'sx' | 'tabIndex'>;

export const LinkComponent = ({
  variant = 'primary',
  children,
  showLinkIcon = false,
  ...props
}: Props) => {
  return (
    <Link
      variant={variant}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        width: 'fit-content',
        _focus: {
          boxShadow: chakraDefaultFocusStyles.boxShadow + ' !important',
          borderRadius: chakraDefaultFocusStyles.borderRadius + ' !important',
        },
      }}
      {...props}
    >
      {children}
      {showLinkIcon && <LinkIcon boxSize={3} />}
    </Link>
  );
};
