import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { VisuallyHidden } from 'components/VisuallyHidden';
import { ariaLiveShiftAnnouncement } from 'store/accessibility/reducer';
import { selectAriaLiveAnnouncements } from 'store/accessibility/selectors';

export const AriaLive = () => {
  const dispatch = useDispatch();
  const [ariaLiveAnnouncement, setAriaLiveAnnouncement] = useState('');
  const ariaLiveAnnouncements = useSelector(selectAriaLiveAnnouncements);
  const setAnnouncementTimerActive = useRef(false);
  const resetTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const clearResetTimeout = () => {
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
    };

    // If there is already an announcement on screen or one coming up, do nothing
    if (ariaLiveAnnouncement || setAnnouncementTimerActive.current) {
      return;
    }

    // If there are no announcements queued, do nothing
    if (!ariaLiveAnnouncements.length) {
      return;
    }

    // Otherwise, set the announcement to the first item
    setAnnouncementTimerActive.current = true;
    // Reset clearing timeout so it can begin again
    clearResetTimeout();
    setTimeout(() => {
      setAriaLiveAnnouncement(ariaLiveAnnouncements[0]);
      dispatch(ariaLiveShiftAnnouncement());
      setAnnouncementTimerActive.current = false;

      // And after 3 seconds, clear it
      resetTimeoutRef.current = setTimeout(() => setAriaLiveAnnouncement(''), 3000);
    }, 2000);

    return () => {
      clearResetTimeout();
    };
  }, [ariaLiveAnnouncements, ariaLiveAnnouncement, dispatch]);

  return (
    <VisuallyHidden aria-atomic="true" aria-live="polite">
      {ariaLiveAnnouncement}
    </VisuallyHidden>
  );
};
