import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CaretDownFilledIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <polygon points="6,9 18,9 12,15" />
    </Icon>
  );
};

export default CaretDownFilledIcon;
