import { useCallback } from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import { useFinishObjectConfirmationMutation } from 'api/CompeonReverseApi/operation/queryHooks/inquiriesSpecializedMmv';
import { CheckboxWithField } from 'components/Checkbox';
import { StyledFileStatusLabel as StatusLabel } from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/styles';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import { FileType } from 'models/File.model';
import { CompeonDocument } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import ButtonComponent from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

// We use a static file here because we don't have a real file to show
// This file will be available to show once user submit the form
const STATIC_FILE: CompeonDocument = {
  id: 'mocked-id',
  createdAt: null,
  fileName: 'mocked-file-name',
};

interface ConfirmObjectTurnoverStepModalProps {
  isOpen?: boolean;
  onClose: (isSuccessful?: boolean) => void;
  inquiryId: string;
}

export const ConfirmObjectTurnoverStepModal = ({
  inquiryId,
  isOpen = false,
  onClose,
}: ConfirmObjectTurnoverStepModalProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.confirmObjectTurnover');
  const tUpload = useTranslations('components.upload');
  const { required, mustBeTrue } = useFieldValidators();
  const toasts = useToasts();

  const { mutateAsync, isLoading } = useFinishObjectConfirmationMutation({
    onError: () => {
      toasts.error({ description: t(translations.pages.errorPage.statuses.statusDefault) });
    },
  });

  const handleSubmit = useCallback(async () => {
    await mutateAsync({ inquiryId });
    onClose(true);
  }, [inquiryId, mutateAsync, onClose]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ confirmation: false }}
      render={({ handleSubmit, invalid }) => (
        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          title={t('modal.title')}
          footer={
            <Flex align="flex-start" w="full">
              <ButtonComponent
                isLoading={isLoading}
                isDisabled={invalid}
                onClick={handleSubmit}
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              >
                {t('modal.action')}
              </ButtonComponent>
            </Flex>
          }
        >
          <form onSubmit={handleSubmit}>
            <TextComponent mb="6">{t('modal.description')}</TextComponent>
            <Flex flexWrap="wrap" rowGap={4}>
              <UploadedFileBlock
                file={{ ...STATIC_FILE, fileName: t('fileName'), id: inquiryId }}
                canRemove={false}
                type={FileType.MMV_OBJECT_CONFIRMATION_FILLED_DOCUMENT}
                label={<StatusLabel>{tUpload('newFile')}</StatusLabel>}
              />
              <CheckboxWithField
                validate={combineValidators(required, mustBeTrue)}
                name="confirmation"
                text={t('modal.confirmation')}
                size="sm"
              />
            </Flex>
          </form>
        </ModalComponent>
      )}
    />
  );
};
