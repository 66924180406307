import styled, { css } from 'styled-components/macro';

import { borderRadius, defaultFocusStyles, transition } from 'theme/themeConstants';

export const StyledInputWrapper = styled.div`
  position: relative;

  input,
  textarea {
    width: 100%;
    min-height: 4.25rem;
    border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
    border-radius: ${borderRadius.default};
    padding: 1.25rem 2.25rem 1.25rem 1.25rem;
    transition: border-color ${transition.default};

    :focus {
      outline: 0;
      border-color: ${({ theme }) => theme.colors.primary};
    }

    ${({ hasError }: { hasError: boolean }) =>
      hasError &&
      css`
        border-color: ${({ theme }) => theme.colors.status.error};
      `}
  }

  textarea {
    resize: none;
  }
`;

export const StyledClearIcon = styled.button`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  position: absolute;
  top: 50%;
  right: 3rem;
  padding: 0.5rem;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.text.tertiary};

  &:focus {
    ${defaultFocusStyles}
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.lightGrey};
  }
`;

export const StyledInputIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  padding: 0.5rem;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.text.tertiary};
  pointer-events: none;
`;
