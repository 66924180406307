import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CompanyDetailsSection } from './sections/companyDetails';
import { CompletingCompanyDetailsSection } from './sections/completingCompanyDetailsSection';

export const MittweidaCompanyStep = () => {
  const t = useTranslations();
  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.mittweida.pages.companyDetails;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <CompanyDetailsSection />
      <CompletingCompanyDetailsSection />
    </Page>
  );
};
