import React from 'react';

import { Form as FinalForm } from 'react-final-form';

import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import { StyledTextArea } from './styles';

const TEXT_AREA_MAX_LENGTH = 1000;

interface Props {
  onSubmit: (internalComment: any) => void;
  isSendButtonDisabled: boolean;
}

const Form = ({ onSubmit, isSendButtonDisabled }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.communication.internalComments');
  const { required, maxLength } = useFieldValidators();

  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              if (promise)
                promise.then(() => {
                  form.restart();
                });
              return promise;
            }}
          >
            <StyledTextArea
              name="internalComment"
              validate={combineValidators(required, maxLength(TEXT_AREA_MAX_LENGTH))}
              caption={t('commentCaption')}
              placeholder={t('commentPlaceholder')}
            />
            <ButtonComponent
              mt={4}
              disabled={isSendButtonDisabled}
              type="submit"
              data-testid={'Comment-Button'}
            >
              {t('addButton')}
            </ButtonComponent>
          </form>
        )}
      />
    </>
  );
};

export default Form;
