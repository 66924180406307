import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid, not } from 'modules/Inquiry/Form/formConditions';
import { PARTNER_SPECIFIC_IBAN, USER_PROFILE_FOUNDING_YEAR } from 'modules/Inquiry/Form/formFields';
import ProfileIndustry from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import AlreadyCustomer from './AlreadyCustomer/AlreadyCustomer';
import CompanyInformationForm from './CompanyInformationForm';
import { ExcludingQuestions } from './ExcludingQuestions';
import FoundingMonth from './FoundingMonth';
import FoundingYear from './FoundingYear';
import LegalForm from './LegalForm/LegalForm';
import TurnoverClass from './TurnoverClass/TurnoverClass';

export const BfsCompletingCompanyDetails: React.FC = () => {
  const t = useTranslations();

  return (
    <FormSection
      name={FormSections.companyDetails}
      sectionNumber={2}
      title={t('pages.userProfile.sections.completingCompanyDetails.title')}
    >
      <CompanyInformationForm />

      <FormRow>
        <AlreadyCustomer />
      </FormRow>
      <FormRow>
        <InputWithField name={PARTNER_SPECIFIC_IBAN} optional />
      </FormRow>

      <Condition
        isBusinessCondition
        condition={not(fieldBusinessConditions.isAlreadyCompanyCustomer)}
      >
        <FormRow>
          <LegalForm />
        </FormRow>

        <ProfileIndustry />

        <FormRow>
          <TurnoverClass />
        </FormRow>
        <FormRow>
          <FoundingYear />

          <Condition condition={fieldIsValid(USER_PROFILE_FOUNDING_YEAR)}>
            <FoundingMonth />
          </Condition>
        </FormRow>
        <ExcludingQuestions />
      </Condition>
    </FormSection>
  );
};
