import { Grid } from '@chakra-ui/react';
import { WrappedComponentProps } from 'react-intl';
import { compose } from 'redux';

import Condition from 'components/Condition';
import PERMISSIONS from 'constants/user/permissions';
import disableInquiryDraft from 'modules/Inquiry/DraftMode/disableInquiryDraft';
import ForwardToBankAdvisor from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToBankAdvisor';
import ForwardToCompeon from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToCompeon';
import ForwardToInquiryManager from 'pages/operationPortal/InquiryDetails/Dashboard/Actions/ForwardToInquiryManager';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { HorizontalSection } from 'theme/components/HorizontalSection';
import { withIntl } from 'utils/intl';

import { useInquiryForwarding } from './useInquiryForwarding';

const ForwardInquiry = ({ intl: { formatMessage } }: WrappedComponentProps) => {
  const t = (msgId: string) =>
    formatMessage({ id: `pages.inquiryDetails.dashboard.forwardInquiry.${msgId}` });
  const { isForwardableForUser, hasBeenForwardedToCompeon, someForwardingVisible } =
    useInquiryForwarding();
  return (
    <Condition condition={someForwardingVisible}>
      <HorizontalSection label={t('title')}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(auto-fit, minmax(min(40%, 100%), 1fr))',
          }}
          gap={12}
        >
          <Condition condition={isForwardableForUser}>
            <ForwardToInquiryManager />
            <ForwardToBankAdvisor />
          </Condition>
          <Condition condition={!hasBeenForwardedToCompeon}>
            <ForwardToCompeon />
          </Condition>
        </Grid>
      </HorizontalSection>
    </Condition>
  );
};

export default compose<React.ComponentType>(
  withIntl,
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.FORWARD_INQUIRY.DISPLAY),
  disableInquiryDraft,
)(ForwardInquiry);
