import React, { ReactNode } from 'react';

import { HeadingComponent } from 'theme/components/Heading';

type FileSectionSubtitleProps = {
  children: ReactNode;
};

const FileSectionSubtitle = ({ children }: FileSectionSubtitleProps) => (
  <HeadingComponent variant="quinary" color="brand.default" mb={6}>
    {children}
  </HeadingComponent>
);

export default FileSectionSubtitle;
