import styled from 'styled-components/macro';

import { zIndex } from 'theme/themeConstants';

const SymbolWrapper = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: ${zIndex.default};
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.text.tertiary};
  user-select: none;
`;

export default SymbolWrapper;
