import { Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { getLegalRepresentativesRequireManualMerging } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type LegalRepresentationCorrectionStepProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  actionUrl: string;
  id?: string;
};

export const LegalRepresentationCorrectionStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  actionUrl,
  id,
}: LegalRepresentationCorrectionStepProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.legalRepresentationCorrection');
  const history = useHistory();
  const manualMergingRequired = useSelector(getLegalRepresentativesRequireManualMerging);

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={6}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              onClick={() => history.push(actionUrl)}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
              disabled={manualMergingRequired}
            >
              {t('action')}
            </ButtonComponent>
          )}
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
