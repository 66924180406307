import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { AriaDescription } from 'components/AriaDescription';
import FiltersDrawer from 'pages/operationPortal/OperationInquiryList/FiltersDrawer';
import { chakraInputHeight } from 'styles/form';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledFilterWrapper } from '../styles';

export const InquiryFilters = ({ badge }: { badge: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslations();

  return (
    <StyledFilterWrapper>
      <AriaDescription id="filter-inquiries-button-aria-description">
        {t('pages.inquiryList.filtersButtonAriaDescription', { activeFiltersAmount: badge })}
      </AriaDescription>
      <AriaDescription id="filter-inquiries-button-aria-label">
        {t('pages.inquiryList.filtersButton')}
      </AriaDescription>
      <ButtonComponent
        badge={badge}
        data-testid="filter-inquiries-button"
        onClick={onOpen}
        aria-describedby="filter-inquiries-button-aria-description"
        aria-labelledby="filter-inquiries-button-aria-label"
        sx={{ ...chakraInputHeight }}
      >
        {t('pages.inquiryList.filtersButton')}
      </ButtonComponent>

      <FiltersDrawer isOpen={isOpen} onClose={onClose} />
    </StyledFilterWrapper>
  );
};
