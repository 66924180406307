import styled from 'styled-components/macro';

import { defaultFocusStyles, visuallyHiddenStyles } from 'theme/themeConstants';

export const HiddenInput = styled.input`
  ${visuallyHiddenStyles}

  &:focus + label {
    ${defaultFocusStyles}
  }
`;
