import React from 'react';

import { AriaDescription } from 'components/AriaDescription';
import {
  StyledPagination,
  StyledPaginationButton as PaginationButton,
  StyledPaginationNumber as PageNumber,
} from 'components/PortalTable/Pagination/StyledPagination';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IPagination {
  totalPages: number;
  currentPage: number;
  onPaginationChange: (newPageNumber: number) => void;
}

const Pagination = ({ totalPages, currentPage, onPaginationChange }: IPagination) => {
  const t = useTranslations();

  if (totalPages < 1) {
    return null;
  }

  const canPreviousPage = currentPage !== 1;
  const canNextPage = currentPage < totalPages;

  const handleNextPage = () => onPaginationChange(currentPage + 1);
  const handlePrevPage = () => onPaginationChange(currentPage - 1);

  const paginationAriaDescriptionId = 'pagination-aria-label';
  const paginationAriaDescription = t('pages.inquiryList.table.pagination.ariaDescription');
  const paginationPageAriaLabel = t('pages.inquiryList.table.pagination.page', {
    page: currentPage,
  });

  return (
    <StyledPagination>
      <AriaDescription id={`${paginationAriaDescriptionId}-previous`}>
        {`${paginationAriaDescription}, ${paginationPageAriaLabel}, ${t(
          'pages.inquiryList.table.pagination.previousPage',
        )}`}
      </AriaDescription>
      <PaginationButton
        className="left"
        type="button"
        disabled={!canPreviousPage}
        onClick={handlePrevPage}
        aria-labelledby={`${paginationAriaDescriptionId}-previous`}
      >
        <ArrowLeftIcon boxSize={6} />
      </PaginationButton>

      <PageNumber as="span">{currentPage}</PageNumber>

      <AriaDescription id={`${paginationAriaDescriptionId}-next`}>
        {`${paginationAriaDescription}, ${paginationPageAriaLabel}, ${t(
          'pages.inquiryList.table.pagination.nextPage',
        )}`}
      </AriaDescription>
      <PaginationButton
        className="right"
        type="button"
        disabled={!canNextPage}
        onClick={handleNextPage}
        aria-labelledby={`${paginationAriaDescriptionId}-next`}
      >
        <ArrowRightIcon boxSize={6} />
      </PaginationButton>
    </StyledPagination>
  );
};

export default Pagination;
