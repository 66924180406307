import { Grid, VisuallyHidden } from '@chakra-ui/react';

import { ITrafficLightReport } from 'models/CompanyDetails.model';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { Bubbles } from './Bubbles';
import { useColorFromSolvency } from './useColorFromSolvency';
import { RiskCard, RiskContainer, RiskHeading } from '../RiskCard/RiskCard';

export type Solvency = 'red' | 'yellow' | 'green';

export type TrafficLightProps = {
  colors: Array<Solvency | undefined>;
  message: string;
};

type PropType = {
  isRiskAnalysisPage?: boolean;
  report: ITrafficLightReport | undefined;
};

const TrafficLight = ({ colors, message }: TrafficLightProps) => {
  return (
    <Grid templateColumns="1fr 1fr" gap={4} alignItems="center">
      <Bubbles colors={colors} />
      <TextComponent as="span" color="text.secondary">
        {message || <VisuallyHidden>–</VisuallyHidden>}
      </TextComponent>
    </Grid>
  );
};

export const TrafficLights = ({ isRiskAnalysisPage, report }: PropType) => {
  const { colors, message } = useColorFromSolvency(report?.trafficLightSolvency?.color);
  const t = useTranslations('pages.companiesDetails.riskAnalysis');

  return (
    <RiskCard
      probabilityOfDefault={report?.trafficLightSolvency?.probabilityOfDefaultRange}
      timeOfMeasurement={report?.createdAt}
      isRiskAnalysisPage={isRiskAnalysisPage}
    >
      <RiskHeading>{t('trafficLight.heading')}</RiskHeading>
      <RiskContainer>
        <TrafficLight colors={colors} message={message} />
      </RiskContainer>
    </RiskCard>
  );
};
