import { chakra, UnorderedList } from '@chakra-ui/react';

export const UploadedFileList = chakra(UnorderedList, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    listStyleType: 'none',
  },
});
