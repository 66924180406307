import React from 'react';

import { VisuallyHidden } from 'components/VisuallyHidden';

type AriaDescriptionProps = {
  id?: string;
  children: string;
  'aria-hidden'?: boolean;
};

export const AriaDescription: React.FC<AriaDescriptionProps> = ({ children, id, ...rest }) => (
  <VisuallyHidden as="span" id={id} {...rest}>
    {children}
  </VisuallyHidden>
);
