import { chakra, UnorderedList } from '@chakra-ui/react';

export const FileDownloadList = chakra(UnorderedList, {
  baseStyle: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    mb: 8,
  },
});
