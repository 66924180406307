import { chakra, UnorderedList } from '@chakra-ui/react';
import styled from 'styled-components/macro';

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.75;
  color: ${(props) => props.theme.colors.text.tertiary};

  b {
    color: ${(props) => props.theme.colors.text.secondary};
  }
`;

export const UploadRequestList = chakra(UnorderedList, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    listStyleType: 'none',
    marginBottom: 8,
  },
});
