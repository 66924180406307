import React from 'react';

import env from '@beam-australia/react-env';
import { useLocation } from 'react-router-dom';

import { AriaDescription } from 'components/AriaDescription';
import { useFormConfig } from 'config/formConfig/hooks';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { LogoImage, LogoLink } from 'modules/Header/PartnerLogo/styles';
import { PARTNER_LOGO } from 'modules/Inquiry/Form/formFields';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { EnvironmentVariable } from 'utils/environment';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { useTranslations } from 'utils/hooks/useTranslations';
import { hasPermission as hasPermissionUtility, isLoggedInUser } from 'utils/user/conditionals';

import { InquiryType } from '../../Inquiry/Inquiry.type';

const partnerLogoAriaLabelId = 'partner-logo-aria-label';

const useHomeUrl = () => {
  const isOperationPortal = useIsUnderOperationPortalPath();
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();
  const hasPermission = hasPermissionUtility(selectedInquiryType || InquiryType.default);
  const { pathname: currentPath } = useLocation();

  let path;

  if (isOperationPortal) {
    if (isLoggedIn) {
      path = paths.operation.inquiryList;
    } else {
      path = paths.operation.login;
    }
  } else {
    if (isLoggedIn) {
      path = paths.customer.inquiryList;
    } else {
      path = hasPermission(PERMISSIONS.INQUIRY.CREATE)
        ? paths.inquiry.new.root
        : paths.customer.login;
    }
  }

  // If the path is the same as the current path, return undefined
  if (path === currentPath) {
    return undefined;
  }
  return path;
};

const PartnerLogo = () => {
  const isScrolledToTop = useIsScrolledToTop();
  const t = useTranslations();
  const {
    details: { fullName },
    meta: { logoUrl },
  } = usePartnerConfig();

  const homeUrl = useHomeUrl();

  if (!logoUrl) {
    return null;
  }

  return (
    <>
      <AriaDescription id={partnerLogoAriaLabelId}>
        {t('other.partnerLogoAriaLabel')}
      </AriaDescription>
      <LogoLink
        to={homeUrl ?? '#'}
        aria-disabled={!homeUrl}
        aria-labelledby={partnerLogoAriaLabelId}
      >
        <LogoImage
          src={`${env(EnvironmentVariable.LOGO_STORAGE_URL)}${logoUrl}`}
          alt={`${fullName} logo`}
          biggerHeader={isScrolledToTop}
          data-testid={PARTNER_LOGO}
        />
      </LogoLink>
    </>
  );
};

export default PartnerLogo;
