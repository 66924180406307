import { Center, Grid, GridItem } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { HeadingComponent } from 'theme/components/Heading';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import IndicativeConditionCalculation from './CbBankIndicativeConditions/IndicativeConditionCalculation';

type DataProps = {
  factoringFee: string;
  factoringLine: number;
  payoutRatio: string;
};

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  state: string;
  data: DataProps;
}

export const ShowIndicativeConditionsStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  data,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.showIndicativeConditions');
  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={10}>{t('description')}</TextComponent>
          <IndicativeConditionCalculation data={data} />
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              htmlHeight="20"
              type="dashboard"
              value={ProgressSectionType.CREATE_INDICATIVE_CONDITIONS}
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
