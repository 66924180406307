import React from 'react';

import { Box } from '@chakra-ui/react';

import { PARTNERS } from 'constants/partner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';

const SummaryHeader = () => (
  <Box sx={{ borderBottom: '1px solid', borderColor: 'border.darkGrey', mb: 8, pb: 4 }}>
    <InquiryDetailsHeader />
  </Box>
);

export default choosePartnerSpecificComponent({
  default: SummaryHeader,
  [PARTNERS.BFSS]: null,
});
