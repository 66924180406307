import { Heading, Subheading } from 'components/Text';

import { PageHeadingWrapper } from './styles';
import { PageHeadingProps } from './types';
import { InnerPageContainer } from '../Page/Page';

const PageHeading = ({ heading, subheading, error }: PageHeadingProps) => (
  <PageHeadingWrapper>
    <InnerPageContainer>
      {heading && (
        <Heading as="h1" error={error}>
          {heading}
        </Heading>
      )}
      {subheading && <Subheading as="p">{subheading}</Subheading>}
    </InnerPageContainer>
  </PageHeadingWrapper>
);

export default PageHeading;
