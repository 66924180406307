import { hexToHSL, setLuminosity } from 'theme/functions';
// Copied the consts below from theme/index because of an Error in the storybook
export const WHITE = '#fff';
export const WHITE_GREY = '#f4f4f4';
export const LIGHTEST_GREY = '#e0e0e0';
export const LIGHTER_GREY = '#c0c0c0';
export const LIGHT_GREY = '#b0b0b0';
export const GREY = '#8d8d8d';
export const DARK_GREY = '#707070';
export const DARKER_GREY = '#454545';
// export const DARKEST_GREY = '#303030'; not used atm
export const BLACK_GREY = '#202020';
// export const BLACK = '#000'; not used atm

export const GREEN = '#368632';
export const BRIGHT_GREEN = '#a5da4c';
export const YELLOW = '#f7d908';
export const BRIGHT_ORANGE = '#f7b108';
export const ORANGE = '#f48700';
export const RED = '#d82e2e';
export const BLUE = '#91d5ff';

export default {
  name: 'Default theme',
  colors: {
    primary: '#232e6a', // brand.default color from chakra theme
    background: {
      blackGrey: DARKER_GREY,
      neutral: GREY,
      darkGrey: LIGHTEST_GREY,
      lightGrey: WHITE_GREY,
      white: WHITE,
    },
    border: {
      darkGrey: LIGHTER_GREY,
      lightGrey: LIGHTEST_GREY,
      white: WHITE,
    },
    text: {
      primary: BLACK_GREY,
      secondary: DARKER_GREY,
      tertiary: DARK_GREY,
      white: WHITE,
    },
    status: {
      info: BLUE,
      info_L93: setLuminosity(hexToHSL(BLUE), 93),
      success: GREEN,
      success_L93: setLuminosity(hexToHSL(GREEN), 93),
      warning: YELLOW,
      warning_L93: setLuminosity(hexToHSL(YELLOW), 93),
      error: RED,
      error_L93: setLuminosity(hexToHSL(RED), 93),
    },
  },
};
