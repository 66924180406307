import styled from 'styled-components/macro';

import { FONT_WEIGHT_LIGHT } from 'constants/globalConstants';
import { borderRadius, defaultFocusStyles, transition } from 'theme/themeConstants';

export const StyledButtonWrapper = styled.div`
  display: flex;
`;

export const StyledIconWrapper = styled.span`
  display: block;
  margin-right: 0.75rem;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.circle};
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.background.white};
  transition: border-color ${transition.default}, background-color ${transition.default};

  svg {
    display: block;
    fill: ${({ theme }) => theme.colors.background.darkGrey};
    transition: fill ${transition.default};
  }
`;

export const StyledGenderButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT_LIGHT};
  color: ${({ theme }) => theme.colors.text.tertiary};
  transition: color ${transition.default};

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary};

    ${StyledIconWrapper} {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.primary};

      svg {
        fill: ${(props) => props.theme.colors.background.white};
      }
    }
  }

  &:hover:not(.active) {
    color: ${({ theme }) => theme.colors.primary};

    ${StyledIconWrapper} {
      border-color: ${({ theme }) => theme.colors.primary};

      svg {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  &:focus {
    ${defaultFocusStyles}
  }
`;
