import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqLarge } from 'styles/breakpoints';

const Title = styled.div`
  display: flex;
  flex-grow: 0;
  padding: 0 1rem 1rem 0;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  ${mqLarge`
    width: 30%;
  `};
`;

export default Title;
