import { useLocation } from 'react-router-dom';

/**
 * This hook is used to determine the aria-current value for a navigation link.
 * It builds on the assumption that multiple navigations can be visible at the same time
 * and that lower level navigation links extend the path of the higher level navigation link.
 * @param path - The path the navigation link is pointing to
 * @returns - The aria-current value
 */
export const useNavigationAriaCurrent = (path: string) => {
  const { pathname } = useLocation();
  const isCurrentNav = pathname === path;
  const isHigherNav = pathname.startsWith(path);

  // Navigation link is the lowest level, controlling the page
  if (isCurrentNav) {
    return 'page';
  }

  // Navigation link takes part of controlling the path but is not the lowest level
  if (isHigherNav) {
    return 'true';
  }

  // Navigation link is not part of the path and is not the lowest level
  return undefined;
};
