import { AxiosResponse } from 'axios/index';
import { createMutation, createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { deepCamelcaseKeys } from 'utils/object';

import endpoints from '../../endpoints';

export enum DashboardActionsType {
  IDENTIFY_AND_SIGN_CONTRACT = 'identify_and_sign_contract_dashboard_action',
  // Customer portal
  LEGAL_REP_SIGNATURE_PROCESS = 'legal_rep_signature_process_dashboard_action',
  // Operation portal
  UPLOAD_CONTRACTS = 'upload_contracts_dashboard_action',
  PROVIDE_CONTRACT = 'provide_contract_dashboard_action',
  AUDIT_REPRESENTATIVES = 'audit_representatives_dashboard_action',
  START_SIGNATURE_PROCESS = 'start_bankers_and_associates_signature_process_dashboard_action',
  CREATE_INDICATIVE_CONDITIONS = 'create_indicative_condition_dashboard_action',
  SHOW_INDICATIVE_CONDITIONS = 'show_indicative_condition_dashboard_action',
  START_BANKERS_SIGNATURE_PROCESS = 'start_bankers_signature_process_dashboard_action',
  START_BANKERS_AND_ASSOCIATES_SIGNATURE_PROCESS = 'start_bankers_and_associates_signature_process_dashboard_action',
  UPLOAD_WELCOME_PACKAGE = 'upload_welcome_package_dashboard_action',
  REQUEST_CREFO_REPORTS = 'request_crefo_reports_dashboard_action',
  FILE_UPLOAD = 'file_upload_dashboard_action',
  RESEND_CUSTOMER_INVITATION = 'resend_customer_invitation_dashboard_action',
}

export enum DashboardActionState {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

interface InquiryQueryVariables {
  id: string;
}

export interface DashboardActionsDetails {
  id: string;
  type: string;
  attributes: {
    expirationInDays: number;
    firstName: string;
    lastName: string;
    status: string;
    url: string;
  };
}

export interface InquiryDashboardAction {
  type: DashboardActionsType;
  id: string;
  attributes: {
    state: string;
    stepsCompleted: number;
    stepsTotal: number;
    details: DashboardActionsDetails | null;
  };
}

interface InquiryDashboardActionsResponce {
  dashboardActions: InquiryDashboardAction[];
}

export const useGetDashboardActions = createQuery<
  InquiryDashboardActionsResponce,
  InquiryQueryVariables
>({
  primaryKey: queryKeys.inquiries.dashboardActions,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.DASHBOARD_ACTIONS.compose({
        params: { id: variables.id },
      }),
    ).then((response) => ({
      dashboardActions: deepCamelcaseKeys(response.data.data),
    })),
});

interface UseSpecializedProcessQueryVariables {
  id: string;
}
export const useSpecializedProcess = createMutation<
  AxiosResponse,
  UseSpecializedProcessQueryVariables
>({
  mutationKey: ['specialized_process'],
  mutationFn: (variables) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.SPECIALIZED_PROCESS.compose({ params: { id: variables.id } }),
    ),
});
