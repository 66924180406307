import React from 'react';

import { HStack } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AriaDescription } from 'components/AriaDescription';
import Condition from 'components/Condition';
import { Container } from 'components/PortalPage/styles';
import paths from 'constants/paths';
import NavigationLink from 'modules/Header/NavigationLink';
import PartnerLogo from 'modules/Header/PartnerLogo';
import PortalNavigation from 'modules/Header/PortalNavigation';
import {
  StyledPortalHeader as StyledHeader,
  StyledMenuButton,
  StyledRwdMenu,
} from 'modules/Header/styles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useIsUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { showRwdMenu } from 'store/layout/actions';
import { rwdMenuOpenSelector } from 'store/layout/selectors';
import useIsScrolledToTop from 'utils/hooks/useIsScrolledToTop';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

import { LanguageSelector } from './LanguageSelector';

const { toggleNavigation, login, loginV2 } = translations.pages.loginPage;
const mainNavigationAriaLabelId = 'main-navigation-aria-label';

const HeaderContent = React.memo(() => {
  const isRwdMenuOpen = useSelector(rwdMenuOpenSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = isLoggedInUser();
  const t = useTranslations();
  const isUnderOperationPortalPath = useIsUnderOperationPortalPath();

  const loginPath = isUnderOperationPortalPath ? paths.operation.login : paths.customer.login;

  const loginButtonLabel = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t(login),
    [InquiryType.hausbank]: t(loginV2),
  });

  const loginLinkAriaLabelId = 'login-link-aria-label';
  const loginLinkAriaLabel = isUnderOperationPortalPath
    ? t('pages.loginPage.operationsLoginAriaLabel')
    : t('pages.loginPage.customerLoginAriaLabel');

  return (
    <Container>
      <PartnerLogo />
      <HStack spacing={8}>
        {isLoggedIn ? (
          <>
            <StyledMenuButton
              type="button"
              className={isRwdMenuOpen && 'active'}
              onClick={() => dispatch(showRwdMenu(!isRwdMenuOpen))}
              aria-label={t(toggleNavigation)}
            >
              <span className="bars" role="presentation" />
            </StyledMenuButton>

            <AriaDescription id={mainNavigationAriaLabelId}>
              {t('mainNavigationAriaLabel')}
            </AriaDescription>
            <StyledRwdMenu
              show={isLoggedIn}
              className={isRwdMenuOpen && 'open'}
              aria-labelledby={mainNavigationAriaLabelId}
            >
              <PortalNavigation />
            </StyledRwdMenu>
          </>
        ) : (
          <Condition condition={loginPath !== pathname}>
            <AriaDescription id={loginLinkAriaLabelId}>{loginLinkAriaLabel}</AriaDescription>
            <NavigationLink
              url={loginPath}
              label={loginButtonLabel}
              aria-labelledby={loginLinkAriaLabelId}
            />
          </Condition>
        )}
        <LanguageSelector />
      </HStack>
    </Container>
  );
});

export const Header = () => {
  const isScrolledToTop = useIsScrolledToTop();

  return (
    <StyledHeader biggerHeader={isScrolledToTop}>
      <HeaderContent />
    </StyledHeader>
  );
};
