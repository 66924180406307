import { useEffect, useMemo } from 'react';

import { debounce as _debounce } from 'lodash';
import { FormSpy, useForm, useFormState } from 'react-final-form';
import { useDispatch, useStore } from 'react-redux';

import { ariaLiveAddAnnouncement } from 'store/accessibility/reducer';
import { useTranslations } from 'utils/hooks/useTranslations';

import { translations } from './common/types';
import { getSectionValidations } from './progress/useCalculateProgress';
import { useFormValidations, useInquiryProcessConfig } from './state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from './state/pageStateReducer';

type Props<Sections> = {
  children: React.ReactNode;
  validation?: boolean | Sections;
  fallback?: React.ReactNode;
  sectionTitle?: string;
};

const sectionUnblocked = translations.pages.sections.sectionUnblockedAriaLive;
const sectionBlocked = translations.pages.sections.sectionBlockedAriaLive;
function useShouldShowSection<Sections>({ validation }: { validation?: boolean | Sections }) {
  const {
    state: { pageValidations },
  } = useInquiryProcessConfig();

  if (typeof validation === 'boolean') {
    return validation;
  }

  if (typeof validation === 'string') {
    let isSectionValid = false;
    for (let page of Object.keys(pageValidations)) {
      const sectionValidations = pageValidations[page].validations;
      for (let section of Object.keys(sectionValidations)) {
        if (section === validation) {
          isSectionValid = (sectionValidations as any)[validation];
          break;
        }
      }
    }

    return isSectionValid;
  }

  return false;
}

export function Condition<Sections>({
  children,
  validation,
  fallback,
  sectionTitle,
}: Props<Sections>) {
  const { state, dispatch } = useInquiryProcessConfig();
  const mainStoreDispatch = useDispatch();
  const validations = useFormValidations();
  const validationsForCurrentStep = validations[state.currentStep as string];
  const { values } = useFormState();
  const form = useForm();
  const shouldShowSection = useShouldShowSection({ validation });
  const store = useStore();
  const {
    formStateData: { conditions },
  } = useInquiryProcessConfig();
  const t = useTranslations();

  const debouncedDispatch = useMemo(
    () =>
      _debounce((message: string) => {
        mainStoreDispatch(ariaLiveAddAnnouncement(message));
      }, 1000),
    [mainStoreDispatch],
  );

  useEffect(() => {
    // We want to reduce the progress calculation as muss as possible. If we only run it after a value of any field has
    // changed we are not validating all sections during initial render with initial values from session storage. To validate all
    // sections with initial values we have to run the progress calculation after a section becomes visible.
    const sectionValidations = getSectionValidations(
      validationsForCurrentStep,
      form,
      values,
      store,
      conditions,
      state.currentStep as string,
    );

    dispatch({
      type: PageStateActionTypes.SET_PROGRESS,
      payload: {
        ...state.pageValidations,
        [state.currentStep]: {
          ...sectionValidations,
        },
      },
    });

    if (sectionTitle) {
      if (shouldShowSection) {
        debouncedDispatch(t(sectionUnblocked, { sectionTitle }));
      } else {
        debouncedDispatch(t(sectionBlocked, { sectionTitle }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowSection]);

  return (
    <FormSpy
      render={() => {
        if (shouldShowSection) {
          return <>{children}</>;
        }

        if (fallback) {
          return <>{fallback}</>;
        }

        return null;
      }}
    />
  );
}
