import React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  fieldName: string;
  labelTranslationKey: string;
  tosTextTranslationKey: string;
}

export const TermsOfServiceField = ({
  fieldName,
  labelTranslationKey,
  tosTextTranslationKey,
}: Props) => {
  const t = useTranslations();

  const tosText = t(tosTextTranslationKey);
  const templateData = {
    termsAndConditions: (
      <a key="termsAndConditions" rel="noreferrer" href={paths.termsAndConditions}>
        {tosText}
      </a>
    ),
  };
  const label = t(labelTranslationKey, templateData);

  return <CheckboxWithField name={fieldName} text={<span>{label}</span>} />;
};
