import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import { Seperator } from 'components/Seperator';
import StaticField from 'components/StaticField';
import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { getCompanyDetails } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import { AccordionComponent } from 'theme/components/Accordion';
import { HeadingComponent } from 'theme/components/Heading';
import { LinkComponent } from 'theme/components/Link';
import { TextComponent } from 'theme/components/Text';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { PortalLegalRepresentative } from '../components/legalRepresentatives/fields';
import { useGetAssociatedPeople } from '../hooks/useFetchAssociatedPeople';

const HausbankAssociatedPeople = () => {
  const { companyId } = useParams<{ companyId: any }>();
  const t = useTranslations('pages.companiesDetails.associatedPerson');
  const { legalRepresentatives } = useGetAssociatedPeople({ companyId });
  const companyDetails = useSelector(getCompanyDetails);

  const mapPerson = (person: PortalLegalRepresentative) => (
    <AccordionComponent
      headingLevel="h4"
      mb={8}
      title={`${person.firstName} ${person.lastName}`}
      text={t('properties.authorizedToRepresent')}
    >
      <Subtitle>{t('properties.represent')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('properties.powerOfRepresentation.caption')}
          text={
            person.soleSignatureAuthorized
              ? t('properties.powerOfRepresentation.values.soleRepresentative')
              : t('properties.powerOfRepresentation.values.notSoleRepresentative')
          }
        />
      </StyledOverviewFields>

      <Subtitle>{t('properties.contactData')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('properties.email')}
          text={
            person.email ? (
              <LinkComponent href={`mailto:${person.email}`}>{person.email}</LinkComponent>
            ) : (
              '-'
            )
          }
        />
        <StaticField
          caption={t('properties.phoneNumber')}
          text={
            person.phoneNumber ? (
              <LinkComponent href={`tel:${person.phoneNumber}`}>{person.phoneNumber}</LinkComponent>
            ) : (
              '-'
            )
          }
        />
      </StyledOverviewFields>
      <Subtitle>{t('properties.personalData')}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t('properties.birthDate')}
          text={person.birthDate ? <time>{formatDateDays(person.birthDate)}</time> : '-'}
          noFlex
        />
      </StyledOverviewFields>
    </AccordionComponent>
  );
  return (
    <Container>
      <Box pt="12" pb="20">
        <HeadingComponent as="h2" variant="primary" mb={8}>
          {t('title')}

          <TextComponent
            as="span"
            display="block"
            fontWeight={FONT_WEIGHT_REGULAR}
            color="text.tertiary"
          >
            {companyDetails.companyName}
          </TextComponent>
        </HeadingComponent>

        {legalRepresentatives.length > 0 && (
          <Seperator
            title={`${t('properties.authorizedToRepresent')} (${legalRepresentatives.length})`}
          >
            {legalRepresentatives.map((person: PortalLegalRepresentative) => mapPerson(person))}
          </Seperator>
        )}
      </Box>
    </Container>
  );
};

export default HausbankAssociatedPeople;
