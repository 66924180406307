import { Divider } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinalPageAction } from './FinalPageAction';

const DescriptionAction = () => {
  const t = useTranslations('pages.finalPage.description');
  const DESCRIPTION = 'description';

  if (t(DESCRIPTION) === '-') {
    return null;
  }

  return (
    <>
      <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
      <FinalPageAction asset={<Asset type="inquiry" value={InquiryAssetKeys.NEXT_STEPS} />}>
        <HeadingComponent as="h3" variant="secondary" mb={4}>
          {t('heading')}
        </HeadingComponent>
        <TextComponent mb={6}>{t(DESCRIPTION)}</TextComponent>
      </FinalPageAction>
    </>
  );
};

export default DescriptionAction;
