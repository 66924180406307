import { SelectWithField } from 'components/Selects/Select';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useGetTurnoverClassOptions } from './useGetTurnoverClassOptions';

type Props = {
  name: string;
};

export const TurnoverClassSelect = ({ name }: Props) => {
  const options = useGetTurnoverClassOptions();
  const t = useTranslations();

  return (
    <SelectWithField
      name={name}
      options={options}
      placeholder={t(translations.placeholders.pleaseChoose)}
      data-testid={'select-field-turnover-class'}
    />
  );
};
