import FormRow from 'components/FormRow';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { PURPOSE_KIND__KFINANZ, USER_PROFILE_FOUNDING_YEAR } from 'modules/Inquiry/Form/formFields';
import { choosePurposeKindSpecificComponent } from 'shared/choosePurposeKindSpecificComponent';

import FoundingMonth from './FoundingMonth';
import FoundingYear from './FoundingYear';
import FoundingYearRadioField from './FoundingYear/FoundingYearRadioField';

const CrefoFoundingYearQuestions = () => {
  return (
    <FormRow>
      <FoundingYear />
      <Condition condition={fieldIsValid(USER_PROFILE_FOUNDING_YEAR)}>
        <FoundingMonth />
      </Condition>
    </FormRow>
  );
};

const KfinanzFoundingYearQuestions = () => {
  return (
    <FormRow>
      <FoundingYearRadioField />
    </FormRow>
  );
};

export default choosePurposeKindSpecificComponent({
  [PURPOSE_KIND__KFINANZ]: KfinanzFoundingYearQuestions,
  default: CrefoFoundingYearQuestions,
});
