import { USER_SCOPE } from 'constants/userScopes';

import { DownloadStatusType } from './types/DownloadStatus.type';
import { FileScanStatusType } from './types/FileScanStatus.type';

export interface IDefaultFile {
  id: string;
  fileName: string;
}

interface ClassifiableFile {
  classification: string | null;
}

interface ArchivableFile {
  archivedAt: Date | null;
  archivingFailedAt: Date | null;
}
export interface IFile extends IDefaultFile, ClassifiableFile, ArchivableFile {
  createdAt: Date;
  scanningStatus: FileScanStatusType;
  status: DownloadStatusType | string; // due not fully typed components we have to allow strings as well
  uploadedBy: USER_SCOPE;
  kind: string;
  isRealEstateExpert: boolean;
}
export interface IPrivateFile extends IDefaultFile, ClassifiableFile, ArchivableFile {
  createdAt: Date;
  scanningStatus: FileScanStatusType;
  status: DownloadStatusType | string;
}

export interface IAssessmentFile extends IDefaultFile, ArchivableFile {
  scanningStatus: FileScanStatusType;
  createdAt: Date;
  status: DownloadStatusType | string;
}

export interface IInternalFile extends IDefaultFile {
  downloadUrl: string;
  contentType: string;
  source: string;
}

export type UploadableFileTypes = IFile | IPrivateFile | IAssessmentFile;

export enum FileType {
  FILE = 'FILE',
  PRIVATE_FILE = 'PRIVATE_FILE',
  ASSESSMENT_FILE = 'ASSESSMENT_FILE',
  MMV_OBJECT_CONFIRMATION_FILLED_DOCUMENT = 'MMV_OBJECT_CONFIRMATION_FILLED_DOCUMENT',
}
