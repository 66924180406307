import React from 'react';

import { Box, ListItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import FileDownloadBlock from 'components/UploadBlock/NewFileRequestBlock/FileDownloadBlock';
import { IInternalFile } from 'models/File.model';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import { getInternalFiles } from 'store/documentExchange/documentExchange.selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FileDownloadList } from './styles';

export const InternalFiles = () => {
  const internalFiles: IInternalFile[] = useSelector(getInternalFiles);

  const internalFilesWithHttps = internalFiles.map(({ downloadUrl, ...file }) => ({
    downloadUrl: downloadUrl.replace('http://', 'https://'),
    ...file,
  }));

  const t = useTranslations('pages.inquiryDetails.documentExchange');

  return (
    <Condition condition={Boolean(internalFiles.length)}>
      <section>
        <Box display="flex" alignItems="center" justifyContent="space-between" w="100%" mb={8}>
          <FileSectionTitle mb={0}>{t('filesInternal')}</FileSectionTitle>
        </Box>
        <FileDownloadList>
          {internalFilesWithHttps.map((file) => (
            <ListItem key={file.id}>
              <FileDownloadBlock file={file} />
            </ListItem>
          ))}
        </FileDownloadList>
      </section>
    </Condition>
  );
};
