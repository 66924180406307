import React from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { filterXSS } from 'xss';

import Spinner from 'components/Spinner';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { useSelectFile } from 'components/UploadBlock/hooks/useSelectFile';
import DownloadFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/DownloadFileInRequest';
import FileRequestStatusLabel from 'components/UploadBlock/NewFileRequestBlock/components/FileRequestStatusLabel';
import PreviewFileInRequest from 'components/UploadBlock/NewFileRequestBlock/components/PreviewFileInRequest';
import RemoveFileInRequestButton from 'components/UploadBlock/NewFileRequestBlock/components/RemoveFileInRequestButton';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import {
  RequestBlockOptionalLabel as OptionalLabel,
  StyledDroppingText as DroppingText,
  StyledErrorIcon as ErrorIcon,
  StyledErrorInfo as ErrorText,
  StyledIconWrapper as IconWrapper,
  StyledProgressBackground as Progress,
  StyledProgressPercent as Percent,
  StyledRequestActions as Actions,
  StyledRequestBlockWrapper as Wrapper,
  StyledRequestContent as Content,
  StyledRequestDescription as Description,
  StyledRequestTitle as Title,
  StyledRequestTitleWrapper as TitleWrapper,
  StyledScanningInfo as ScanningInfo,
  StyledUploadedFileName as FileName,
  StyledUploadedIcon as UploadedIcon,
  StyledUploadedInfo as UploadedInfo,
  StyledUploadingIcon as UploadingIcon,
  UnuploadedIcon,
  UploadedFileDate as UploadedDate,
} from 'components/UploadBlock/NewFileRequestBlock/styles';
import UploadDropzone from 'components/UploadBlock/UploadIdleState/UploadDropzone';
import type { UploadedFile } from 'models/FileApi.model';
import { IFileRequest } from 'models/FileRequest.model';
import { UPLOAD_DOCUMENTS_BUTTON } from 'modules/Inquiry/Form/formFields';
import { ariaLiveAddAnnouncement } from 'store/accessibility/reducer';
import { ButtonComponent } from 'theme/components/Button';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IFileRequestBlock {
  url: string;
  onUpload: (file: any) => void;
  fileRequest: IFileRequest;
  optional?: boolean;
}

// the file argument on onUpload function is a vulnerability - you need to check if you correctly handle the type

const FileRequestBlock = ({ url, onUpload, fileRequest, optional }: IFileRequestBlock) => {
  const { uploadProgress } = useNewUploadFile(url);
  const dispatch = useDispatch();

  // If multiple file upload is allowed, the MultipleFileUploadRequestBlock component is used instead
  // So we can assume that there is only one file in the fileRequest.files array here
  const [file] = fileRequest.files ?? [];

  const t = useTranslations('components.upload');

  const handleSuccess = (uploadedFile: UploadedFile) => {
    onUpload(uploadedFile);
    dispatch(ariaLiveAddAnnouncement(t('ariaLiveUploadSuccess')));
  };

  const { onFileSelected, errorMsg, isDropping, uploadingState, setIsDropping } = useSelectFile({
    onSuccess: handleSuccess,
    url,
    fileRequestId: fileRequest.id,
  });

  const isUploading = [UPLOADING_STATE.UPLOADING, UPLOADING_STATE.SCANNING].includes(
    uploadingState,
  );
  const fileDescription = fileRequest.description || '';
  const sanitizedDescription = filterXSS(fileDescription, {
    whiteList: {
      a: ['href', 'title', 'target'],
    },
  });

  const fileRequestActions = (
    <>
      <PreviewFileInRequest file={file} />
      <DownloadFileInRequest file={file} />
      <RemoveFileInRequestButton file={file} />
    </>
  );

  return (
    <Box data-testid="request-block" width="full" cursor="pointer">
      <UploadDropzone
        onFileDropping={setIsDropping}
        onFileDropped={onFileSelected}
        disabled={!!file}
      >
        <Wrapper className={isDropping ? 'dropping' : uploadingState}>
          {optional && <OptionalLabel>{t('optional')}</OptionalLabel>}
          <Progress progress={uploadProgress}>
            <Percent>
              <ScanningInfo>
                <Spinner />
                {t('scanning')}
              </ScanningInfo>
              {uploadProgress}%
            </Percent>
          </Progress>

          <IconWrapper>
            <UploadingIcon />
            <UnuploadedIcon />
            <ErrorIcon />
            <UploadedIcon />
          </IconWrapper>

          <Content>
            <TitleWrapper>
              <Title>{fileRequest.title}</Title>{' '}
              <FileRequestStatusLabel status={fileRequest.status} />
            </TitleWrapper>
            <FileName>
              {file && <UploadedDate>{formatDateWithTime(file.createdAt)}</UploadedDate>}
              {isUploading && `${t('uploading')}: `}
              {file?.fileName}
            </FileName>
            <DroppingText>{t('dropFilesHere')}</DroppingText>
            <UploadedInfo>{t('fileUploaded')}</UploadedInfo>
            <ErrorText>{errorMsg}</ErrorText>
            {fileDescription && (
              <Description dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
            )}
          </Content>
          <Actions>
            {file ? (
              fileRequestActions
            ) : (
              <ButtonComponent
                data-testid={UPLOAD_DOCUMENTS_BUTTON}
                leftIcon={<UploadIcon boxSize={6} display={'block'} />}
              >
                {t('sendNow')}
              </ButtonComponent>
            )}
          </Actions>
        </Wrapper>
      </UploadDropzone>
    </Box>
  );
};

export default FileRequestBlock;
