import { DashboardActionsType } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import { useDzbResendCustomerInvitation } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import Condition from 'components/Condition';
import { useActionCalculations } from 'pages/customerPortal/InquiryDetails/Dashboard/useDashboardActions';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { useToasts } from 'shared/hooks/useToasts';
import ButtonComponent from 'theme/components/Button';
import { HorizontalSection } from 'theme/components/HorizontalSection';
import { useTranslations } from 'utils/hooks/useTranslations';

import Section from '../Actions/Section';

const ResendCustomerInvitation = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.resendCustomerInvitation');
  const { success, error } = useToasts();
  const { id: inquiryId } = useParamsSafe(['id']);
  const { dashboardActions } = useActionCalculations();
  const show = dashboardActions.some(
    (action) => action.type === DashboardActionsType.RESEND_CUSTOMER_INVITATION,
  );

  const { mutate: resendEmail, isLoading } = useDzbResendCustomerInvitation({
    onSuccess: () => success({ description: t('success') }),
    onError: () => error({ description: t('error') }),
  });

  const button = (
    <ButtonComponent isLoading={isLoading} onClick={() => resendEmail({ id: inquiryId })}>
      {t('action')}
    </ButtonComponent>
  );

  return (
    <Condition condition={show}>
      <HorizontalSection label={t('title')}>
        <Section title={t('subtitle')} description={t('description')} button={button} />
      </HorizontalSection>
    </Condition>
  );
};

export default ResendCustomerInvitation;
