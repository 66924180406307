import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { DashboardActionState } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import { useCbBankRepresentativesSignatureApproval } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import { Asset } from 'components/Asset';
import queryKeys from 'constants/queryKeys';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { useToasts } from 'shared/hooks/useToasts';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  state?: string;
}

export const StartSignatureProcessStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  state,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.signatureProcess');
  const { success, error } = useToasts();
  const queryClient = useQueryClient();
  const { mutate: approveSignature, isLoading } = useCbBankRepresentativesSignatureApproval({
    onSuccess: () => success({ description: t('success') }),
    onError: () => error({ description: t('error') }),
    onSettled: () => queryClient.invalidateQueries([queryKeys.inquiries.dashboardActions]),
  });
  const { id: inquiryId } = useParamsSafe(['id']);

  const hasAction = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.cbBank]: true,
  });
  const showAction = hasAction && state !== DashboardActionState.COMPLETED;

  const description = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: t('description'),
    [InquiryType.cbBank]: t('descriptionV2'),
  });

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={3}>{description}</TextComponent>
          {showAction && (
            <ButtonComponent
              variant="primary"
              onClick={() => approveSignature({ id: inquiryId })}
              disabled={state === DashboardActionState.INACTIVE}
              isLoading={isLoading}
            >
              {t('action')}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              htmlHeight="200"
              type="dashboard"
              value={ProgressSectionType.START_SIGNATURE_PROCESS}
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
