import React, { ReactNode } from 'react';

import { NavLink } from 'react-router-dom';

import { CompaniesDetailsRouteDeclaration } from 'pages/operationPortal/CompaniesDetails/useCompaniesDetailsRoutes';

import { useNavigationAriaCurrent } from './hooks/useNavigationAriaCurrent';

// Types
export interface UnifiedRouteDeclaration extends Omit<CompaniesDetailsRouteDeclaration, 'name'> {
  name: string;
}

type RouteDeclarationMapper = (
  routeDeclarations: UnifiedRouteDeclaration[],
  t: (id: string) => string,
  hasPermission: (availableForRoles: any) => any,
  companyId: string,
  inquiryId?: string,
) => ReactNode[];

const NavigationListItem = ({
  path,
  name,
  children,
}: {
  path: string;
  name: string;
  children: ReactNode;
}) => {
  const ariaCurrent = useNavigationAriaCurrent(path);

  return (
    <li>
      <NavLink
        activeClassName="active"
        to={path}
        data-testid={`${name}-link`}
        aria-current={ariaCurrent}
      >
        {children}
      </NavLink>
    </li>
  );
};

// Utils
export const mapRouteDeclarationToNavLink: RouteDeclarationMapper = (
  routeDeclarations,
  t,
  hasPermission,
  companyId,
  inquiryId,
) => {
  return (routeDeclarations as UnifiedRouteDeclaration[])
    .filter(({ permission }) => (permission ? hasPermission(permission) : true))
    .map((route) => {
      const path = route.path.replace(':companyId', companyId).replace(':id', inquiryId as string);
      return (
        <NavigationListItem key={route.path} path={path} name={route.name}>
          {t(route.title)}
        </NavigationListItem>
      );
    });
};
