import React from 'react';

import { VisuallyHidden } from 'components/VisuallyHidden';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateDays, formatDateTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import {
  StyledWrapper as Wrapper,
  StyledHeader as Header,
  StyledColumn as Column,
  StyledComment as Comment,
} from './styles';

interface Props {
  firstName: string;
  lastName: string;
  date: string;
  comment: string;
  testId?: string;
}

const InternalCommentsItem = ({ firstName, lastName, date, comment, testId }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.communication.internalComments');

  return (
    <Wrapper data-testid={testId}>
      <Header>
        <Column>
          <UserIcon boxSize={6} mr={2} />
          <dl>
            <dt>
              <VisuallyHidden>{t('authorLabel')}</VisuallyHidden>
            </dt>
            <dd>
              {firstName} {lastName}
            </dd>
          </dl>
        </Column>
        <Column>
          <TimeIcon boxSize={6} mr={2} />
          <dl>
            <dt>
              <VisuallyHidden>{t('dateLabel')}</VisuallyHidden>
            </dt>
            <dd>
              <time>
                {formatDateDays(date)}; {formatDateTime(date)}
              </time>
            </dd>
          </dl>
        </Column>
      </Header>
      <Comment>{comment}</Comment>
    </Wrapper>
  );
};

export default InternalCommentsItem;
