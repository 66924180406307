import React from 'react';

import { useSelector } from 'react-redux';

import { Container } from 'components/PortalPage/styles';
import PERMISSIONS from 'constants/user/permissions';
import { StyledPageTitle } from 'pages/operationPortal/CompaniesDetails/styles';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinancialInformationAllInfo } from './FinancialInformationAllInfo';
import { InquiryLane } from '../../../../modules/Inquiry/InquiryLane';
import CONFIG from '../../../../shared/featureFlagConfig/configFromAdmin';
import { useConfig } from '../../../../shared/featureFlagConfig/useConfig';
import { getInquiryDetailsSelector } from '../../../../store/inquiryDetails/selectors';
import { getAllKpis, getKpiDateRange } from '../../../../store/kpi/kpis.selectors';
import { InformationDatePickerProvider } from '../../../../theme/components/FinancialInformations/context/DatePickerContext';
import { FallbackComponent } from '../FallbackComponent';

const FinancialInformation: React.FC = () => {
  const t = useTranslations();
  const kpiDateRange = useSelector(getKpiDateRange);
  const kpis = useSelector(getAllKpis);
  const isEvalueinEnabled = useConfig(CONFIG.IS_EVALUEIN_ENABLED);
  const isFallback = Object.keys(kpis).length === 0;
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  // If FinancialInformation is rendered in InquiryDetails, we can check the lane
  const isContract = inquiryDetails ? inquiryDetails.lane === InquiryLane.contract : false;

  return (
    <Container>
      <StyledPageTitle>{t('pages.companiesDetails.financialInformation.title')}</StyledPageTitle>
      {isFallback ? (
        <FallbackComponent />
      ) : (
        <InformationDatePickerProvider initialValue={new Date(kpiDateRange.lastKpi)}>
          {isEvalueinEnabled && !isContract && <FinancialInformationAllInfo />}
        </InformationDatePickerProvider>
      )}
    </Container>
  );
};

export default withRolesGuard(PERMISSIONS.COMPANY_DETAILS.DISPLAY)(FinancialInformation);
