import React from 'react';

import { Box, ChakraComponent } from '@chakra-ui/react';

export const VisuallyHidden: ChakraComponent<'div'> = ({ children, ...rest }) => {
  const sx = {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    'white-space': 'nowrap',
    border: 0,
  };

  return (
    <Box sx={sx} {...rest}>
      {children}
    </Box>
  );
};
