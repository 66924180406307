import React from 'react';

import PropTypes from 'prop-types';

import SortingArrowIcon from 'components/PortalTable/TableHeaders/SortingArrows/StyledSortingArrow';
import CaretDownFilledIcon from 'theme/components/Icon/CaretDownFilledIcon';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';
import CaretUpFilledIcon from 'theme/components/Icon/CaretUpFilledIcon';
import CaretUpIcon from 'theme/components/Icon/CaretUpIcon';

const SortingArrows = ({ sortedDesc, isSorted }) => {
  if (!isSorted) {
    return (
      <SortingArrowIcon>
        <CaretUpIcon boxSize={6} />
        <CaretDownIcon boxSize={6} />
      </SortingArrowIcon>
    );
  }

  return (
    <SortingArrowIcon>
      {sortedDesc ? <CaretUpIcon boxSize={6} /> : <CaretUpFilledIcon boxSize={6} />}
      {sortedDesc ? <CaretDownFilledIcon boxSize={6} /> : <CaretDownIcon boxSize={6} />}
    </SortingArrowIcon>
  );
};

SortingArrows.defaultProps = {
  sortedDesc: false,
  isSorted: false,
};

SortingArrows.propTypes = {
  sortedDesc: PropTypes.bool,
  isSorted: PropTypes.bool,
};

export default SortingArrows;
