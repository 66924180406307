import React, { useEffect, useMemo, useState } from 'react';

import { Flex, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { FieldInputProps } from 'react-final-form';

import { AriaDescription } from 'components/AriaDescription';
import {
  StyledCaptionWrapper as CaptionWrapper,
  StyledLabelWrapper as LabelWrapper,
} from 'components/ControlWrapper/styles';
import { SelectInput } from 'components/Selects/Select/SelectInput';
import { SelectItem } from 'components/Selects/Select/SelectItem';
import { SelectList } from 'components/Selects/Select/SelectList';
import { Optional } from 'components/Text';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import ButtonComponent from 'theme/components/Button';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { buildElementIdString } from 'utils/accessibility/buildElementIdString';
import { useTranslations } from 'utils/hooks/useTranslations';

export interface Option {
  complexLabel?: React.ReactNode;
  label: string;
  value: string;
  key?: string;
  testId?: string;
  greyBackground?: boolean;
  isItemBold?: boolean;
}

export const Select = ({
  name,
  value,
  options,
  caption,
  optional,
  placeholder,
  onChange,
  isClearable,
  onBlur,
  valid,
  submitFailed,
  touched,
  error,
  initial,
  isNotSearchable = false,
  tooltip,
  width,
  onChangeInputValue = () => {},
  preventEnterInInput = false,
}: FieldInputProps<Option> & {
  isClearable?: boolean;
  isNotSearchable?: boolean;
  onChangeInputValue?: Function;
}) => {
  const [inputItems, setInputItems] = useState(options);
  const initialValue = options.find(
    (option: Option) => option.value === ((value?.value || value) as unknown as string),
  );
  const initialInputValue = initialValue ? initialValue.label : '';
  const initialSelectedItem = initialValue ? initialValue.value : '';
  const t = useTranslations();

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
    setInputValue,
    reset,
  } = useCombobox({
    initialInputValue,
    initialSelectedItem,
    items: inputItems,
    selectedItem: value,
    onSelectedItemChange: ({ selectedItem }) => {
      // FIXME: we change the value to string, but it should be Option
      // this causes to have mixed types here, which worsen the readability and type safety
      onChange(selectedItem?.value);
    },
    itemToString: (item?: Option | null | undefined): string => (item ? item.label : ''),
    onInputValueChange: ({ inputValue = '' }) => {
      setInputItems(
        options.filter((item: Option) =>
          item.label.toLowerCase().includes(inputValue?.toLowerCase()),
        ),
      );
      onChangeInputValue(inputValue);
      setInputValue(inputValue);
    },
    onIsOpenChange: ({ isOpen, inputValue, selectedItem }) => {
      if (!isOpen) {
        onBlur(); // need this to make react-real-form touched property work

        // prevent leaving out an invalid value
        const selectedValue = selectedItem?.label || (selectedItem as unknown as string) || '';
        if (inputValue !== selectedValue) {
          onChange('');
          setInputValue('');
        }
      }
    },
  });

  useEffect(() => {
    setInputValue(initial);
  }, [initial, setInputValue]);

  useEffect(() => {
    if (options && options.length) {
      setInputItems(options);
    }
  }, [options]);

  useEffect(() => {
    if (initialInputValue) {
      selectItem(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInputValue]);

  const selectList = useMemo(
    () => (
      <SelectList {...getMenuProps()} isOpen={isOpen} flex={1} overflowY="auto" mt={0}>
        {inputItems.map((item: Option, index: number) => (
          <SelectItem
            {...getItemProps({ item, index })}
            itemIndex={index}
            highlightedIndex={highlightedIndex}
            data-testid={`option:${
              item.testId || (typeof item.value === 'string' ? item.value : index)
            }`}
            key={item.key ? item.key : item.value}
            greyBackground={item.greyBackground || false}
            isItemBold={item.isItemBold || false}
          >
            {item.complexLabel ? item.complexLabel : item.label}
          </SelectItem>
        ))}
      </SelectList>
    ),
    [getItemProps, getMenuProps, highlightedIndex, inputItems, isOpen],
  );

  const clearButtonAriaLabelId = buildElementIdString(name, 'aria-label');
  const clearButton = (
    <>
      <AriaDescription id={clearButtonAriaLabelId}>
        {t('buttons.clearSelectionAriaLabel')}
      </AriaDescription>
      <ButtonComponent aria-labelledby={clearButtonAriaLabelId} onClick={reset} variant="link">
        <CloseIcon boxSize={6} />
      </ButtonComponent>
    </>
  );

  const toggle =
    isClearable && selectedItem ? clearButton : <CaretDownIcon boxSize={6} zIndex="20" />;

  const labelStyles = useMultiStyleConfig('FieldWrapper');

  return (
    <Flex direction="column" width={width}>
      <LabelWrapper>
        <CaptionWrapper>
          <Text
            as="label"
            fontSize="m"
            color="text.tertiary"
            fontWeight={300}
            mb={caption ? 5 : 0}
            sx={labelStyles.caption}
            {...getLabelProps()}
          >
            {caption}
          </Text>
          {tooltip && (
            <PopoverComponent
              mt="-12px"
              ml={1}
              text={tooltip}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={6} display="block" />}
                  label={t('buttons.moreInfos')}
                />
              }
            />
          )}
        </CaptionWrapper>
        {optional && <Optional>{t('other.optional')}</Optional>}
      </LabelWrapper>
      <Flex direction="column" position="relative" {...getComboboxProps()}>
        <Flex direction="row" alignItems="baseline" {...getToggleButtonProps()}>
          <SelectInput
            flex="0 0 auto"
            placeholder={placeholder}
            name={name}
            toggleButton={toggle}
            data-testid={name}
            visited
            isReadOnly={isNotSearchable}
            {...getInputProps({
              onBlur,
              onKeyDown: (event) => {
                switch (event.key) {
                  case 'Enter': {
                    if (preventEnterInInput) event.preventDefault();
                  }
                }
              },
            })}
          />
        </Flex>
        {selectList}
      </Flex>
      {!valid && (submitFailed || touched) && <FieldError message={error} />}
    </Flex>
  );
};
