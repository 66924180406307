import {
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { AriaDescription } from 'components/AriaDescription';
import { useTranslations } from 'utils/hooks/useTranslations';

import { HeadingComponent } from '../Heading';

type PlacementType = 'top' | 'right' | 'bottom' | 'left';
type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  placement: PlacementType;
  size: SizeType;
  title: string;
  children: React.ReactNode;
  dataTestId?: string;
};

export const DrawerComponent = ({
  isOpen,
  onClose,
  placement,
  size,
  title,
  children,
  dataTestId,
  ...props
}: Props) => {
  const styles = useMultiStyleConfig('Drawer', {});
  const t = useTranslations();
  const closeButtonId = 'inquiry-list-filters-drawer-close-button-aria-description';

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      size={size}
      data-testid={dataTestId}
      {...props}
    >
      <DrawerOverlay sx={styles.overlay}>
        <DrawerContent sx={styles.content} data-testid={dataTestId + '-content'}>
          <AriaDescription id={closeButtonId}>
            {t('pages.inquiryList.filtersDrawer.buttons.closeAriaLabel')}
          </AriaDescription>
          <DrawerCloseButton aria-labelledby={closeButtonId} />
          <DrawerHeader sx={styles.header} data-testid={dataTestId + '-header'}>
            <HeadingComponent variant="secondary">{title}</HeadingComponent>
          </DrawerHeader>
          {children}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
