import React from 'react';

import { Link } from 'react-router-dom';

import { AriaDescription } from 'components/AriaDescription';
import { Container } from 'components/PortalPage/styles';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledCompanyNav } from './styles';
import { useCompaniesDetailsRoutes } from './useCompaniesDetailsRoutes';

const companyNavAriaLabelId = 'company-navigation-aria-label';

const CompanyNavigation: React.FC = () => {
  const t = useTranslations();
  const routeLinks = useCompaniesDetailsRoutes();

  return (
    <>
      <AriaDescription id={companyNavAriaLabelId}>
        {t('pages.companyDetails.companyNavigationAriaLabel')}
      </AriaDescription>
      <StyledCompanyNav aria-labelledby={companyNavAriaLabelId}>
        <Container>
          <ul>{routeLinks}</ul>
          <Link to={paths.operation.companies.root}>{t('pages.companiesDetails.close')}</Link>
        </Container>
      </StyledCompanyNav>
    </>
  );
};

export default CompanyNavigation;
