import React from 'react';

import { VisuallyHidden } from 'components/VisuallyHidden';
import { ProcessLog, ProcessLogType } from 'models/ProcessLog.model';
import {
  StyledItemWrapper as Wrapper,
  StyledUserField as UserField,
  StyledStatusField as StatusField,
  StyledDateField as DateField,
} from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/styles';
import StarIcon from 'theme/components/Icon/StarIcon';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import UserIcon from 'theme/components/Icon/UserIcon';
import { formatDateDays, formatDateTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

const ProcessLogItem = ({ log }: { log: ProcessLog }) => {
  const { createdAt: date, activity, user, type } = log;
  const t = useTranslations('pages.inquiryDetails.dashboard.communication.processLog');
  return (
    <Wrapper>
      <UserField>
        <UserIcon boxSize={6} mr={2} />
        <dt>
          <VisuallyHidden>{t('userLabel')}</VisuallyHidden>
        </dt>
        <dd>
          {type === ProcessLogType.user && user
            ? `${user.firstName} ${user.lastName}`
            : t('systemType')}
        </dd>
      </UserField>

      <StatusField>
        <StarIcon boxSize={6} mr={2} />
        <dt>
          <VisuallyHidden>{t('activityLabel')}</VisuallyHidden>
        </dt>
        <dd>{t(`activity.${activity}`)}</dd>
      </StatusField>

      <DateField>
        <TimeIcon boxSize={6} mr={2} />
        <dt>
          <VisuallyHidden>{t('dateLabel')}</VisuallyHidden>
        </dt>
        <dd>
          <time>
            {formatDateDays(date)}; {formatDateTime(date)}
          </time>
        </dd>
      </DateField>
    </Wrapper>
  );
};

export default ProcessLogItem;
