import { useClipboard } from '@chakra-ui/react';

import { Signee } from 'models/types/Signee.type';
import Button from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

type SignContractStepSigneeCopyLinkProps = Pick<Signee['attributes'], 'url'>;

export const SignContractStepSigneeCopyLink = ({ url }: SignContractStepSigneeCopyLinkProps) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.signContract');

  const { onCopy, hasCopied } = useClipboard(url);
  return (
    <Button size="sm" onClick={onCopy} width="full">
      {t(hasCopied ? 'table.copiedLink' : 'table.copyLink')}
    </Button>
  );
};
