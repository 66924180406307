import React, { PropsWithChildren } from 'react';

import { useDisclosure } from '@chakra-ui/hooks';
import { useQueryClient } from '@tanstack/react-query';
import normalize from 'json-api-normalizer';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';

import { useCancelInquiry, useInquiry } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import Condition from 'components/Condition';
import queryKeys from 'constants/queryKeys';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { getCustomer } from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import ButtonComponent from 'theme/components/Button';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

type CancelInquiryProps = {
  inquiryId: string;
  status: INQUIRY_STATUSES;
  variant?: 'full' | 'short';
  mt?: number;
  ml?: number;
};

const CancelInquiry: React.FC<PropsWithChildren<CancelInquiryProps>> = ({
  inquiryId,
  status,
  variant = 'short',
  mt,
  ml,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { error, success } = useToasts();
  const queryClient = useQueryClient();
  const { mutateAsync: cancelInquiry } = useCancelInquiry();
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.closeInquiry.archiveInquiry.cancelInquiry',
  );
  const tRoot = useTranslations();
  const { data: inquiry } = useInquiry({ variables: { id: inquiryId }, suspense: true });

  const archiveInquiry = async () => {
    try {
      const { data } = await cancelInquiry({ id: inquiryId });

      if (data) {
        success({ description: t('inquiryIsCancelled') });
        dispatch(mapInquiryDetailsApiResponseAction(data));
        queryClient.invalidateQueries([queryKeys.inquiries.details]);
        queryClient.invalidateQueries([queryKeys.operation.inquiriesList]);
        onClose();
      } else {
        error({ description: tRoot('errors.actionFailed') });
      }
    } catch (err: any) {
      console.error('An error occured while trying to cancel the inquiry', err);
    }
  };

  const handleCancelClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    onOpen();
  };

  const showCancelInquiry = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.bfs]: true,
  });

  const customer = getCustomer(
    normalize(inquiry?.data, {
      camelizeKeys: false,
      camelizeTypeValues: false,
    }),
  );
  const advertisingConsent = _get(
    customer,
    'attributes.additional_details.advertisement_acceptance',
  );
  const modalConfirmationText = advertisingConsent
    ? t('modal.confirmationText')
    : t('modal.confirmationTextWithoutAdvertisingConsent');

  return (
    <Condition condition={showCancelInquiry}>
      <AlertDialogComponent
        cancelText={t('modal.declineBtn')}
        confirmText={t('modal.confirmationBtn')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={archiveInquiry}
        title={t('modal.heading')}
      >
        {modalConfirmationText}
      </AlertDialogComponent>
      <ButtonComponent
        onClick={handleCancelClick}
        variant="error"
        disabled={status === INQUIRY_STATUSES.ARCHIVED}
        rightIcon={variant === 'full' ? <CloseIcon /> : undefined}
        mt={mt}
        ml={ml}
      >
        {variant === 'short' ? <CloseIcon /> : children}
      </ButtonComponent>
    </Condition>
  );
};

export default CancelInquiry;
