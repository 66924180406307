import React, { useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { AriaDescription } from 'components/AriaDescription';
import { Sidebar, IconWrapper, IconButton, ButtonText } from 'components/Sidebar/styles';
import { ELEMENT_IDS } from 'constants/elementIds';
import ContactContent from 'modules/ContactSidebar/ContactContent/ContactContent';
import ContactDetails from 'modules/ContactSidebar/ContactFooter/ContactDetails/ContactDetails';
import { Container, BodyWrapper, CloseButton } from 'modules/ContactSidebar/styles';
import { showContactSidebar } from 'store/layout/actions';
import { contactSidebarOpenSelector } from 'store/layout/selectors';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import MailIcon from 'theme/components/Icon/MailIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const ContactSidebar = () => {
  const isOpen = useSelector(contactSidebarOpenSelector);
  const dispatch = useDispatch();
  const triggerRef = useRef(null);
  const open = () => dispatch(showContactSidebar(true));
  const close = () => {
    dispatch(showContactSidebar(false));
    triggerRef.current.focus();
  };

  const t = useTranslations();
  return (
    <Container isOpen={isOpen}>
      <Sidebar>
        <IconWrapper>
          <AriaDescription id="contact-sidebar-trigger-aria-label">
            {isOpen
              ? t('components.contactSidebar.contactCloseAriaLabel')
              : t('components.contactSidebar.contactOpenAriaLabel')}
          </AriaDescription>
          <IconButton
            onClick={isOpen ? close : open}
            aria-controls={ELEMENT_IDS.CONTACT_SIDEBAR}
            aria-expanded={isOpen}
            aria-labelledby="contact-sidebar-trigger-aria-label"
            type="button"
            ref={triggerRef}
          >
            <MailIcon boxSize={6} mt={1} mb={1} />
            <ButtonText>{t('components.contactSidebar.contact')}</ButtonText>
          </IconButton>
        </IconWrapper>

        <BodyWrapper aria-hidden={!isOpen} id={ELEMENT_IDS.CONTACT_SIDEBAR}>
          <ContactContent />
          <ContactDetails />
          <AriaDescription id="contact-sidebar-close-button-aria-label">
            {t('components.contactSidebar.contactCloseAriaLabel')}
          </AriaDescription>
          <CloseButton
            onClick={close}
            tabIndex={isOpen ? 0 : -1}
            type="button"
            aria-labelledby="contact-sidebar-close-button-aria-label"
          >
            <CloseIcon boxSize={6} />
          </CloseButton>
        </BodyWrapper>
      </Sidebar>
    </Container>
  );
};

export default ContactSidebar;
