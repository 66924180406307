import { parse } from 'date-fns';
import moment from 'moment';
import 'moment/locale/de';

export const futureMonths = moment.utc().startOf('month').toDate();

const isValidDate = (pseudoDate) => pseudoDate && !window.isNaN(pseudoDate);

export const formatDateBE = (dateObj) => dateObj && moment(dateObj).format('YYYY-MM-DD');

export const formatDateDays = (dateObj) => moment(dateObj).format('DD.MM.YYYY');
export const formatInquiryDateDays = (date) => {
  const dateString = date.toString().trim();
  return moment(new Date(dateString)).format('DD.MM.YYYY');
};

export const formatDateMonth = (dateObj) => moment(dateObj).format('MM.YYYY');

export const formatDateTime = (dateObj) => moment(dateObj).format('HH:mm');

export const formatDateWithTime = (dateObj) =>
  `${formatDateDays(dateObj)} ${formatDateTime(dateObj)}`;

export const parseMonthYear = (monthYearString) => parse(monthYearString, 'MM.yyyy', new Date());

export const formatMonthDashYear = (monthDashYearString) =>
  moment(parse(monthDashYearString, 'MM-yyyy', new Date())).format('MM/YYYY');

export const formatYear = (yearString) =>
  moment(parse(yearString, 'yyyy', new Date())).format('YYYY');

export const formatMonthWithYear = (monthAndYearOrIso) => {
  const date = new Date(monthAndYearOrIso);

  if (isValidDate(date)) {
    // is ISO date
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    return `${month}.${date.getUTCFullYear()}`;
  }
  // is formatted month.year
  return monthAndYearOrIso;
};

export const getPassedMonths = (date, parseFormat) => {
  let parsedDate = date;
  if (typeof date === 'string') {
    parsedDate = parse(date, parseFormat, new Date());
  }
  return moment().diff(moment(parsedDate), 'M');
};

export const formatDateToYear = (date) => {
  return moment(date).year();
};

export const getMaxBuildingYearDate = (buildingSpan) => {
  const currentYear = new Date().getFullYear();
  // UTC representation of the date limit
  return moment(`${currentYear + (buildingSpan || 0)}-01-01T00:00:00`)
    .utc()
    .toDate();
};

export function formatToRubyDateString(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss ZZ');
}
