import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { defaultFocusStyles } from 'theme/themeConstants';

export const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.125rem 1rem 1rem;
  color: ${(props) => props.theme.colors.text.tertiary};
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${(props) => props.theme.colors.text.tertiary};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  &:focus {
    ${defaultFocusStyles}
  }
`;
