import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { defaultFocusStyles, transition } from 'theme/themeConstants';

export const LogoLink = styled(NavLink)`
  display: block;

  :focus {
    ${defaultFocusStyles}
  }
`;

export const LogoImage = styled.img`
  max-height: 3rem;
  transition: max-height ${transition.default};
  will-change: max-height;

  ${mqMedium`
    max-height: ${({ biggerHeader }) => (biggerHeader ? '4rem' : '3rem')};
  `}
`;
