import React from 'react';

import { Divider, Stack, Text, VStack } from '@chakra-ui/react';

import Condition from 'components/Condition';
import type { UploadableFileTypes } from 'models/File.model';
import ButtonComponent, { VariantType } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FileList } from './components/FileList';
import { LoadingComponent } from './components/LoadingComponent';
import { useMultipleFiles } from '../hooks/useMultipleFiles';

export interface UploadButtonProps {
  text: string;
  variant: VariantType;
  leftIcon: JSX.Element;
  textColor: string;
}

interface MultipleFilesBlockProps {
  title: string;
  description?: string;
  files?: Array<UploadableFileTypes>;
  fileRequestId?: string;
  maxDescriptionLength?: number;
  showFileDownloadStatusBadge?: boolean;
  isFileUploadingEnabled?: boolean;
  isPrivateFile?: boolean;
  isAssessmentFile?: boolean;
  uploadButtonProps?: UploadButtonProps;
}

export const MultipleFilesBlock = ({
  title,
  description,
  files = [],
  fileRequestId,
  maxDescriptionLength = 100,
  showFileDownloadStatusBadge,
  isFileUploadingEnabled,
  isPrivateFile,
  isAssessmentFile,
  uploadButtonProps,
}: MultipleFilesBlockProps) => {
  const t = useTranslations();

  const { getRootProps, getInputProps, open, isUploading } = useMultipleFiles({
    fileRequestId,
    isPrivateFile,
    isAssessmentFile,
  });

  return (
    <>
      <VStack
        padding={8}
        spacing={4}
        backgroundColor="white"
        boxShadow="lg"
        borderRadius="lg"
        width="full"
        alignItems="normal"
      >
        <Stack direction={{ base: 'column', md: 'row' }} {...getRootProps()}>
          <VStack alignItems="start" flexGrow="1">
            <HeadingComponent variant="tertiary" color="brand.default" fontWeight="semibold">
              {title}
            </HeadingComponent>
            <Condition condition={Boolean(description)}>
              <Text color="text.secondary">{description}</Text>
            </Condition>
            <input {...getInputProps()} />
          </VStack>
          <Condition condition={Boolean(isFileUploadingEnabled)}>
            <LoadingComponent isLoading={isUploading}>
              <ButtonComponent
                onClick={open}
                variant={uploadButtonProps?.variant || 'link'}
                color={uploadButtonProps?.textColor || 'brand.default'}
                leftIcon={uploadButtonProps?.leftIcon || <ArrowRightIcon />}
                flexShrink="0"
              >
                {uploadButtonProps?.text || t('components.upload.uploadFurtherDocuments')}
              </ButtonComponent>
            </LoadingComponent>
          </Condition>
        </Stack>
        <Condition condition={Boolean(files.length)}>
          <Divider borderColor="border.darkGrey" />
          <FileList
            isAssessmentFile={isAssessmentFile}
            files={files}
            fileRequestId={fileRequestId}
            showFileDownloadStatusBadge={showFileDownloadStatusBadge}
            isPrivateFile={isPrivateFile}
          />
        </Condition>
      </VStack>
    </>
  );
};

export default MultipleFilesBlock;
