import React, { ReactNode } from 'react';

import _get from 'lodash/get';
import { Transition } from 'react-transition-group';

import { AriaDescription } from 'components/AriaDescription';
import Overlay from 'components/Overlay';
import LoadingRows from 'components/PortalTable/LoadingRows';
import {
  StyledTableScrollWrapper as ScrollWrapper,
  StyledTable as Table,
  StyledTableFooter as Footer,
  StyledTableWrapper as TableWrapper,
  StyledTableRow as Row,
  StyledTableCell as Cell,
} from 'components/PortalTable/styles';
import { ITableHeader } from 'components/PortalTable/TableHeader.model';
import TableHeader from 'components/PortalTable/TableHeaders/InquiryTableHeaders';
import Spinner from 'components/Spinner';
import { VisuallyHidden } from 'components/VisuallyHidden';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IPortalTable {
  headers: ITableHeader[];
  tableData: any[];
  children?: ReactNode;
  onRowClick: Function;
  isLoading?: boolean;
  isInitialized?: boolean;
  onSortChange: (columnKey: string) => void;
  sortBy: string;
  sortDirection?: SORT_DIRECTION;
}

const TABLE_ROW_SHOW_DELAY = 30;

const PortalTable: React.FC<IPortalTable> = ({
  children,
  tableData = [],
  onRowClick,
  headers,
  isLoading = false,
  isInitialized = false,
  onSortChange,
  sortBy,
  sortDirection,
}: IPortalTable) => {
  let tableHeaders = headers.filter((header) => header !== undefined);
  const t = useTranslations();

  const mapTableHeaders = ({
    key,
    text,
    renderHeader,
    notSortable,
    headerColor = 'primary',
    ariaLabel,
  }: ITableHeader) => (
    <TableHeader
      sortBy={sortBy}
      sortDirection={!text ? undefined : sortDirection}
      onSortChange={onSortChange}
      key={text}
      columnId={key}
      headerColor={headerColor}
      notSortable={notSortable}
      ariaLabel={ariaLabel}
    >
      {renderHeader ? renderHeader(text) : text}
    </TableHeader>
  );

  return (
    <TableWrapper>
      <Overlay show={isLoading && isInitialized} dimmed overlayComponent={<Spinner />}>
        <ScrollWrapper>
          <Table>
            <VisuallyHidden as="caption">{t('pages.inquiryList.table.caption')}</VisuallyHidden>
            <thead>
              <tr>{tableHeaders?.map(mapTableHeaders)}</tr>
            </thead>
            {!isInitialized && <LoadingRows numOfColumns={headers.length} />}
            <tbody>
              {tableData &&
                tableData.map((row, index) => (
                  <Transition
                    in
                    timeout={index * TABLE_ROW_SHOW_DELAY}
                    appear
                    mountOnEnter
                    unmountOnExit
                    key={row.id}
                  >
                    {(transitionState) => (
                      <>
                        <AriaDescription id={`inquiry-table-row-aria-label-${row.id}`}>
                          {t('pages.inquiryList.table.actions.openInquiryAriaLabel', {
                            number: index + 1,
                          })}
                        </AriaDescription>
                        <Row
                          className={transitionState}
                          onClick={() => onRowClick(row)}
                          data-testid="table-row"
                          heading={row.rowHighlight}
                          tabIndex={0}
                          aria-labelledby={`inquiry-table-row-aria-label-${row.id}`}
                        >
                          {tableHeaders.map(({ renderRow, key }) => {
                            const value = _get(row, key);
                            const uniqueKey = row.id + key;
                            return renderRow ? (
                              <Cell key={uniqueKey} heading={row.rowHighlight}>
                                {renderRow(value)}
                              </Cell>
                            ) : (
                              <Cell
                                key={uniqueKey}
                                heading={row.rowHighlight}
                                valueBold={row.valueBold}
                              >
                                {value}
                              </Cell>
                            );
                          })}
                        </Row>
                      </>
                    )}
                  </Transition>
                ))}
            </tbody>
          </Table>
        </ScrollWrapper>

        <Footer>{children}</Footer>
      </Overlay>
    </TableWrapper>
  );
};

export default PortalTable;
