import { SystemStyleObject } from '@chakra-ui/react';

import ViewIcon from 'theme/components/Icon/ViewIcon';
import ViewOffIcon from 'theme/components/Icon/ViewOffIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { chakraDefaultFocusStyles } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';

interface VisibilityButtonProps {
  isVisible: boolean;
  onClick: () => void;
  sx?: SystemStyleObject;
  'data-testid'?: string;
}

const VisibilityButton = ({
  isVisible,
  onClick,
  sx,
  'data-testid': dataTestId,
}: VisibilityButtonProps) => {
  const t = useTranslations();

  return (
    <IconButtonComponent
      sx={{
        padding: 2,
        borderRadius: chakraDefaultFocusStyles.borderRadius,
        _hover: {
          color: 'brand.default',
          cursor: 'pointer',
        },
        _focusVisible: {
          cursor: 'pointer',
          outline: 'none',
        },
        ...sx,
      }}
      label={t('buttons.showPassword')}
      icon={isVisible ? <ViewIcon /> : <ViewOffIcon />}
      onClick={onClick}
      data-testid={dataTestId}
    />
  );
};

export default VisibilityButton;
