import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { assignRealEstateExpertInquiry } from 'api/CompeonReverseApi/operation/loadRealEstateExperts/actions';
import useRealEstateExperts from 'pages/operationPortal/OperationInquiryList/useRealEstateExperts';
import { useToasts } from 'shared/hooks/useToasts';
import { assignedRealEstateExpertAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ProgressSection } from 'theme/components/ProgressSection';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { AssignRealEstateExpertModal } from './AssignRealEstateExpertModal';
import { AssignToRealEstateExpertSectionCard } from './AssignRealEstateExpertSectionCard';
import { AssignRealEstateExpertSelectorForm } from './AssignRealEstateExpertSelectorForm';
import { FormDataType } from './types';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const AssignInquiryToRealEstateExpertStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: Props) => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.assignInquiryToRealEstateExpert',
  );
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { success } = useToasts();
  const { realEstateExperts, isPending } = useRealEstateExperts();
  const { makeCall, isPending: forwardREELoading } = useDispatchApiCall();
  const inquiryId = useSelector(getInquiryIdSelector);

  const onSubmit = (formData: FormDataType) => {
    makeCall(assignRealEstateExpertInquiry(inquiryId!!, formData.realEstateExpertId), () => {
      dispatch(assignedRealEstateExpertAction());
      onClose();
      success({
        description: t('success'),
      });
    });
  };

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <AssignToRealEstateExpertSectionCard onClick={onOpen} />
      <AssignRealEstateExpertModal onClose={onClose} isOpen={isOpen}>
        <AssignRealEstateExpertSelectorForm
          onSubmit={onSubmit}
          realEstateExperts={realEstateExperts}
          onClose={onClose}
          isPending={isPending!!}
          forwardREELoading={forwardREELoading!!}
        />
      </AssignRealEstateExpertModal>
    </ProgressSection>
  );
};
