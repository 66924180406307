import * as React from 'react';

import { IconButton, ButtonProps, useStyleConfig, SystemStyleObject } from '@chakra-ui/react';
import { uniqueId } from 'lodash';

import { AriaDescription } from 'components/AriaDescription';

interface Props extends ButtonProps {
  icon: React.ReactElement;
  label: string;
  sx?: SystemStyleObject;
}

export const IconButtonComponent = React.forwardRef(({ icon, label, sx, ...props }: Props, ref) => {
  const styles = useStyleConfig('IconButton');

  const ariaLabelId = uniqueId('icon-button-aria-label-');

  return (
    <>
      <AriaDescription id={ariaLabelId}>{label}</AriaDescription>
      <IconButton
        aria-label={label}
        aria-labelledby={ariaLabelId}
        icon={icon}
        variant="unstyled"
        size={'full'}
        sx={{ ...styles, ...sx }}
        ref={ref}
        {...props}
      />
    </>
  );
});
