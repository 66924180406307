// Convert HEX colors to HSL
export function hexToHSL(HEX: string) {
  // Convert HEX to RGB first
  let r = 0;
  let g = 0;
  let b = 0;

  if (HEX.length === 4) {
    r = parseInt(`0x${HEX[1]}${HEX[1]}`, 16);
    g = parseInt(`0x${HEX[2]}${HEX[2]}`, 16);
    b = parseInt(`0x${HEX[3]}${HEX[3]}`, 16);
  } else if (HEX.length === 7) {
    r = parseInt(`0x${HEX[1]}${HEX[2]}`, 16);
    g = parseInt(`0x${HEX[3]}${HEX[4]}`, 16);
    b = parseInt(`0x${HEX[5]}${HEX[6]}`, 16);
  }

  // Then convert to HSL
  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(0);
  l = +(l * 100).toFixed(0);

  return `hsl(${h}, ${s}%, ${l}%)`;
}

// Half the saturation and set luminosity of a HSL color
export function hslAdjust(hsl: string, l: number) {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  const splitHSL = hsl.substr(4).split(')')[0].split(sep);

  const splitH = splitHSL[0];
  const splitS = parseInt(splitHSL[1].replace(/\s|%/g, ''), 10);
  const splitL = parseInt(splitHSL[2].replace(/\s|%/g, ''), 10);

  const setS = splitS / 2;
  const setL = !l ? splitL : l;

  return `hsl(${splitH}, ${setS}%, ${setL}%)`;
}

// Reduce luminosity of a HSL color
export function reduceLuminosity(hsl: string, l: number) {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  const splitHSL = hsl.substr(4).split(')')[0].split(sep);

  const splitH = splitHSL[0];
  const splitS = parseInt(splitHSL[1].replace(/\s|%/g, ''), 10);
  const splitL = parseInt(splitHSL[2].replace(/\s|%/g, ''), 10);

  const setL = splitL > 0 ? splitL - l : 0;

  return `hsl(${splitH}, ${splitS}%, ${setL}%)`;
}

// Set luminosity of a HSL color
export function setLuminosity(hsl: string, l: number) {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  const splitHSL = hsl.substr(4).split(')')[0].split(sep);

  const splitH = splitHSL[0];
  const splitS = parseInt(splitHSL[1].replace(/\s|%/g, ''), 10);
  const splitL = parseInt(splitHSL[2].replace(/\s|%/g, ''), 10);

  const setL = !l ? splitL : l;

  return `hsl(${splitH}, ${splitS}%, ${setL}%)`;
}

export function getAccessibleColor(hslColor: string) {
  // Helper functions
  function parseHSL(hsl: string) {
    const [hue, saturation, lightness] = hsl
      .replace(/hsla?|\(|\)|%|\s/g, '')
      .split(',')
      .map(Number);
    return [hue, saturation, lightness];
  }

  function formatHSL(h: number, s: number, l: number) {
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  // Parse the input HSL color
  let [h, s, l] = parseHSL(hslColor);

  // Adjust lightness to ensure high contrast with the brand color
  // and with both light and dark backgrounds
  if (l >= 50) {
    l = 30; // If the brand color is light, make the new color darker
  } else {
    l = 70; // If the brand color is dark, make the new color lighter
  }

  // Optionally adjust saturation for better contrast
  // For example, reduce saturation if it's too high
  if (s > 80) {
    s = 80;
  }

  // Return the new color in HSL format
  return formatHSL(h, s, l);
}
