import { Asset } from 'components/Asset';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinalPageAction } from './FinalPageAction';

const VideoLegitimationAction = () => {
  const t = useTranslations('pages.finalPage.videoLegitimation');

  return (
    <FinalPageAction asset={<Asset type="inquiry" value={InquiryAssetKeys.VIDEO_IDENT} />}>
      <HeadingComponent as="h3" variant="secondary" mb={4}>
        {t('heading')}
      </HeadingComponent>
      <TextComponent mb={6}>{t('description')}</TextComponent>
    </FinalPageAction>
  );
};

export default VideoLegitimationAction;
