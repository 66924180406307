import React from 'react';

import { Box, ListItem } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import { ArchiveMultipleDocumentsButton } from 'components/ArchiveDocuments/components/ArchiveMultipleDocumentsButton';
import { useArchiveDocumentsButton } from 'components/ArchiveDocuments/hooks/useArchiveDocumentsButton';
import Condition from 'components/Condition';
import FileRequestBlock from 'components/UploadBlock/NewFileRequestBlock/FileRequestBlock';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import type { ApiFile } from 'models/FileApi.model';
import type { IFileRequest } from 'models/FileRequest.model';
import { AppActionThunk } from 'models/types/AppThunk.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import FileSectionSubtitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionSubtitle';
import FileSectionTitle from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileSectionTitle';
import AddNewRequest from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/AddNewRequest';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { uploadFileToFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getFileRequestsSelector } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { UploadRequestList } from './styles';

const RequestsForCustomer: React.FC = () => {
  const fileRequests: IFileRequest[] = useSelector(getFileRequestsSelector);
  const { shouldShowArchiveDocumentsButton } = useArchiveDocumentsButton();

  const dispatch = useDispatch();
  const handleFileUploaded =
    (requestId: string) =>
    (file: ApiFile): AppActionThunk<void> =>
      dispatch(uploadFileToFileRequestAction(requestId, mapApiFile(file)));

  const inquiryId = useSelector(getInquiryIdSelector);
  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });
  const t = useTranslations('pages.inquiryDetails.documentExchange');

  const requiredFiles = fileRequests.filter((file) => file.required);

  const optionalFiles = fileRequests.filter((file) => !file.required);

  const isMultipleFilesPerUploadRequestEnabled = useConfig(
    CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST,
  );

  const canAddNewRequest = useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: false,
    [InquiryType.profiMittweida]: false,
    [InquiryType.default]: true,
  });

  return (
    <section>
      <Box display="flex" alignItems="center" justifyContent="space-between" w="100%" mb="2rem">
        <Condition condition={canAddNewRequest || shouldShowArchiveDocumentsButton}>
          <FileSectionTitle mb={0} variant="quaternary">
            {t('filesRequestedFromBank')}
          </FileSectionTitle>
        </Condition>
        {canAddNewRequest && <AddNewRequest />}
        {shouldShowArchiveDocumentsButton && <ArchiveMultipleDocumentsButton />}
      </Box>

      <Condition condition={Boolean(requiredFiles.length)}>
        <FileSectionSubtitle>{t('requiredFiles')}</FileSectionSubtitle>
        <UploadRequestList>
          {requiredFiles.map((request) =>
            isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
              <ListItem key={request.id}>
                <MultipleFilesBlock
                  fileRequestId={request.id}
                  isFileUploadingEnabled
                  {...request}
                />
              </ListItem>
            ) : (
              <ListItem key={request.id}>
                <FileRequestBlock
                  key={request.id}
                  fileRequest={request}
                  url={uploadUrl}
                  onUpload={handleFileUploaded(request.id)}
                />
              </ListItem>
            ),
          )}
        </UploadRequestList>
      </Condition>
      <Condition condition={Boolean(optionalFiles.length)}>
        <FileSectionSubtitle>{t('optionalFiles')}</FileSectionSubtitle>
        <UploadRequestList>
          {optionalFiles.map((request) =>
            isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
              <ListItem key={request.id}>
                <MultipleFilesBlock
                  fileRequestId={request.id}
                  isFileUploadingEnabled
                  {...request}
                />
              </ListItem>
            ) : (
              <ListItem key={request.id}>
                <FileRequestBlock
                  fileRequest={request}
                  url={uploadUrl}
                  onUpload={handleFileUploaded(request.id)}
                />
              </ListItem>
            ),
          )}
        </UploadRequestList>
      </Condition>
    </section>
  );
};

export default RequestsForCustomer;
