import React, { useEffect, useMemo } from 'react';

import { debounce as _debounce } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { NewStaticFieldWithField } from 'components/StaticField';
import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { replaceUndefinedWithZero } from 'new/form/common/replaceUndefinedWithNumber';
import { translations } from 'new/form/common/types';
import { useGetStaticErrorTranslation } from 'shared/hooks/useGetStaticErrorTranslation';
import { ariaLiveAddAnnouncement } from 'store/accessibility/reducer';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

const { financingAmount } = hausbankFields.financingNeedPage.financingNeedSection;
const financingAmountChangedAriaLive =
  translations.inquiryType.hausbank.pages.financingNeed.sections.financingNeed.fields
    .financingAmount.financingAmountChangedAriaLive;

function useFinancingAmount() {
  const { change } = useForm();

  // Get current error message if any
  const error = useGetStaticErrorTranslation(financingAmount);

  const dispatch = useDispatch();
  const t = useTranslations();
  const {
    values: {
      financingNeedPage: {
        financingNeedSection: {
          investmentAmount: formInvestmentAmount,
          maintenanceReserve: formMaintenanceReserve,
          wegContribution: formWegContribution,
          constructionSubsidies: formConstructionSubsidies,
        },
      },
    },
  } = useFormState<HausbankFieldTypes>();

  const debouncedDispatch = useMemo(
    () =>
      _debounce((messages: string[]) => {
        for (const message of messages) {
          dispatch(ariaLiveAddAnnouncement(message));
        }
      }, 1000),
    [dispatch],
  );

  useEffect(() => {
    const { investmentAmount, maintenanceReserve, wegContribution, constructionSubsidies } =
      replaceUndefinedWithZero({
        investmentAmount: formInvestmentAmount,
        maintenanceReserve: formMaintenanceReserve,
        wegContribution: formWegContribution,
        constructionSubsidies: formConstructionSubsidies,
      });

    const total = investmentAmount - maintenanceReserve - wegContribution - constructionSubsidies;

    change(financingAmount, total);

    const message = t(financingAmountChangedAriaLive, { financingAmount: total });
    const messages = [message];
    if (error) {
      messages.push(error);
    }
    debouncedDispatch(messages);

    // Cleanup on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [
    change,
    debouncedDispatch,
    error,
    formConstructionSubsidies,
    formInvestmentAmount,
    formMaintenanceReserve,
    formWegContribution,
    t,
  ]);
}

export const FinancingAmount = () => {
  useFinancingAmount();

  return (
    <NewStaticFieldWithField
      name={financingAmount}
      parseValue={(value: number) => formatPrice(value, true)}
    />
  );
};
