import { omit } from 'lodash';
import { useSelector } from 'react-redux';

import { mmvFields } from 'mmv/inquiry/fields';
import BeneficiaryOwnerSection from 'modules/InquiryDetails/DetailedInformation/BeneficiaryOwnersSection/BeneficiaryOwnerSection';
import LegalRepresentativesSection from 'modules/InquiryDetails/DetailedInformation/LegalRepresentativesSection/LegalRepresentativesSection';
import { DetailedInformationWrapper } from 'modules/InquiryDetails/DetailedInformation/styles';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { InquiryDetailsSummary } from 'pages/customerPortal/InquiryDetails/DetailedInformation/InquiryDetails/InquiryDetailsSummary';
import { getNewInquiryDetails } from 'store/newInquiryDetails/selector';

import { SummaryStepProvider } from '../../../../../new/summary/SummaryPageContext';

const customMmvFields = omit(
  mmvFields,
  'customerDataPage.beneficialOwnerSection.isFictitiousBeneficialOwner',
);

export const MmvDetailedInquiryInformation = () => {
  const values = useSelector(getNewInquiryDetails);
  return (
    <article>
      <InquiryDetailsHeader />
      <DetailedInformationWrapper>
        <SummaryStepProvider values={values}>
          <InquiryDetailsSummary fields={customMmvFields} />
          <LegalRepresentativesSection />
          <BeneficiaryOwnerSection />
        </SummaryStepProvider>
      </DetailedInformationWrapper>
    </article>
  );
};
