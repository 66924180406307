import React from 'react';

import { AriaDescription } from 'components/AriaDescription';
import { StyledCompanyNav } from 'pages/operationPortal/CompaniesDetails/styles';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useInquiryDetailsCompanyDetailsRoutes } from './useInquiryDetailsCompanyDetailsRoutes';

interface Props {
  inquiryId: string;
  companyId: string;
}

const companySubNavAriaLabelId = 'company-sub-navigation-aria-label';

const CompanySubnavigation: React.FC<Props> = () => {
  const routeLinks = useInquiryDetailsCompanyDetailsRoutes();
  const t = useTranslations();

  return (
    <>
      <AriaDescription id={companySubNavAriaLabelId}>
        {t('pages.inquiryDetails.companySubNavigationAriaLabel')}
      </AriaDescription>
      <StyledCompanyNav subNav aria-labelledby={companySubNavAriaLabelId}>
        <ul>{routeLinks}</ul>
      </StyledCompanyNav>
    </>
  );
};

export default CompanySubnavigation;
