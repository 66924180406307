import { buildElementIdString } from './buildElementIdString';

export const getInputDescribedByIds = (
  name: string,
  error: string | string[],
  connectSideSymbol: boolean,
) => {
  const symbolWrapperId = connectSideSymbol ? `input-${name}-symbol-wrapper` : '';
  return `${symbolWrapperId} ${buildElementIdString(name, 'error', error)} ${name}-optional-label`;
};
