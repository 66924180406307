import DisabledFormSection from 'components/DisabledFormSection';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { mittweidaFields, MittweidaSections } from 'mittweida/inquiry/fields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  demolitionNecessary,
  heritageProtected,
  developmentNecessary,
  ownSalesPlanned,
  energyPass,
  buildingPermit,
  permitBasis,
  permitStatus,
  notesOnPermit,
} = mittweidaFields.financingNeedPage.otherProjectInformation;

export const OtherProjectInformationSection = () => {
  const t = useTranslations();
  const { fallback, title } =
    translations.inquiryType.mittweida.pages.financingNeed.sections.otherProjectInformation;
  const permitBasisOptions = useInquirySelectOptions(permitBasis);
  const permitStatusOptions = useInquirySelectOptions(permitStatus);

  return (
    <Condition<MittweidaSections>
      validation={'objectInformationSection'}
      fallback={
        <DisabledFormSection sectionNumber={4} placeholder={t(fallback)} title={t(title)} />
      }
    >
      <FormSection title={t(title)} name={'otherObjectInformation'} sectionNumber={4}>
        <FormRow>
          <YesNoRadioGroupWithField name={demolitionNecessary} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={heritageProtected} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={developmentNecessary} />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={ownSalesPlanned} optional />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={energyPass} optional />
        </FormRow>
        <FormRow>
          <YesNoRadioGroupWithField name={buildingPermit} optional />
        </FormRow>
        <FormRow>
          <SelectWithField
            name={permitBasis}
            placeholder={t(translations.placeholders.pleaseChoose)}
            options={permitBasisOptions}
            data-testid={'mittweida-permit-basis-select-field'}
            optional
          />
        </FormRow>
        <FormRow>
          <SelectWithField
            name={permitStatus}
            placeholder={t(translations.placeholders.pleaseChoose)}
            options={permitStatusOptions}
            data-testid={'mittweida-permit-status-select-field'}
            optional
          />
        </FormRow>
        <FormRow>
          <TextAreaWithField name={notesOnPermit} optional />
        </FormRow>
      </FormSection>
    </Condition>
  );
};
