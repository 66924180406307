import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import CaretDownIcon from 'theme/components/Icon/CaretDownIcon';

import { chakraDefaultFocusStyles, textStyle } from '../../theme/themeConstants';

interface SeperatorProps {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  divider?: boolean;
  action?: React.ReactNode;
}

export const Seperator = ({ title, children, divider, action }: SeperatorProps) => {
  return (
    <>
      <Accordion as="section" position="relative" allowMultiple defaultIndex={[0]}>
        <AccordionItem border="0">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                p={0}
                {...textStyle.sizeLTight}
                textAlign="left"
                color="brand.default"
                _focus={{
                  ...chakraDefaultFocusStyles,
                }}
                _hover={{
                  color: 'text.primary',
                  backgroundColor: 'inherit',
                }}
              >
                <Flex justifyContent="space-between" alignItems="center" w="full">
                  <HStack>
                    {isExpanded ? (
                      <CaretDownIcon boxSize={6} mr={2} />
                    ) : (
                      <CaretDownIcon boxSize={6} mr={2} transform="rotate(-90deg)" />
                    )}
                    <Text
                      as="h3"
                      fontSize="20px"
                      lineHeight="1.3"
                      fontWeight={FONT_WEIGHT_MEDIUM}
                      marginInlineStart="0 !important"
                    >
                      {title}
                    </Text>
                  </HStack>
                  {action}
                </Flex>
              </AccordionButton>
              <AccordionPanel position="relative" pt={8} px={2} pb={4}>
                {children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      {divider && <Divider mt={4} mb={4} />}
    </>
  );
};
