import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { defaultFocusStyles, transition } from 'theme/themeConstants';

export const StyledNavLink = styled.div`
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.text.secondary};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.5rem 0;
    text-decoration: none;
    color: inherit;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 2px;
      transform: translateY(-2px);
      background-color: ${({ theme }) => theme.colors.primary};
      transition: left ${transition.default}, width ${transition.default};
    }

    &.active {
      /* stylelint-disable-line selector-no-qualifying-type */
      &::after {
        left: 0;
        width: 100%;
      }

      &:hover {
        &::after {
          left: 50%;
          width: 0;
        }
      }
    }

    &:hover {
      &::after {
        left: 0;
        width: 100%;
      }
    }

    &:focus {
      ${defaultFocusStyles}
    }
  }
`;
