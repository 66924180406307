import { ValidationErrorItem } from '@hapi/joi';
import { useField } from 'react-final-form';

import { useErrorTranslator } from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';
import { getErrorMessageForStaticField } from 'utils/form/withForm.helpers';

export const useGetStaticErrorTranslation = (fieldName: string) => {
  const errorTranslator = useErrorTranslator();
  const field = useField(fieldName);
  const errorMessage = getErrorMessageForStaticField(field.meta) as string | ValidationErrorItem;
  if (errorMessage) {
    return errorTranslator(errorMessage) ?? undefined;
  }
  return undefined;
};
