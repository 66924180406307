import { useForm } from 'react-final-form';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { usePartnerConfig } from 'config/partner/hooks';
import paths from 'constants/paths';
import { validateSectionFields } from 'modules/Inquiry/Form/formConditions';
import {
  CONTACT_PERSON_ADVERTISING_CONSENT,
  CONTACT_PERSON_ADVERTISING_CONSENT_TIMESTAMP,
  CONTACT_PERSON_SECURITY_CONSENT,
} from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { formatToRubyDateString } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

type Props = {
  sectionNumber: number;
};

export const validatebfsConsentSection = () =>
  validateSectionFields([CONTACT_PERSON_SECURITY_CONSENT]);

export const BfsTermsAndConditions = ({ sectionNumber }: Props) => {
  const { required, mustBeTrue } = useFieldValidators();
  const {
    details: { email },
  } = usePartnerConfig();
  const { change } = useForm();
  const t = useTranslations('pages.companyDetails.sections.termsAndConditions.bfs');

  return (
    <FormSection sectionNumber={sectionNumber} title={t('title')}>
      <FormRow>
        <CheckboxWithField
          name={CONTACT_PERSON_SECURITY_CONSENT}
          validate={combineValidators(required, mustBeTrue)}
          text={
            <span>
              {t('fields.contactPersonSecurityConsent.label', {
                termsAndConditions: (
                  <a href={paths.termsAndConditions}>
                    {t('fields.contactPersonSecurityConsent.links.termsAndConditions')}
                  </a>
                ),
                dataSecurity: (
                  <a href={paths.privacyPolicy}>
                    {t('fields.contactPersonSecurityConsent.links.dataSecurity')}
                  </a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
      <FormRow>
        <CheckboxWithField
          name={CONTACT_PERSON_ADVERTISING_CONSENT}
          text={
            <span>
              <MarkdownWrapper
                text={t('fields.contactPersonAdvertisingConsent.label', {
                  email,
                })}
              />
            </span>
          }
          onChange={(value: boolean) => {
            if (value) {
              change(
                CONTACT_PERSON_ADVERTISING_CONSENT_TIMESTAMP,
                formatToRubyDateString(new Date()),
              );
            } else {
              change(CONTACT_PERSON_ADVERTISING_CONSENT_TIMESTAMP, undefined);
            }
          }}
          optional
        />
      </FormRow>
    </FormSection>
  );
};
