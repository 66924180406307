import React from 'react';

import PropTypes from 'prop-types';

import { hasError } from 'modules/Inquiry/Form/Field/service';
import { FinalFormInputPropTypes, FinalFormMetaPropTypes } from 'utils/form/propTypes';
import { formatDecimalWithPrecision2 } from 'utils/valueFormats';

import PriceInput from './PriceInput';

const PriceInputAdapter = ({
  label,
  input: { name, onFocus, onBlur, value },
  meta: { visited, error },
  placeholder,
  onChange,
}) => {
  const formattedValue = formatDecimalWithPrecision2(value);
  return (
    <PriceInput
      label={label}
      hasError={hasError(visited, error)}
      value={formattedValue}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      placeholder={placeholder}
      error={error}
    />
  );
};

PriceInputAdapter.propTypes = {
  label: PropTypes.string.isRequired,
  input: FinalFormInputPropTypes.isRequired,
  meta: FinalFormMetaPropTypes.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PriceInputAdapter.defaultProps = {
  placeholder: null,
};

export default PriceInputAdapter;
