import React, { ReactNode } from 'react';

import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import Title from 'components/FormSection/Title';
import Text from 'components/Text/Text';

interface IFormSectionLayout {
  sectionNumber?: number;
  disabled?: boolean;
  title: string;
  children: ReactNode;
  showSectionTitle?: boolean;
  hideBottomBorder?: boolean;
  hideTopBorder?: boolean;
  hideSectionNumber?: boolean;
}

const FormSectionLayout = ({
  sectionNumber,
  disabled,
  title,
  children,
  showSectionTitle,
  hideBottomBorder,
  hideTopBorder,
  hideSectionNumber,
}: IFormSectionLayout) => {
  const showSectionNumber = !hideSectionNumber && !!sectionNumber;
  return (
    <Section hideBottomBorder={hideBottomBorder} hideTopBorder={hideTopBorder}>
      {title && (
        <Title>
          {showSectionTitle && (
            <Text as="legend" inactive={disabled}>
              {`${showSectionNumber ? `${sectionNumber}. ` : ''}${title}`}
            </Text>
          )}
        </Title>
      )}

      <Content inactive={disabled}>{children}</Content>
    </Section>
  );
};

export default FormSectionLayout;
