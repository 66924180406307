import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useOfferFields } from 'modules/helpers/useOfferFields';
import { useGetIndicativeOfferFormattedValueMap } from 'pages/customerPortal/InquiryDetails/Dashboard/utils';
import {
  getIndicativeCondition,
  getIndicativeConditionCompleted,
  getIndicativeOffer,
} from 'store/inquiryDetails/selectors';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { HeadingComponent } from 'theme/components/Heading';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  id?: string;
}

export const ShowConditionsAction = ({ id }: Props) => {
  const t = useTranslations();
  const fields = useOfferFields();
  const indicativeConditionCompleted = useSelector(getIndicativeConditionCompleted);
  const indicativeConditions = useSelector(getIndicativeCondition) as Record<string, string>;
  const indicativeOffer = useSelector(getIndicativeOffer) as Record<string, string>;
  const lane = useSelector(getInquiryLane);
  const getIndicativeOfferFormattedValueMap = useGetIndicativeOfferFormattedValueMap();

  return (
    <>
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" id={id} mb={2} variant="secondary">
            {t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.title.${lane}`)}
          </HeadingComponent>
          <TextComponent mb={3}>
            {t(`pages.inquiryDetails.dashboard.actions.nonBindingOffer.description.${lane}`)}
          </TextComponent>
        </GridItem>
      </Grid>
      <Grid templateColumns={['repeat(1, 1fr)', null, null, '1fr 1fr 1fr']} gap={6}>
        {fields.map((item) => (
          <GridItem key={item.name}>
            <Box
              border="1px"
              borderColor="border.lightGrey"
              p="8"
              bgColor="background.white"
              boxShadow="default"
            >
              <DescriptionList>
                <DescriptionListTerm display="flex" alignItems="center">
                  {t(item.title)}
                  {item.info && (
                    <PopoverComponent
                      ml="1px"
                      transform="translateY(-1px)"
                      text={t(item.info)}
                      trigger={
                        <IconButtonComponent
                          icon={<InfoIcon boxSize={6} display="block" />}
                          label={t('buttons.moreInfos')}
                        />
                      }
                    />
                  )}
                </DescriptionListTerm>
                <DescriptionListDescription>
                  {indicativeConditionCompleted
                    ? getIndicativeOfferFormattedValueMap(
                        item,
                        indicativeConditions[item.name] ?? indicativeOffer[item.name],
                      )
                    : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.comingSoon')}
                </DescriptionListDescription>
              </DescriptionList>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
