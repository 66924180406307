import React from 'react';

import { AriaLive } from 'components/App/GlobalComponents/AriaLive';

import CspMeta from './CspMeta';

export const GlobalComponents = () => {
  return (
    <>
      <AriaLive />
      <CspMeta />
    </>
  );
};
