import React from 'react';

import { DashboardActionsType } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import paths from 'constants/paths';
import { DashboardActionProps } from 'pages/customerPortal/InquiryDetails/Dashboard/NewDashboard';
import ProgressSectionsWrapper from 'pages/customerPortal/InquiryDetails/Dashboard/ProgressSectionsWrapper';
import { ProgressSuccess } from 'pages/customerPortal/InquiryDetails/Dashboard/ProgressSuccess';
import SummaryCard from 'pages/customerPortal/InquiryDetails/Dashboard/SummaryCard';
import SummaryHeader from 'pages/customerPortal/InquiryDetails/Dashboard/SummaryHeader';
import { useActionCalculations } from 'pages/customerPortal/InquiryDetails/Dashboard/useDashboardActions';
import { mapDashboardActionAttributes } from 'pages/customerPortal/InquiryDetails/Dashboard/useDashboardActions';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { useScrollTopOnLoad } from 'utils/hooks/useScrollTopOnLoad';

import Actions from './Actions';
import CloseInquiry from './CloseInquiry';
import Communication from './Communication';
import ForwardInquiry from './ForwardInquiry';
import ResendCustomerInvitation from './ResendCustomerInvitation';
import { AuditRepresentativesStep } from './Steps/AuditRepresentativesStep';
import { CreateIndicativeConditionsStep } from './Steps/CreateIndicativeConditionsStep';
import CrefoQueryStep from './Steps/CrefoQueryStep';
import { ShowIndicativeConditionsStep } from './Steps/ShowIndicativeConditionsStep';
import { StartSignatureProcessStep } from './Steps/StartSignatureProcessStep';
import { UploadOfferStep } from './Steps/UploadOfferStep';
import { WelcomePackageStep } from './Steps/WelcomePackageStep';

export const DashboardAction = ({
  type,
  dashboardActionProps,
}: {
  type: DashboardActionsType;
  dashboardActionProps: DashboardActionProps;
}) => {
  const { id } = useParamsSafe(['id']);

  switch (type) {
    case DashboardActionsType.UPLOAD_CONTRACTS:
    case DashboardActionsType.PROVIDE_CONTRACT:
      return (
        <UploadOfferStep
          actionUrl={paths.operation.inquiryDetails.uploadOffer.replace(':id', id)}
          {...dashboardActionProps}
        />
      );
    case DashboardActionsType.AUDIT_REPRESENTATIVES:
      return <AuditRepresentativesStep {...dashboardActionProps} />;
    case DashboardActionsType.START_BANKERS_SIGNATURE_PROCESS:
    case DashboardActionsType.START_BANKERS_AND_ASSOCIATES_SIGNATURE_PROCESS:
      return <StartSignatureProcessStep {...dashboardActionProps} />;
    case DashboardActionsType.CREATE_INDICATIVE_CONDITIONS:
      return <CreateIndicativeConditionsStep {...dashboardActionProps} />;
    case DashboardActionsType.SHOW_INDICATIVE_CONDITIONS:
      return <ShowIndicativeConditionsStep {...dashboardActionProps} />;
    case DashboardActionsType.UPLOAD_WELCOME_PACKAGE:
      return <WelcomePackageStep {...dashboardActionProps} />;
    case DashboardActionsType.REQUEST_CREFO_REPORTS:
      return <CrefoQueryStep {...dashboardActionProps} />;
    default:
      return null;
  }
};

export const NewOperationsDashboard = () => {
  const {
    actionsDone,
    firstOpenTask,
    completedValue,
    isLoading: dashboardActionsIsLoading,
    dashboardActions,
    progressTotal,
    isCompleted,
    canShowProgress,
    isLastItem,
  } = useActionCalculations();

  useScrollTopOnLoad();

  const header = (
    <>
      <SummaryHeader />
      <SummaryCard
        completedValue={completedValue}
        progressIncrement={actionsDone}
        progressTotal={progressTotal}
        isCompleted={isCompleted}
        openTaskId={firstOpenTask}
        canShowProgress={canShowProgress}
      />
    </>
  );

  const progressSections = (
    <>
      {dashboardActions.map((action, index) => (
        <DashboardAction
          key={action.id}
          type={action.type}
          dashboardActionProps={{
            ...mapDashboardActionAttributes(action, index),
            isPending: dashboardActionsIsLoading,
            isLast: isLastItem(index),
          }}
        />
      ))}
      {isCompleted && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
      <Actions />
      <ResendCustomerInvitation />
      <ForwardInquiry />
      <Communication />
      <CloseInquiry />
    </>
  );

  return (
    <ProgressSectionsWrapper
      header={header}
      progressSections={progressSections}
      isLoading={dashboardActionsIsLoading}
    />
  );
};
