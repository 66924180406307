import { SelectWithField } from 'components/Selects/Select';
import { mittweidaFields } from 'mittweida/inquiry/fields';
import {
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
  USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
  USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
} from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const useLegalFormOptions = () => {
  const legalFormOptions = useInquirySelectOptions(USER_PROFILE_LEGAL_FORM);

  const excludedDefaultOptions = [
    USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
    USER_PROFILE_LEGAL_FORM__STIFTUNG_DES_OFFENTLICHEN_RECHTS,
    USER_PROFILE_LEGAL_FORM__ORDENSGEMEINSCHAFT,
  ];

  return legalFormOptions.filter((obj) => {
    return !excludedDefaultOptions.includes(obj.value);
  });
};

export const LegalForm = () => {
  const { legalForm } = mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company;
  const t = useTranslations();

  const legalFormOptions = useLegalFormOptions();

  return (
    <SelectWithField
      name={legalForm}
      options={legalFormOptions}
      placeholder={t(translations.placeholders.pleaseChoose)}
      data-testid={'select-field-legal-form'}
    />
  );
};
