import { VStack } from '@chakra-ui/react';
import { useFormState } from 'react-final-form';

import Page from 'components/Page/Page';
import PageTitle from 'components/PageTitle/PageTitle';
import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { SummaryActions } from 'new/summary/components/SummaryActions';
import { InquirySummarySections } from 'new/summary/components/SummarySections';
import { SummaryStepProvider } from 'new/summary/SummaryPageContext';
import SummaryHeading from 'pages/inquiryFlow/SummaryPage/SummaryHeading/SummaryHeading';
import { parseStringBoolean } from 'utils/helpers';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

export const DzbSummaryStep = () => {
  const t = useTranslations();
  const { values } = useFormState<DzbFieldTypes>();
  useScrollToTop();
  const { allInformationAvailable } = values.personalDataPage.beneficialOwnersSection;

  const filteredValues = parseStringBoolean(allInformationAvailable)
    ? values
    : {
        ...values,
        personalDataPage: {
          ...values.personalDataPage,
          beneficialOwnersSection: {
            ...values.personalDataPage.beneficialOwnersSection,
            beneficialOwners: [],
          },
        },
      };
  return (
    <Page hero={<SummaryHeading />}>
      <PageTitle title={t(translations.pages.inquiryDetails.detailed.summary.title)} />
      <VStack gap={4}>
        <SummaryStepProvider<DzbFieldTypes> values={filteredValues}>
          <InquirySummarySections />
        </SummaryStepProvider>
        <SummaryActions />
      </VStack>
    </Page>
  );
};
