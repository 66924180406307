import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ProgressStage from 'components/ProgressStage';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useCalculateProgress } from './useCalculateProgress';
import useIsScrolledToTop from '../../../utils/hooks/useIsScrolledToTop';
import { useInquiryProcessConfig } from '../state/inquiryProcessConfig/hooks';

/**
 * currentStep is only calculated in the inquiry process which does not include the summary page and thank you page
 * thus we never showed the last page as completed because it was always active. We need to check if the current stage
 * is the current page as well to show it as completed.
 */

function useIsCurrentStepCurrentPage() {
  const {
    formStateData: { routeToPageNameMap },
    state: { inquiryMode, currentStep },
  } = useInquiryProcessConfig();
  const { location } = useHistory();

  const currentPath = routeToPageNameMap[currentStep as any][inquiryMode];
  const path = location.pathname;
  const compare = path.split('/').pop();
  return !currentPath ? false : currentPath.includes(compare ?? 'not found');
}

export const NewProgressBar = () => {
  const t = useTranslations();
  useCalculateProgress();
  const contentIsScrolledToTop = useIsScrolledToTop();
  const { wrapper } = useMultiStyleConfig('ProgressBar', { contentIsScrolledToTop });
  const {
    state: { pageValidations, currentStep },
    formStateData: { stepSequence, stepData },
  } = useInquiryProcessConfig();
  const isCurrentPage = useIsCurrentStepCurrentPage();
  const onlyShowFinishedProgressCounter = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.cbBank]: true,
  });

  return (
    <Box sx={wrapper} willChange={'transform'}>
      {stepSequence.map((page, index) => {
        const { progress } = pageValidations[page as any];
        return (
          <ProgressStage
            key={index}
            stepName={t(stepData[page as any].progressBarTitle)}
            validSections={progress.numberOfValidSections}
            numberOfSections={progress.numberOfSections}
            active={index <= stepSequence.indexOf(currentStep)}
            isCurrentStage={currentStep === page && isCurrentPage}
            onlyShowFinishedProgressCounter={onlyShowFinishedProgressCounter}
          />
        );
      })}
    </Box>
  );
};
