import styled from 'styled-components/macro';

import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { defaultFocusStyles } from 'theme/themeConstants';

const StyledLogoutButton = styled.button`
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: 1.125rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    ${defaultFocusStyles}
  }
`;

export default StyledLogoutButton;
