import { Divider, Flex, Box } from '@chakra-ui/react';
import moment from 'moment';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { HeadingComponent } from 'theme/components/Heading';
import TimeIcon from 'theme/components/Icon/TimeIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

type RiskCardProps = {
  children: React.ReactNode;
  probabilityOfDefault?: string;
  timeOfMeasurement?: string;
  isRating?: boolean;
  isMarketpage?: boolean;
  isRiskAnalysisPage?: boolean;
};

export const RiskCard = ({
  children,
  probabilityOfDefault,
  timeOfMeasurement,
  isRating,
  isMarketpage,
  isRiskAnalysisPage = false,
}: RiskCardProps) => {
  return (
    <Flex
      as="section"
      flexDirection="column"
      border="1px"
      borderColor="border.lightGrey"
      px="6"
      py="8"
      bgColor="background.white"
      boxShadow="default"
      height={isRiskAnalysisPage ? '25rem' : '100%'}
    >
      <Box flex="1">
        {children}
        {!!isRating || !!isMarketpage ? '' : <ProbabilityOfDefault text={probabilityOfDefault} />}
      </Box>
      {!isMarketpage && <TimeOfMeasurement timestamp={timeOfMeasurement} />}
    </Flex>
  );
};

type RiskHeadingProps = {
  children: React.ReactNode;
};

export const RiskHeading = ({ children }: RiskHeadingProps) => {
  return (
    <HeadingComponent as="h4" variant="secondary" data-testid={'CD-Risk-Analysis-Sub-Sections'}>
      {children}
    </HeadingComponent>
  );
};

type RiskContainerProps = {
  children: React.ReactNode;
};

export const RiskContainer = ({ children }: RiskContainerProps) => {
  return <Flex alignItems="center">{children}</Flex>;
};

type ProbabilityOfDefaultProps = {
  text?: string;
};

export const ProbabilityOfDefault = ({ text }: ProbabilityOfDefaultProps) => {
  const t = useTranslations('pages.companiesDetails.riskAnalysis');

  return (
    <>
      <Divider opacity="1" mt="2" mb="6" borderColor="border.lightGrey" />
      <Box as="dl" mb="6" color="text.secondary">
        <TextComponent as="dt" mb="1">
          {t('probabilityOfFailure')}
        </TextComponent>
        <TextComponent as="dd" fontWeight={FONT_WEIGHT_BOLD}>
          {text ? `${text} PD` : '–'}
        </TextComponent>
      </Box>
    </>
  );
};

type TimeOfMeasurementProps = {
  timestamp?: string;
};

const TimeOfMeasurement = ({ timestamp }: TimeOfMeasurementProps) => {
  const dateTime = timestamp ? timestamp : '';
  const formattedTime = `${moment(dateTime)
    .utcOffset(dateTime, false)
    .format('YYYY.MM.DD, HH:mm')} Uhr`;
  const t = useTranslations('pages.companiesDetails.riskAnalysis');

  return (
    <Flex alignItems="center" color="text.tertiary">
      <TimeIcon boxSize={6} mr={1} />
      {timestamp ? (
        <time>{formattedTime}</time>
      ) : (
        <TextComponent as="span">{t('noTime')}</TextComponent>
      )}
    </Flex>
  );
};
