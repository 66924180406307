import React from 'react';

import { ListItem } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import { translations } from 'new/form/common/types';
import { FinalPageAction } from 'pages/inquiryFlow/FinalPage/actions/FinalPageAction';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { HausbankFinalPageTitle } from './styles';

export const HausbankFinalOfferAction = () => {
  const t = useTranslations();
  const { heading, description } = translations.inquiryType.hausbank.pages.final.offer;

  return (
    <ListItem>
      <FinalPageAction asset={<Asset type={'inquiry'} value={InquiryAssetKeys.OFFER} />}>
        <HausbankFinalPageTitle>{t(heading)}</HausbankFinalPageTitle>
        <TextComponent mb={6}>{t(description)}</TextComponent>
      </FinalPageAction>
    </ListItem>
  );
};
