import React from 'react';

import { bool, number, string } from 'prop-types';

import StageProgressCounter from 'components/StageProgressCounter';

import Fill from './Fill';
import NameWrapper from './NameWrapper';
import Outer from './Outer';
import Wrapper from './Wrapper';

const ProgressStage = ({
  stepName,
  validSections,
  numberOfSections,
  active,
  isCurrentStage,
  onlyShowFinishedProgressCounter,
}) => {
  return (
    <Outer>
      <Fill ratio={active ? validSections / numberOfSections : 0} />
      <Wrapper
        active={active}
        isCurrentStage={isCurrentStage}
        tabIndex={0}
        aria-valuemin={0}
        aria-valuenow={validSections}
        aria-valuemax={numberOfSections}
        aria-label={`${stepName} page progress`}
        role="progressbar"
      >
        <NameWrapper active={active} isCurrentStage={isCurrentStage}>
          {stepName}
        </NameWrapper>
        <StageProgressCounter
          active={active}
          finished={active && !isCurrentStage}
          current={validSections}
          totalCount={numberOfSections}
          onlyShowFinishedProgressCounter={onlyShowFinishedProgressCounter}
        />
      </Wrapper>
    </Outer>
  );
};

ProgressStage.propTypes = {
  validSections: number,
  numberOfSections: number,
  active: bool,
  stepName: string.isRequired,
  isCurrentStage: bool.isRequired,
  onlyShowFinishedProgressCounter: bool,
};

ProgressStage.defaultProps = {
  active: false,
  validSections: 0,
  numberOfSections: 2,
  onlyShowFinishedProgressCounter: false,
};

export default React.memo(ProgressStage);
