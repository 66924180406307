import React, { useState, useEffect, useMemo } from 'react';

import Downshift from 'downshift';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { SelectOptionShape } from 'components/PortalFormFields/SelectField';
import AutocompleteInput from 'components/PortalFormFields/SelectField/Autocomplete/AutocompleteInput';
import Element from 'components/PortalFormFields/SelectField/Autocomplete/Element';
import { NoEntriesInAutocomplete } from 'components/PortalFormFields/SelectField/Autocomplete/styles';
import {
  StyledSelectList,
  StyledSelectWrapper,
  StyledFixedElements,
} from 'components/PortalFormFields/SelectField/styles';
import SearchIcon from 'theme/components/Icon/SearchIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import VirtualList from './VirtualList';

const DEFAULT_DROPDOWN_HEIGHT = 300;

const Autocomplete = ({
  onChange,
  onSelect,
  onFocus,
  name,
  options,
  loading,
  placeholder,
  symbol,
  menuShowThreshold,
  value,
  inputInitialValue,
  optionMinHeight,
  closeOnSelect,
  onScrollEnd,
  fixedOptions,
  disableClear,
  onBlur,
  clearCompanyForm,
  testId,
  error,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);
  const [inputValue, setInputValue] = useState(inputInitialValue);

  const getCurrentIndexForFixedOption = (index) => index + options.length;

  useEffect(() => {
    const selectedOption = [...options, ...fixedOptions].find((option) =>
      _isEqual(option.value, value),
    );
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
  }, [fixedOptions, inputInitialValue, options, value]);

  const handleSelectOption = (item) => {
    // added changes here because item.value is saving value we need to save item in case off assiciated persons.
    // Have to add these changes because of validations
    // todo: either remove saving functionality from here or remove withFormField encapsulation.
    if (item) {
      onSelect(item.value);
    }
    if (closeOnSelect) {
      closeMenu();
    }
  };

  const valuePassThreshold = (inputVal) => {
    if (menuShowThreshold > 0) {
      return inputVal && inputVal.length >= menuShowThreshold;
    }
    // if threshold is 0, open menu, despite value entered or not
    return true;
  };

  const openMenuPassThreshold = (inputVal) => {
    if (valuePassThreshold(inputVal)) {
      openMenu();
    } else {
      closeMenu();
    }
  };

  const clearInputValue = () => {
    setInputValue('');
    onChange('');
    onSelect(undefined);
    clearCompanyForm();
  };

  const handleInputChange = ({ target }) => {
    const inputVal = target.value;

    setInputValue(inputVal);
    onChange(inputVal);

    openMenuPassThreshold(inputVal);
  };

  const handleInputFocus = () => {
    openMenuPassThreshold(inputValue);
    if (onFocus) onFocus();
  };

  const inputProps = {
    name,
    onChange: handleInputChange,
    onFocus: handleInputFocus,
    'aria-labelledby': null,
  };

  // it prevents from closing menu on scroll
  const menuProps = {
    'aria-labelledby': null,
    onMouseDown: (e) => {
      e.preventDefault();
    },
  };

  const filteredOptions = useMemo(() => options.filter((o) => !o.hidden), [options]);
  const optionsLength = filteredOptions.length;
  const getDropdownHeight = () =>
    optionsLength > 4 ? DEFAULT_DROPDOWN_HEIGHT : optionMinHeight * optionsLength;

  const t = useTranslations();
  return (
    <Downshift
      onSelect={handleSelectOption}
      itemToString={(item) => item?.label}
      inputValue={inputValue}
      defaultHighlightedIndex={0}
    >
      {({ getInputProps, getRootProps, getItemProps, getMenuProps, highlightedIndex }) => (
        <StyledSelectWrapper {...getRootProps({ 'aria-labelledby': null })} onBlur={closeMenu}>
          <AutocompleteInput
            symbol={symbol}
            inputProps={getInputProps(inputProps)}
            placeholder={placeholder}
            onClear={clearInputValue}
            readOnly={!!value}
            loading={loading}
            onBlur={onBlur}
            onIconClick={openMenu}
            testId={testId}
            error={error}
          />

          <StyledSelectList
            isOpen={isMenuOpen}
            {...getMenuProps(menuProps)}
            data-testid="select-items"
          >
            {!optionsLength && (
              <NoEntriesInAutocomplete>{t('components.select.notFound')}</NoEntriesInAutocomplete>
            )}
            <VirtualList
              getItemProps={getItemProps}
              optionMinHeight={optionMinHeight}
              getDropdownHeight={getDropdownHeight}
              options={filteredOptions}
              highlightedIndex={highlightedIndex}
              onScrollEnd={onScrollEnd}
              fixedOptions={fixedOptions}
            />
            <StyledFixedElements>
              {fixedOptions.map((option, index) => (
                <Element
                  itemProps={getItemProps({
                    index: getCurrentIndexForFixedOption(index),
                    item: option,
                  })}
                  option={option}
                  key={option}
                  highlighted={highlightedIndex === getCurrentIndexForFixedOption(index)}
                  data-testId="option:company-not-found"
                />
              ))}
            </StyledFixedElements>
          </StyledSelectList>
        </StyledSelectWrapper>
      )}
    </Downshift>
  );
};

Autocomplete.defaultProps = {
  onSelect: () => {},
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  clearCompanyForm: () => {},
  placeholder: '',
  name: null,
  loading: false,
  symbol: <SearchIcon boxSize={6} />,
  menuShowThreshold: 3,
  value: null,
  inputInitialValue: '',
  optionMinHeight: 84,
  closeOnSelect: true,
  onScrollEnd: () => {},
  fixedOptions: [],
  disableClear: false,
  testId: '',
};

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(SelectOptionShape).isRequired,
  fixedOptions: PropTypes.arrayOf(SelectOptionShape),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  symbol: PropTypes.node,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onScrollEnd: PropTypes.func,
  menuShowThreshold: PropTypes.number,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  inputInitialValue: PropTypes.string,
  optionMinHeight: PropTypes.number,
  disableClear: PropTypes.bool,
  clearCompanyForm: PropTypes.func,
  testId: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Autocomplete;
