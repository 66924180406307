import React, { ReactNode } from 'react';

import { AriaDescription } from 'components/AriaDescription';
import {
  StyledTableHeaderCell as HeaderCell,
  StyledTableHeaderContent as HeaderContent,
} from 'components/PortalTable/styles';
import SortingArrows from 'components/PortalTable/TableHeaders/SortingArrows';
import { translations } from 'new/form/common/types';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IInquiryTableHeaders {
  children: ReactNode;
  columnId: string;
  onSortChange: (columnKey: string) => void;
  sortBy: string;
  sortDirection?: SORT_DIRECTION;
  headerColor?: string;
  notSortable?: boolean;
  ariaLabel?: string;
}

const InquiryTableHeader: React.FC<IInquiryTableHeaders> = ({
  children,
  columnId,
  onSortChange,
  sortBy,
  sortDirection,
  headerColor,
  notSortable,
  ariaLabel,
}: IInquiryTableHeaders) => {
  const t = useTranslations();
  const { ariaSortable } = translations.pages.inquiryList.table.headers;

  const fullAriaLabel = `${ariaLabel ?? (notSortable ? '' : children)}${
    notSortable ? '' : ', ' + t(ariaSortable)
  }`;

  const handleClick = () => {
    if (!notSortable) {
      onSortChange(columnId);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick();
    }
  };

  const ariaSort =
    sortBy === columnId
      ? sortDirection === SORT_DIRECTION.ASC
        ? 'ascending'
        : 'descending'
      : undefined;
  const headerAriaLabelId = `inquiry-table-header-${columnId}-aria-label`;

  return (
    <HeaderCell
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      scope="col"
      color={headerColor}
      notSortable={notSortable}
      tabIndex={0}
      aria-sort={ariaSort}
    >
      {fullAriaLabel ? (
        <AriaDescription id={headerAriaLabelId} aria-hidden>
          {fullAriaLabel}
        </AriaDescription>
      ) : null}
      <HeaderContent aria-labelledby={fullAriaLabel ? headerAriaLabelId : undefined}>
        {children}
        {sortDirection && !notSortable && (
          <SortingArrows
            isSorted={sortBy === columnId}
            sortedDesc={sortDirection === SORT_DIRECTION.DESC}
          />
        )}
      </HeaderContent>
    </HeaderCell>
  );
};

export default InquiryTableHeader;
