import { useSelector } from 'react-redux';

import EditInquiryLink from 'components/EditInquiryLink';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import FinancingOfferSection from 'modules/InquiryDetails/DetailedInformation/FinancingOfferSection/FinancingOfferSection';
import FinancingPurposeSection from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/FinancingPurposeSection';
import { DetailedInformationWrapper } from 'modules/InquiryDetails/DetailedInformation/styles';
import UserProfileSection from 'modules/InquiryDetails/DetailedInformation/UserProfileSection/UserProfileSection';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import useCanEdit from 'modules/InquiryEdit/useCanEdit';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

interface BfsServiceDetailedInquiryInformationProps {
  pathToEditInquiry: string;
}
export const BfsServiceDetailedInquiryInformation = ({
  pathToEditInquiry,
}: BfsServiceDetailedInquiryInformationProps) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const canEdit = useCanEdit();

  return (
    <article>
      <InquiryDetailsHeader>
        {canEdit && <EditInquiryLink to={pathToEditInquiry.replace(':id', inquiryId!)} />}
      </InquiryDetailsHeader>

      <DetailedInformationWrapper>
        <FinancingPurposeSection />
        <CompanyDetailsSection />
        <FinancingOfferSection />
        <UserProfileSection />
      </DetailedInformationWrapper>
    </article>
  );
};
