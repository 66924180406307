import { AnyAction, Dispatch } from 'redux';

enum ActionTypes {
  ARIA_LIVE_SHIFT_ANNOUNCEMENT = 'ARIA_LIVE_SHIFT_ANNOUNCEMENT',
  ARIA_LIVE_ADD_ANNOUNCEMENT = 'ARIA_LIVE_ADD_ANNOUNCEMENT',
}

export type IAccessibilityState = {
  ariaLive: {
    announcements: string[];
  };
};

const initialState = {
  ariaLive: {
    announcements: [],
  },
};

// Actions
export const ariaLiveShiftAnnouncement = () => (dispatch: Dispatch) =>
  dispatch({
    type: ActionTypes.ARIA_LIVE_SHIFT_ANNOUNCEMENT,
  });

export const ariaLiveAddAnnouncement = (announcement: string) => (dispatch: Dispatch) =>
  dispatch({
    type: ActionTypes.ARIA_LIVE_ADD_ANNOUNCEMENT,
    payload: announcement,
  });

// Reducer
export default (state: IAccessibilityState = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ARIA_LIVE_SHIFT_ANNOUNCEMENT: {
      return {
        ...state,
        ariaLive: {
          ...state.ariaLive,
          announcements: state.ariaLive.announcements.slice(1),
        },
      };
    }
    case ActionTypes.ARIA_LIVE_ADD_ANNOUNCEMENT: {
      return {
        ...state,
        ariaLive: {
          ...state.ariaLive,
          announcements: [...state.ariaLive.announcements, payload],
        },
      };
    }
    default:
      return state;
  }
};
