import React from 'react';

import { ListItem } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import Condition from 'components/Condition';
import FileRequestBlock from 'components/UploadBlock/NewFileRequestBlock/FileRequestBlock';
import MultipleFilesBlock from 'components/UploadBlock/NewFileRequestBlock/MultipleFilesBlock';
import type { ApiFile } from 'models/FileApi.model';
import { IFileRequest } from 'models/FileRequest.model';
import { translations } from 'new/form/common/types';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { uploadFileToFileRequestAction } from 'store/documentExchange/documentExchange.actions';
import { getFileRequestsSelector } from 'store/documentExchange/documentExchange.selectors';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { UploadRequestList } from './styles';
import FileSectionSubtitle from '../FileSectionSubtitle';
import FileSectionTitle from '../FileSectionTitle';

const {
  requiredFiles: requiredFilesTranslationKey,
  optionalFiles: optionalFilesTranslationkey,
  filesRequestedFromBank,
} = translations.pages.inquiryDetails.documentExchange;

const FileRequestsFromBank = () => {
  const fileRequests: IFileRequest[] = useSelector(getFileRequestsSelector);
  const dispatch = useDispatch();

  const handleFileUploaded = (requestId: string) => (file: ApiFile) => {
    dispatch(uploadFileToFileRequestAction(requestId, mapApiFile(file)));
  };

  const inquiryId = useSelector(getInquiryIdSelector);
  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });

  const t = useTranslations();

  const numOfFilledRequests = fileRequests.filter((req) => !!req.files?.length).length;
  const numOfRequests = fileRequests.length;

  const requiredFiles = fileRequests.filter((file) => file.required);

  const optionalFiles = fileRequests.filter((file) => !file.required);

  const isMultipleFilesPerUploadRequestEnabled = useConfig(
    CONFIG.ENABLE_MULTIPLE_FILES_PER_UPLOAD_REQUEST,
  );

  if (requiredFiles.length === 0 && optionalFiles.length === 0) {
    return null;
  }

  return (
    <section>
      <FileSectionTitle variant="quaternary">
        {t(filesRequestedFromBank)} ({numOfFilledRequests}/{numOfRequests})
      </FileSectionTitle>

      <FileSectionSubtitle>{t(requiredFilesTranslationKey)}</FileSectionSubtitle>
      <UploadRequestList>
        {requiredFiles.map((request) =>
          isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
            <ListItem key={request.id}>
              <MultipleFilesBlock fileRequestId={request.id} isFileUploadingEnabled {...request} />
            </ListItem>
          ) : (
            <ListItem key={request.id}>
              <FileRequestBlock
                fileRequest={request}
                url={uploadUrl}
                onUpload={handleFileUploaded(request.id)}
              />
            </ListItem>
          ),
        )}
      </UploadRequestList>

      <Condition condition={!!optionalFiles.length}>
        <FileSectionSubtitle>{t(optionalFilesTranslationkey)}</FileSectionSubtitle>
        <UploadRequestList>
          {optionalFiles.map((request) =>
            isMultipleFilesPerUploadRequestEnabled && request.files?.length ? (
              <ListItem key={request.id}>
                <MultipleFilesBlock
                  fileRequestId={request.id}
                  isFileUploadingEnabled
                  {...request}
                />
              </ListItem>
            ) : (
              <ListItem key={request.id}>
                <FileRequestBlock
                  fileRequest={request}
                  url={uploadUrl}
                  onUpload={handleFileUploaded(request.id)}
                />
              </ListItem>
            ),
          )}
        </UploadRequestList>
      </Condition>
    </section>
  );
};

export default FileRequestsFromBank;
