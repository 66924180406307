import { Signee } from 'models/types/Signee.type';

import { SignContractStep } from './SignContractStep';
import { DashboardActionProps } from '../NewDashboard';

export const NewSignContractStep = (props: DashboardActionProps) => {
  // Data is being inserted inside of another object to match the structure of Signee
  const extendedData = props.data?.map((attributes: Signee['attributes']) => ({
    attributes,
  }));
  const signContractStepProps = {
    ...props,
    data: extendedData,
  };
  return <SignContractStep {...signContractStepProps} />;
};
