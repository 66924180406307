import { SimpleGrid, Flex, Box } from '@chakra-ui/react';

type FinalPageActionProps = {
  asset?: React.ReactNode;
  children: React.ReactNode;
};

export const FinalPageAction = ({ asset, children }: FinalPageActionProps) => {
  return (
    <SimpleGrid templateColumns="20% 80%" gap={8} mb={16}>
      <Flex justifyContent="center">{asset}</Flex>
      <Box>{children}</Box>
    </SimpleGrid>
  );
};
