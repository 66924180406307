import styled from 'styled-components/macro';

import { Container } from 'components/PortalPage/styles';
import { InputCaption } from 'components/Text';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqMedium, mqLarge } from 'styles/breakpoints';
import { boxShadow, defaultFocusStyles, transition } from 'theme/themeConstants';

export const StyledCompanyPage = styled.main`
  padding-top: 6rem;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};

  ${mqMedium`
    padding: 9rem 0 6rem;
  `};
`;

export const StyledCompanyNav = styled.nav<{
  subNav?: boolean;
}>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};

  ${({ subNav, theme }) =>
    !subNav &&
    `border-bottom: 1px solid ${theme.colors.border.lightGrey};
  box-shadow: ${boxShadow.default}`};

  ${({ subNav }) => (subNav ? 'margin-bottom: 0.5rem;' : '')}

  ul {
    display: block;
    list-style: none;

    ${mqMedium`
      display: flex;
    `};

    li {
      display: block;

      ${mqMedium`
        display: flex;
        margin-right: 1.5rem;
      `};

      ${mqLarge`
        margin-right: 3rem;
      `};
    }
  }

  a {
    display: block;
    position: relative;
    padding: 1.5rem 0;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.tertiary};
    transition: color ${transition.default};

    ${mqMedium`
      max-width: 160px;
      overflow-wrap: break-word;
    `};

    ${mqLarge`
      max-width: none;
      overflow-wrap: normal;
    `};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px;
      background-color: transparent;
      transition: width ${transition.default}, background-color ${transition.default};
    }

    &.active {
      /* stylelint-disable-line selector-no-qualifying-type */
      color: ${({ theme }) => theme.colors.primary};

      &::after {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }

    &:focus {
      ${defaultFocusStyles}
    }
  }

  ${Container} {
    display: block;

    ${mqMedium`
      display: flex;
      justify-content: space-between;
    `};
  }
`;

export const StyledPageTitle = styled.h2`
  margin: 3rem 0 2rem;
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledOverviewFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  :not(:last-child) {
    margin-bottom: 2rem;
  }

  ${InputCaption} {
    text-transform: none;
    margin-bottom: 0;
  }
`;

export const Subtitle = styled.h5`
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const BrandSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const BreakLine = styled.hr`
  margin-bottom: 2rem;
`;
