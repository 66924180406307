import React from 'react';

import {
  StyledElementsPerPageOption,
  StyledElementsPerPageInput,
  StyledElementsPerPageLabel,
  StyledElementsPerPageList,
} from 'components/PortalTable/ElementsPerPage/StyledElementsPerPage';
import { HiddenLegend } from 'theme/components/accessibility/HiddenLegend';
import { useTranslations } from 'utils/hooks/useTranslations';

const PAGE_SIZE_OPTIONS = [5, 25, 50];

const ElementsPerPage = ({
  pageSize,
  onPageSizeChange,
}: {
  pageSize: number;
  onPageSizeChange: (newPage: number) => any;
}) => {
  const t = useTranslations();
  const handleChange = (newPageSize: number) => onPageSizeChange(newPageSize);

  return (
    <StyledElementsPerPageList>
      <HiddenLegend>{t('pages.inquiryList.table.pageSizeSelector.ariaDescription')}</HiddenLegend>
      {PAGE_SIZE_OPTIONS.map((numOfElements) => {
        const id = `page-size-option-${numOfElements}`;
        const active = +pageSize === +numOfElements;

        return (
          <StyledElementsPerPageOption key={numOfElements}>
            <StyledElementsPerPageInput
              type="radio"
              id={id}
              name="pageSize"
              value={numOfElements}
              checked={active}
              onChange={() => handleChange(numOfElements)}
            />
            <StyledElementsPerPageLabel htmlFor={id} active={active} role="button" data-testid={id}>
              {numOfElements}
            </StyledElementsPerPageLabel>
          </StyledElementsPerPageOption>
        );
      })}
    </StyledElementsPerPageList>
  );
};

export default ElementsPerPage;
