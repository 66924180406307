import React, { useEffect } from 'react';

import { useForm, useFormState } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import Separator from 'components/FormRow/Separator';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import { translations } from 'new/form/common/types';
import { LegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import LegalRepresentativeCards from './LegalRepresentativeCards';

export const LEGAL_REPRESENTATIVE_ERROR = `legalRepresentativesSection.legalRepresentatives.error`;

export const CbBankLegalRepresentativeCards = ({
  fields,
  FieldComponent,
  arrayFieldName,
}: {
  fields: FieldArrayRenderProps<any, any>['fields'];
  FieldComponent: React.ComponentType<{ mode: AssociatedPersonMode; prefix: string }>;
  arrayFieldName: string;
}) => {
  const { errors } = useFormState();
  const legalRepError = errors?.[LEGAL_REPRESENTATIVE_ERROR];
  const t = useTranslations();
  const { mutators } = useForm();

  useEffect(() => {
    if (fields.length === 0) {
      mutators.push(arrayFieldName);
    }
  }, [mutators, arrayFieldName, fields, fields.length]);

  if (!fields) {
    return null;
  }

  return (
    <>
      {fields.map((name, index) => {
        const legalRep: LegalRepresentative = fields.value[index];
        const fieldLength = fields.length;
        return (
          <React.Fragment key={name}>
            <LegalRepresentativeCards.Container>
              {index !== 0 ? (
                <LegalRepresentativeCards.DeleteButton
                  fields={fields}
                  index={index}
                  variant="link"
                  ariaLabel={t(
                    translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives
                      .fields.removeAriaLabel,
                    { index: index + 1 },
                  )}
                >
                  {t(
                    translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives
                      .fields.remove,
                  )}
                </LegalRepresentativeCards.DeleteButton>
              ) : null}
              {legalRep?.id ? (
                <FieldComponent mode={'edit'} prefix={name} />
              ) : (
                <FieldComponent mode={'add'} prefix={name} />
              )}
            </LegalRepresentativeCards.Container>
            {fieldLength ? index < fieldLength - 1 && <Separator /> : null}
          </React.Fragment>
        );
      })}
      <FieldError message={legalRepError} name={LEGAL_REPRESENTATIVE_ERROR} />
    </>
  );
};
