import * as React from 'react';

import {
  Box,
  BoxProps,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';

type Props = {
  heading?: string;
  text: string;
  trigger: React.ReactElement;
  popoverPlacement?: Placement;
} & Pick<BoxProps, 'mt' | 'mb' | 'ml' | 'transform' | 'display'>;

type RenderersProps = {
  node: any;
  href: string | undefined;
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
};

export const PopoverComponent = ({ heading, text, trigger, popoverPlacement, ...props }: Props) => {
  const styles = useMultiStyleConfig('Popover', {});
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const focusTrigger = () => {
    triggerRef.current?.focus();
  };
  const triggerWithRef = React.cloneElement(trigger, { ref: triggerRef });

  return (
    <Popover trigger="click" placement={popoverPlacement}>
      <PopoverTrigger>
        {/* There is a weird CSS behavior from the past which does not align the Popover with the caption, therefore
         we have to set mb={2} here
         */}
        <Box as="span" mb={2} {...props}>
          {triggerWithRef}
        </Box>
      </PopoverTrigger>
      <PopoverContent sx={styles.content} onBlur={focusTrigger}>
        {heading && <PopoverHeader sx={styles.header}>{heading}</PopoverHeader>}
        <PopoverArrow backgroundColor={'background.blackGrey'} />
        <PopoverBody sx={styles.body}>
          <MarkdownWrapper
            text={text}
            renderers={{
              link: ({ node, ...props }: RenderersProps) => (
                <a href={props.href}>{props.children}</a>
              ),
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
