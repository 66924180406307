import { Box, useMultiStyleConfig } from '@chakra-ui/react';

import { CircularProgress } from 'theme/components/CircularProgress';
import { useTranslations } from 'utils/hooks/useTranslations';

import Presentation from './components/Presentation';
import Progress from './components/Progress';

type VerticalProgressBarProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
};

export const VerticalProgressBar = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
}: VerticalProgressBarProps) => {
  const t = useTranslations('other');
  const styles = useMultiStyleConfig('VerticalProgressBar', {});

  return (
    <Box sx={styles.container}>
      <CircularProgress
        progressIncrement={progressIncrement!}
        progressTotal={progressTotal!}
        progressType={t('tasks')}
        isCompleted={isCompleted}
        completedValue={completedValue}
        componentStyles={styles.circularProgress}
      />

      {!isLast && (
        <Presentation isFirst={isFirst} isLast={isLast}>
          <Progress completedValue={completedValue} />
        </Presentation>
      )}

      {isLast && (
        <Presentation isFirst={isFirst} isLast={isLast}>
          <Progress completedValue={completedValue} />
        </Presentation>
      )}
    </Box>
  );
};
