import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import _get from 'lodash/get';
import { useForm, useFormState } from 'react-final-form';

import { hausbankFields, HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { HausbankPurposeKinds } from 'hausbank/inquiry/steps/financingNeed/sections/purposeSection/purposeKinds/purposeKinds';
import { HiddenInput } from 'theme/components/accessibility/HiddenInput';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const transition = 'all 0.3s ease-in-out';
const { purposeKind } = hausbankFields.financingNeedPage.purposeSection;

type Props = {
  purposeKindItem: HausbankPurposeKinds;
};

export const PurposeKindItem = ({ purposeKindItem }: Props) => {
  const IconComponent = purposeKindItem.icon;
  const t = useTranslations();
  const { change } = useForm();
  const { values } = useFormState<HausbankFieldTypes>();

  const id = `${purposeKind}-${purposeKindItem.id}`;
  const purposeKindValue = _get(values, purposeKind);
  const isActive = purposeKindValue === purposeKindItem.id;

  const handleChange = () => {
    change(purposeKind, purposeKindItem.id);
  };

  return (
    <Box>
      <HiddenInput
        id={id}
        type="radio"
        name={purposeKind}
        onChange={handleChange}
        value={purposeKindItem.id}
      />
      <Flex
        as="label"
        flexDirection="column"
        alignItems="center"
        gap={4}
        sx={{
          ...(isActive
            ? {}
            : {
                '&:hover': {
                  '.icon-container': { borderColor: 'brand.default' },
                  '.icon': { stroke: 'brand.default' },
                  '.text': { color: 'brand.default' },
                },
              }),
          cursor: 'pointer',
        }}
        htmlFor={id}
        data-testId={purposeKindItem.id}
        role="button"
      >
        <Box
          className="icon-container"
          border="2px"
          borderColor={isActive ? 'brand.default' : 'border.darkGrey'}
          backgroundColor={isActive ? 'brand.default' : 'initial'}
          p={8}
          transition={transition}
        >
          <IconComponent
            className="icon"
            stroke={isActive ? 'white' : 'border.darkGrey'}
            width={40}
            height={24}
            display="block"
            transition={transition}
          />
        </Box>
        <TextComponent
          className="text"
          color={isActive ? 'brand.default' : 'text.tertiary'}
          transition={transition}
        >
          {t(purposeKindItem.translationString)}
        </TextComponent>
      </Flex>
    </Box>
  );
};
