import React, { forwardRef } from 'react';

import { bool, func, string } from 'prop-types';

import { SelectContext } from 'components/Selects/shared';
import { getInputDescribedByIds } from 'utils/accessibility/getInputDescribedByIds';

import StyledInput from './StyledInput';

const TextInput = forwardRef(
  (
    {
      sideSymbol,
      name,
      preventEnterSubmit,
      autoComplete,
      label,
      errorMessage,
      sideSymbolIsButton,
      ...restProps
    },
    ref,
  ) => (
    <StyledInput
      data-testid={name}
      sideSymbol={sideSymbol}
      type="text"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && preventEnterSubmit) e.preventDefault();
      }}
      {...restProps}
      data-name={name}
      autoComplete={autoComplete}
      ref={ref}
      name={name}
      id={`input-${name}`}
      aria-describedby={getInputDescribedByIds(name, errorMessage, !sideSymbolIsButton)}
      aria-labelledby={`${name}-caption`}
    />
  ),
);

TextInput.propTypes = {
  label: string,
  sideSymbol: func,
  name: string,
  autoComplete: string,
  preventEnterSubmit: bool,
  sideSymbolIsButton: bool,
};

TextInput.defaultProps = {
  label: null,
  sideSymbol: () => null,
  name: null,
  autoComplete: 'off',
  preventEnterSubmit: true,
  sideSymbolIsButton: false,
};

export default (props) => (
  <SelectContext.Consumer>{(ref) => <TextInput {...props} ref={ref} />}</SelectContext.Consumer>
);
