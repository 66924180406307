import { rgba } from 'polished';
import styled from 'styled-components/macro';

import { ReactComponent as FileIcon } from 'assets/specific-icons/file.svg';
import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import { FONT_WEIGHT_REGULAR, FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { mqMedium } from 'styles/breakpoints';
import CheckmarkLargeIcon from 'theme/components/Icon/CheckmarkLargeIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { borderRadius, boxShadow, transition, zIndex } from 'theme/themeConstants';

export const UploadedFileDate = styled.span`
  display: inline-block;
  position: relative;
  margin: 0 1rem 0 0;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -0.75rem;
    width: 3px;
    height: 3px;
    border-radius: ${borderRadius.circle};
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.background.blackGrey};
  }
`;

export const StyledUploadedFileName = styled.div`
  transform: translateY(0);
  font-style: italic;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.colors.text.tertiary};
  transition: opacity ${transition.default}, transform ${transition.default};
`;

export const StyledErrorIcon = styled(CloseIcon).attrs({
  boxSize: '16',
  color: 'icon.white',
})`
  opacity: 0;
  position: absolute;
  z-index: ${zIndex.default};
  top: 50%;
  left: 42.5%;
  transform: translateY(3rem);
  transition: opacity ${transition.default}, transform ${transition.default};

  ${mqMedium`
    left: 0;
    margin-top: -50%;
  `};
`;

export const StyledUploadedIcon = styled(CheckmarkLargeIcon).attrs({
  boxSize: '16',
  color: 'icon.white',
})`
  opacity: 0;
  position: absolute;
  z-index: ${zIndex.default};
  top: 0;
  left: 42.5%;
  transform: translateY(3rem);
  transition: opacity ${transition.default}, transform ${transition.default};

  ${mqMedium`
    left: 0;
  `};
`;

export const StyledErrorInfo = styled.div`
  opacity: 0;
  position: absolute;
  top: 3rem;
  left: 0;
  transform: translateY(-3rem);
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${(props) => props.theme.colors.text.white};
  transition: opacity ${transition.default}, transform ${transition.default};

  ${mqMedium`
    left: auto;
  `};
`;

export const StyledUploadedInfo = styled.div`
  opacity: 0;
  position: absolute;
  top: 3rem;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(3rem);
  font-size: 2rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${(props) => props.theme.colors.text.white};
  transition: opacity ${transition.default}, transform ${transition.default};
`;

export const StyledIconWrapper = styled.div`
  position: relative;
  height: 5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};

  ${mqMedium`
    flex: 1 1 0%;
    max-width: 4rem;
    margin-bottom: 0;
  `};
`;

export const StyledUploadingIcon = styled(UploadIcon).attrs({
  boxSize: '16',
})`
  opacity: 0;
  position: absolute;
  z-index: ${zIndex.default};
  top: 0;
  left: 42.5%;
  transform: translateY(-3rem);
  transition: opacity ${transition.default}, transform ${transition.default};

  ${mqMedium`
    left: 0;
  `};
`;

export const StyledScanningInfo = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  margin: 0 1rem 0.25rem;
  transform: translateY(1rem);
  font-size: 1rem;
  font-style: italic;
  transition: opacity ${transition.default}, transform ${transition.default};

  ${StyledSpinnerWrapper} {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem 0 0;
  }
`;

export const StyledProgressPercent = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 1rem;
  bottom: 3rem;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledProgressBackground = styled.div<{ progress: number }>`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ progress }) => progress}%;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.3)};
  transition: opacity ${transition.default}, width ${transition.default};

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 2.75rem;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledRequestContent = styled.div`
  overflow: hidden;
  flex: 2.5 1 0%;
  position: relative;
  z-index: ${zIndex.default};
  top: 0;
  padding: 0 2rem;
  text-align: center;
  transition: top ${transition.default};

  ${mqMedium`
    padding: 0 4rem 0 2rem;
    text-align: left;
  `}
`;

export const StyledRequestDescription = styled.p`
  position: relative;
  margin-top: 1rem;
  transform: translateY(-1rem);
  line-height: 1.25;
  transition: opacity ${transition.default}, transform ${transition.default};
`;

export const UnuploadedIcon = styled(FileIcon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(0);
  transition: opacity ${transition.default}, transform ${transition.default};
`;

export const StyledRequestActions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  transform: translateY(0);
  transition: opacity ${transition.default}, transform ${transition.default};

  ${mqMedium`
    max-width: 400px;
  `};

  button:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

export const StyledDroppingText = styled.div`
  opacity: 0;
  position: relative;
  transform: translateY(-2rem);
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  transition: opacity ${transition.default}, transform ${transition.default};

  &::before {
    content: '';
    position: absolute;
    top: 1.7rem;
    left: 0;
    width: 0;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: width ${transition.default}, left ${transition.default},
      transform ${transition.default};
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    padding-top: 0.5rem;
    transition: height ${transition.default};
  }
`;

export const StyledRequestTitleWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 0.5rem;

  ${mqMedium`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `}
`;

export const StyledRequestTitle = styled.h3`
  max-width: 100%;
  font-size: 1.25rem;
  font-weight: ${FONT_WEIGHT_BOLD};
  line-height: 1.25;
  overflow-wrap: break-word;
  transition: opacity ${transition.default};

  ${mqMedium`
    :not(:last-child) {
      margin-right: 0.75rem;
    }
  `}
`;

export const StyledRequestLabel = styled.span`
  display: block;
  margin: 0.5rem 0;
  border: 1px solid ${(props) => props.theme.colors.status.info};
  border-radius: ${borderRadius.default};
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  color: ${(props) => props.theme.colors.text.secondary};
  background-color: ${(props) => props.theme.colors.status.info_L93};
  transition: opacity ${transition.default};

  ${mqMedium`
    display: inline-block;
  `}

  &.uploaded {
    border-color: ${(props) => props.theme.colors.status.success};
    background-color: ${(props) => props.theme.colors.status.success_L93};
  }
`;

export const RequestBlockOptionalLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 ${borderRadius.default};
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const StyledRequestBlockWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${borderRadius.large};
  padding: 2rem;
  box-shadow: ${boxShadow.default};
  background-color: ${(props) => props.theme.colors.background.white};

  ${mqMedium`
    display: flex;
    align-items: center;
    width: 100%;
  `}

  /* background for error state */
  &::after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius.large};
    transform: translateY(-100%);
    background-color: ${({ theme }) => theme.colors.status.error};
    transition: opacity ${transition.default}, transform ${transition.default};
  }

  /* background for finished state */
  &::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius.large};
    transform: translateY(100%);
    background-color: ${({ theme }) => theme.colors.status.success};
    transition: opacity ${transition.default}, transform ${transition.default};
  }

  &.${UPLOADING_STATE.IDLE} {
    &::before {
      transform: translateY(-100%);
    }

    ${StyledProgressBackground} {
      width: 0;
    }

    ${StyledUploadedIcon} {
      opacity: 0;
      transform: translateY(-3rem);
    }

    ${StyledUploadedInfo} {
      opacity: 0;
      transform: translateY(-2rem);
    }
  }

  &.dropping {
    ${StyledUploadedFileName} {
      opacity: 0;
      transform: translateY(2rem);
    }

    ${StyledRequestDescription} {
      opacity: 0;
      transform: translateY(2rem);
    }

    ${UnuploadedIcon} {
      opacity: 0;
      transform: translateY(10rem);
    }

    ${StyledRequestActions} {
      opacity: 0;
      transform: translateY(5rem);
    }

    ${StyledDroppingText} {
      opacity: 1;
      transform: translateY(0);

      &::before {
        width: 3rem;
      }

      > * {
        height: 2rem;
      }
    }

    ${StyledRequestTitle} {
      opacity: 0.3;
    }

    ${StyledRequestLabel} {
      opacity: 0;
    }
  }

  /* stylelint-disable-next-line selector-list-comma-newline-after */
  &.${UPLOADING_STATE.SCANNING}, &.${UPLOADING_STATE.UPLOADING} {
    ${StyledUploadingIcon} {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledDroppingText} {
      opacity: 0;
      transform: translateX(2rem);

      &::before {
        width: 0;
        left: 3rem;
        transform: translateX(-2rem);
      }
    }

    ${StyledRequestLabel} {
      opacity: 0;
    }

    ${StyledRequestDescription} {
      opacity: 0;
      transform: translateY(2rem);
    }

    ${UnuploadedIcon} {
      opacity: 0;
      transform: translateY(10rem);
    }

    ${StyledRequestActions} {
      opacity: 0;
      transform: translateY(5rem);
    }

    ${StyledProgressBackground} {
      opacity: 1;
    }
  }

  &.${UPLOADING_STATE.SCANNING} {
    ${StyledScanningInfo} {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.${UPLOADING_STATE.FINISHED} {
    /* green bg */
    &::before {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledUploadedIcon} {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledUploadedInfo} {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledRequestDescription} {
      opacity: 0;
    }

    ${UnuploadedIcon} {
      opacity: 0;
    }

    ${StyledRequestLabel} {
      opacity: 0;
    }

    ${StyledRequestActions} {
      opacity: 0;
      flex: 0;
      width: 0;
    }

    ${StyledUploadedFileName} {
      opacity: 0;
      transform: translateY(-1rem);
    }

    ${StyledRequestTitle} {
      color: ${(props) => props.theme.colors.text.white};
    }

    ${StyledRequestContent} {
      top: 1rem;
    }
  }

  &.${UPLOADING_STATE.ERROR} {
    /* green bg */
    &::after {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledErrorIcon} {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledErrorInfo} {
      opacity: 1;
      transform: translateY(0);
    }

    ${StyledRequestDescription} {
      opacity: 0;
    }

    ${UnuploadedIcon} {
      opacity: 0;
    }

    ${StyledRequestLabel} {
      opacity: 0;
    }

    ${StyledRequestActions} {
      opacity: 0;
      flex: 0;
      width: 0;
    }

    ${StyledUploadedFileName} {
      opacity: 0;
      transform: translateY(-1rem);
    }

    ${StyledRequestTitle} {
      opacity: 0.5;
      color: ${(props) => props.theme.colors.text.white};
    }

    ${StyledRequestContent} {
      top: 1rem;
    }
  }
`;
