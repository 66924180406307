import { chakra } from '@chakra-ui/react';

import { inputBorder, inputPadding } from 'styles/form';
import { chakraDefaultFocusStyles } from 'theme/themeConstants';

export const InputWrapper = chakra('div', {
  baseStyle: {
    w: 'full',
    borderWidth: inputBorder,
    borderStyle: 'solid',
    borderColor: 'border.lightGrey',
    color: 'text.primary',
    borderRadius: 'base',
    boxSizing: 'border-box',
    // precalculated result of src/styles/form.js#fixInputWidthAndHeight
    h: '4.25rem',
    '&:focus-within': {
      borderColor: 'brand.default',
      '.PhoneInputCountrySelectArrow': {
        borderColor: 'brand.default',
      },
    },
    '.PhoneInput': {
      display: 'flex',
      gridGap: 'md',
      h: 'full',
      bg: 'white',
    },
    '.PhoneInputInput': {
      boxSizing: 'border-box',
      flexGrow: 1,
      p: inputPadding,
      border: 'none',
      h: '100%',
      '&:focus': {
        outline: 0,
      },
    },
    '.PhoneInputCountry': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ps: '1rem',
      pos: 'relative',
      gridGap: '0.5rem',

      '&:has(select:focus)': {
        ...chakraDefaultFocusStyles,
      },
    },
    '.PhoneInputCountrySelect': {
      opacity: 0,
      inset: 0,
      pos: 'absolute',
      cursor: 'pointer',
      maxWidth: '100%',
      borderWidth: '1px',
      padding: 0,
    },
    '.PhoneInputCountryIcon': {
      w: '2rem',
    },
    '.PhoneInputCountryIconImg': {
      w: 'full',
      h: 'auto',
      display: 'block',
    },
    '.PhoneInputCountrySelectArrow': {
      display: 'block',
      content: '""',
      width: '0.5rem',
      height: '0.5rem',
      borderStyle: 'solid',
      borderColor: 'black',
      borderTopWidth: 0,
      borderBottomWidth: '1px',
      borderLeftWidth: 0,
      borderRightWidth: '1px',
      transform: 'rotate(45deg)',
      mx: '3px',
    },
  },
});
