import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { mqMedium } from 'styles/breakpoints';
import { defaultFocusStyles, transition } from 'theme/themeConstants';

export const NavBar = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.lightGrey};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${mqMedium`
    flex-direction: row;
  `}
`;

export const MainNavigation = styled.ul`
  ${mqMedium`
    display: flex;
    flex: 1 1 0%;
  `}
`;

export const AdditionalNavigation = styled.ul`
  display: flex;
`;

export const StyledNavElement = styled.li`
  width: 100%;
  list-style: none;
  padding: 1rem 0;

  ${mqMedium`
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;

    &:not(:last-child) {
      margin-right: 4rem;
    }
  `}
`;

export const NavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  font-size: 1.25rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.tertiary};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 2px;
    transform: translateY(-2px);
    background-color: ${({ theme }) => theme.colors.primary};
    transition: left ${transition.default}, width ${transition.default};
  }

  &.active {
    &::after {
      left: 0;
      width: 100%;
    }

    &:hover {
      &::after {
        left: 50%;
        width: 0;
      }
    }
  }

  &:hover {
    &::after {
      left: 0;
      width: 100%;
    }
  }

  &:focus {
    ${defaultFocusStyles}
  }
`;
