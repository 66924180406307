import { GridItem } from '@chakra-ui/react';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import ButtonComponent from 'theme/components/Button';
import { CircularProgress } from 'theme/components/CircularProgress';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SummaryCardItem } from './SummaryCardItem';

import { SummaryCardProps } from './index';

export const ProgressItem = ({
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  openTaskId,
  canShowProgress,
}: SummaryCardProps) => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();

  const isBfsService = selectedInquiryType === InquiryType.bfsService;

  return (
    <GridItem>
      {canShowProgress && (
        <SummaryCardItem
          display="flex"
          alignItems={['flex-start', null, null, 'center']}
          flexDirection="column"
          justifyContent="flex-end"
        >
          <CircularProgress
            progressIncrement={progressIncrement!}
            progressTotal={progressTotal!}
            progressType={t('other.tasks')}
            isCompleted={isCompleted}
            completedValue={isCompleted ? 100 : completedValue}
            size="large"
            mb={4}
            isBfsService={isBfsService}
          />

          {!isCompleted && (
            <ButtonComponent
              as="a"
              href={`#${openTaskId}`}
              leftIcon={<ArrowRightIcon boxSize={6} d="block" />}
              variant="tertiary"
            >
              {t('other.openTasks')}
            </ButtonComponent>
          )}
        </SummaryCardItem>
      )}
    </GridItem>
  );
};
