import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { transition, zIndex } from 'theme/themeConstants';

const helpers = createMultiStyleConfigHelpers(['wrapper']);

const ProgressBar = helpers.defineMultiStyleConfig({
  baseStyle: ({ contentIsScrolledToTop }) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed',
      zIndex: zIndex.horizontalProgressBar,
      top: 0,
      width: '100%',
      transform: `translateY(${contentIsScrolledToTop ? 9 : 6}rem)`,
      transition: `transform ${transition.default}`,
    },
  }),
});

export default ProgressBar;
