import React from 'react';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-final-form';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import ControlWrapper from 'components/ControlWrapper';
import InputContainer from 'components/Input/InputWrapper/InputContainer';
import SymbolWrapper from 'components/SideSymbol/SymbolWrapper';
import CalendarIcon from 'theme/components/Icon/CalendarIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { buildElementIdString } from 'utils/accessibility/buildElementIdString';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { convertLocalToUTCDate } from 'utils/helpers';
import { useTranslations } from 'utils/hooks/useTranslations';
import { datepickerFormat, monthFormat, yearFormat } from 'utils/valueFormats';

import { ClearButton, ClearButtonWrapper } from './ClearButton';
import DatePickerWrapper from './DatePickerWrapper';
import locales from './locales';

const sideSymbol = () => <CalendarIcon boxSize={6} />;

interface DatePickerProps {
  selected?: Date;
  caption: string;
  name: string;
  placeholder: string;
  errorMessage: string;
  value: string;
  onChange: (date: Date | undefined) => void;
  optional: boolean;
  tooltip?: string;
  isClearable: boolean;
  minDate?: Date;
  maxDate?: Date;
  includeDates?: any;
  filterDate?: any;
  testId?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const DatePickerBase = ({
  caption,
  selected,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  testId,
  onBlur,
  ...rest
}: DatePickerProps & Omit<ReactDatePickerProps, 'onChange'>) => {
  const t = useTranslations();
  const { change } = useForm();

  const { selectedLanguage, availableLanguages } = useLanguages();

  availableLanguages.forEach((language) => {
    registerLocale(language, locales[language]);
  });

  setDefaultLocale(selectedLanguage);

  const handleChange = (date: Date) => {
    const utcDate = convertLocalToUTCDate(date);
    onChange(utcDate);
    /**
     * We need to send the date in ISO format to the backend. It worked before since the FormFieldController stored the
     * values in SessionStorage and the date object was converted to an ISO string automatically. Now that we are only using
     * ReactFinalForm, we need to convert the date to an ISO string manually.
     */
    change(name, utcDate.toISOString());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Backspace') {
      onChange(undefined);
    }
  };

  const placeholderText: string = placeholder ? placeholder : t('placeholders.pleaseChoose');

  /**
   * There was some legacy code so that we had to parse the value in a difficult way (convertUTCToLocalDate) which can be
   * replaced by the new Date() constructor. Since we have to change the selected date to a UTC string to the backend
   * this is the way to go forward
   */
  return (
    <ControlWrapper
      caption={caption}
      optional={optional}
      errorMessage={errorMessage}
      tooltip={tooltip}
      selected={selected}
      name={name}
    >
      <InputContainer>
        <DatePickerWrapper>
          <DatePickerComponent
            selected={value ? new Date(value) : selected}
            placeholderText={placeholderText}
            onChange={handleChange}
            locale={selectedLanguage}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            startDate={minDate}
            endDate={maxDate}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<input data-testid={name} type="text" />}
            onBlur={onBlur}
            name={name}
            ariaLabelledBy={buildElementIdString(name, 'caption')}
            {...rest}
          >
            {isClearable && value && (
              <ClearButtonWrapper>
                <ClearButton onClick={() => onChange(undefined)}>
                  <DeleteIcon boxSize={6} mr={1} />
                  {t('pages.inquiryList.filtersDrawer.buttons.clear')}
                </ClearButton>
              </ClearButtonWrapper>
            )}
          </DatePickerComponent>
        </DatePickerWrapper>
        <SymbolWrapper>{sideSymbol()}</SymbolWrapper>
      </InputContainer>
    </ControlWrapper>
  );
};

export const DayPickerUnwrapped = ({
  selected,
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  includeDates,
  filterDate,
  onBlur,
}: DatePickerProps) => (
  <DatePickerBase
    selected={selected}
    caption={caption}
    name={name}
    placeholder={placeholder}
    errorMessage={errorMessage}
    value={value}
    onChange={onChange}
    optional={optional}
    tooltip={tooltip}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={datepickerFormat}
    includeDates={includeDates}
    filterDate={filterDate}
    onBlur={onBlur}
  />
);

export const MonthPickerUnwrapped = ({
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  onBlur,
}: DatePickerProps) => (
  <DatePickerBase
    onChange={onChange}
    caption={caption}
    placeholder={placeholder}
    errorMessage={errorMessage}
    optional={optional}
    tooltip={tooltip}
    name={name}
    value={value}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={monthFormat}
    onBlur={onBlur}
    showMonthYearPicker
    showFullMonthYearPicker
  />
);

export const YearPickerUnwrapped = ({
  caption,
  name,
  placeholder,
  errorMessage,
  value,
  onChange,
  optional,
  tooltip,
  isClearable,
  minDate,
  maxDate,
  onBlur,
}: DatePickerProps) => (
  <DatePickerBase
    onChange={onChange}
    caption={caption}
    placeholder={placeholder}
    errorMessage={errorMessage}
    optional={optional}
    tooltip={tooltip}
    name={name}
    value={value}
    isClearable={isClearable}
    minDate={minDate}
    maxDate={maxDate}
    dateFormat={yearFormat}
    showYearPicker
    yearItemNumber={8}
    onBlur={onBlur}
  />
);

const DayPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <DayPickerUnwrapped {...input} {...meta} {...rest} />
);

const MonthPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <MonthPickerUnwrapped {...input} {...meta} {...rest} />
);

const YearPickerAdapter = ({ input, meta, ...rest }: { input: any; meta: any }) => (
  <YearPickerUnwrapped {...input} {...meta} {...rest} />
);

DayPickerAdapter.propTypes = FinalFormFieldPropTypes;
MonthPickerAdapter.propTypes = FinalFormFieldPropTypes;
YearPickerAdapter.propTypes = FinalFormFieldPropTypes;

export const DayPickerWithField = withFormField(DayPickerAdapter);
export const MonthPickerWithField = withFormField(MonthPickerAdapter);
export const YearPickerWithField = withFormField(YearPickerAdapter);
