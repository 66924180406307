import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { StyledNavLink } from 'modules/Header/NavigationLink/styles';
import { showRwdMenu } from 'store/layout/actions';
import { useNavigationAriaCurrent } from 'utils/hooks/useNavigationAriaCurrent';

const NavigationLink = ({ icon, url, label, 'aria-labelledby': ariaLabelledBy }) => {
  const dispatch = useDispatch();
  const ariaCurrent = useNavigationAriaCurrent(url);

  return (
    <StyledNavLink>
      <NavLink
        activeClassName="active"
        to={url}
        onClick={() => dispatch(showRwdMenu(false))}
        aria-current={ariaCurrent}
        aria-labelledby={ariaLabelledBy}
      >
        {icon}
        {label}
      </NavLink>
    </StyledNavLink>
  );
};

NavigationLink.defaultProps = {
  icon: null,
  'aria-labelledby': undefined,
};

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.node,
  'aria-labelledby': PropTypes.string,
};

export default NavigationLink;
