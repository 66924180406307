import { HeadingProps, Heading, SystemStyleObject } from '@chakra-ui/react';

export type HeadingVariantType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'hidden';

type Props = {
  variant?: HeadingVariantType;
  children: React.ReactNode;
} & HeadingProps;

export const HeadingComponent = ({ variant = 'primary', children, ...props }: Props) => {
  const headingType: Record<HeadingVariantType, React.ElementType<any>> = {
    primary: 'h1',
    secondary: 'h2',
    tertiary: 'h3',
    quaternary: 'h4',
    quinary: 'h5',
    hidden: 'div',
  };

  const headingStyles: Partial<Record<HeadingVariantType, SystemStyleObject>> = {
    quaternary: {
      fontSize: '20px',
      lineHeight: '1.5',
    },
    quinary: {
      fontSize: '20px',
      lineHeight: '1.5',
    },
  };

  return (
    <Heading
      variant={variant}
      as={headingType[variant as HeadingVariantType]}
      sx={headingStyles[variant as HeadingVariantType] ?? {}}
      {...props}
    >
      {children}
    </Heading>
  );
};
