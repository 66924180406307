import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

const CaretUpFilledIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <polygon points="6,15 18,15 12,9" />
    </Icon>
  );
};

export default CaretUpFilledIcon;
