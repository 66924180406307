import React, { ReactNode } from 'react';

import { useMultiStyleConfig, chakra, Heading } from '@chakra-ui/react';

import PageTitle from 'components/PageTitle/PageTitle';

import { ContentWrapper, Inner } from './styles';

interface PortalPageProps {
  children: ReactNode;
  /**
   * Page title (in the browser tab) and as a heading for page
   */
  pageTitle?: string;
  /**
   * Page title (in the browser tab) only
   */
  title?: string;
  /**
   * Custom heading to put on top of `pageTitle` (if exists)
   */
  heading?: ReactNode;
  biggerHeading?: boolean;
  widerContainer?: boolean;
  stylesThemeKey?: string;
}

const PortalPage = ({
  children,
  title,
  stylesThemeKey = 'PortalPage',
  pageTitle,
  heading,
  biggerHeading,
  widerContainer,
}: PortalPageProps) => {
  const styles = useMultiStyleConfig(stylesThemeKey, {
    hasTallerHeading: biggerHeading,
    hasWiderContainer: widerContainer,
  });
  return (
    <chakra.main __css={styles.wrapper}>
      <PageTitle title={title ?? pageTitle} />
      <chakra.div __css={styles.container}>
        {heading}
        {pageTitle && (
          <Heading as="h1" sx={styles.heading}>
            {pageTitle}
          </Heading>
        )}
      </chakra.div>
      <Inner>
        <ContentWrapper>
          <chakra.div __css={styles.container}>{children}</chakra.div>
        </ContentWrapper>
      </Inner>
    </chakra.main>
  );
};

export default PortalPage;
