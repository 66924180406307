import { Flex, Heading, Text } from '@chakra-ui/react';

import { chooseConfigSpecificComponent } from 'shared/chooseConfigSpecific';
import { useToasts } from 'shared/hooks/useToasts';
import { ButtonComponent } from 'theme/components/Button';

import { requestPasswordResetAction } from '../../pages/RequestPasswordReset/service';
import CONFIG from '../../shared/featureFlagConfig/configFromAdmin';
import useDispatchApiCall from '../../utils/hooks/useDispatchApiCallHook';
import { useTranslations } from '../../utils/hooks/useTranslations';

type ResetPasswordSectionProps = {
  email: string;
};

const ResetPasswordSection = ({ email }: ResetPasswordSectionProps) => {
  const t = useTranslations('pages.userData.changePassword');
  const { isPending, makeCall } = useDispatchApiCall();
  const { success } = useToasts();

  return (
    <Flex as="section" marginTop={8} direction="column" alignItems="flex-start">
      <Heading as="h3" marginBottom={7} variant="secondary">
        {t('heading')}
      </Heading>
      <Text marginBottom={6} fontSize="md" fontWeight="light" color="text.tertiary">
        {t('subheading')}
      </Text>
      <ButtonComponent
        disabled={isPending}
        isLoading={isPending}
        onClick={() =>
          makeCall(requestPasswordResetAction(email), () => {
            success({ description: t('successNotification') });
          })
        }
      >
        {t('label')}
      </ButtonComponent>
    </Flex>
  );
};

export default chooseConfigSpecificComponent({
  [CONFIG.IS_SSO_ENABLED]: () => null,
  default: ResetPasswordSection,
});
