import React from 'react';

import { useRadioGroup } from '@chakra-ui/react';

import { StyledFieldset } from './styles';
import { HiddenLegend } from '../accessibility/HiddenLegend';
import RadioButtonComponent from '../RadioButton';

type props = {
  name: string;
  defaultValue?: string;
  options: { caption?: any; value: string | number | undefined; isDisabled?: boolean }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentValue: string;
  radioWidth: string;
  groupCaption?: string | React.ReactNode | null;
};

const RadioGroupComponent = ({
  name,
  defaultValue,
  options,
  currentValue,
  groupCaption,
  ...restProps
}: props) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: defaultValue,
  });

  const group = getRootProps();

  const check = options.find((element) => element.value === defaultValue);
  if (check === undefined && defaultValue) {
    throw new Error('RadioGroup: The default value must be included in the Options Array');
  }

  return (
    <StyledFieldset {...group}>
      {groupCaption ? <HiddenLegend>{groupCaption}</HiddenLegend> : null}
      {options.map(({ value, caption, isDisabled }) => {
        const radio = getRadioProps({ value });
        const id = `${name}-${value}`;
        return (
          <RadioButtonComponent
            {...radio}
            {...restProps}
            id={id}
            isChecked={value === currentValue}
            // FIXME
            // @ts-ignore
            value={value}
            key={value}
            isDisabled={isDisabled}
            name={name}
          >
            {caption}
          </RadioButtonComponent>
        );
      })}
    </StyledFieldset>
  );
};

export default RadioGroupComponent;
