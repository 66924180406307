import React, { Ref } from 'react';

import { Box, Flex, HStack, useMultiStyleConfig, useRadio, Text } from '@chakra-ui/react';

type props = {
  isChecked?: boolean;
  name?: string;
  value: string | undefined;
  ref?: Ref<HTMLInputElement> | null;
  children: React.ReactNode;
  key: string | number | undefined;
  id?: string;
  radioWidth: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

const RadioButtonComponent = ({
  id,
  isChecked,
  value,
  children,
  radioWidth,
  name,
  ...restProps
}: props) => {
  const { getInputProps, getCheckboxProps } = useRadio({
    id,
    isChecked,
    value,
    ...restProps,
  });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const ariaProps = {
    disabled: input['aria-disabled'],
  };

  const changeValue = (e: any) => {
    restProps.onChange(e);
    if (input.onChange) {
      input.onChange(e);
    }
  };

  const styles = useMultiStyleConfig('RadioButton', {
    variant: isChecked ? 'checked' : 'notChecked',
  });
  return (
    <Box>
      <Box
        as="input"
        {...input}
        onChange={changeValue}
        value={value}
        name={name}
        sx={styles.RadioButtonInput}
      />
      <Box
        as="label"
        sx={styles.RadioButtonContainer}
        width={radioWidth}
        data-testid={id}
        htmlFor={input.id}
        {...ariaProps}
      >
        <HStack spacing={0}>
          <Flex sx={styles.RadioButtonOuter} {...ariaProps}>
            <Box {...checkbox} sx={styles.RadioButtonInner} className="checkbox-inner" />
          </Flex>
          <Text sx={styles.RadioButtonLabel} {...ariaProps}>
            {children}
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

export default RadioButtonComponent;
