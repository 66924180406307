import { css } from 'styled-components/macro';

import { borderRadius, transition } from 'theme/themeConstants';

// FIXME: change these to appropiate styling system that we currently use
export const inputBorder = '2px';
export const inputHeight = '1.5rem';
export const inputPadding = '1.25rem';
export const inputPaddingRightWithSideSymbol = '5rem';

export const inputStyles = () => css`
  width: 100%;
  height: ${inputHeight};
  border: ${inputBorder} solid ${(props) => props.theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  padding: ${inputPadding};
  color: ${(props) => props.theme.colors.text.primary};
  transition: border ${transition.default}, background-color ${transition.default};

  :disabled {
    background-color: ${(props) => props.theme.colors.background.darkGrey};
  }

  :focus {
    outline: 0;
    border: ${inputBorder} solid ${(props) => props.theme.colors.primary};
  }
`;

export const setInputHeight = (min = false) => css`
  ${min ? 'min-height' : 'height'}: calc(2 * ${inputPadding} + ${inputHeight} + 2 * ${inputBorder});
`;

export const setTopBasedOnInputHeight = () => css`
  top: calc(2 * ${inputPadding} + ${inputHeight} + 2 * ${inputBorder});
`;

export const chakraInputHeight = {
  height: `calc(2 * ${inputPadding} + ${inputHeight} + 2 * ${inputBorder})`,
};

export const fixInputWidthAndHeight = () => css`
  ${setInputHeight()};
`;

export const inputPaddingRight = () => css`
  padding-right: ${(props) => (props.sideSymbol ? inputPaddingRightWithSideSymbol : inputPadding)};
`;
