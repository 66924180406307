import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { usePreviewFile } from 'components/DownloadBlock/usePreviewFile';
import { FileType } from 'models/File.model';
import { ariaLiveAddAnnouncement } from 'store/accessibility/reducer';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import ViewIcon from 'theme/components/Icon/ViewIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { useTranslations } from 'utils/hooks/useTranslations';

export interface LocalFile {
  id: string;
  file: File;
}

interface Props {
  onRemove: (id: string) => void;
  //A LocalFile object is passed if we want to download/preview a file stored in the application state
  selectedFile: { id: string; fileName: string; type?: FileType } | LocalFile;
}

const isLocalFile = (obj: any): obj is LocalFile => 'file' in obj && 'id' in obj;

export const UploadOfferTableActions = ({ onRemove, selectedFile }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles.table');
  const dispatch = useDispatch();
  let usePreviewFileProps;

  // Check if the selected file is a local file or one stored on the server
  if (isLocalFile(selectedFile)) {
    usePreviewFileProps = selectedFile.file;
  } else {
    usePreviewFileProps = { fileId: selectedFile.id, type: selectedFile.type };
  }
  const [previewFile] = usePreviewFile(usePreviewFileProps);

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onRemove(selectedFile.id);
    dispatch(ariaLiveAddAnnouncement(t('ariaLiveFileRemoved')));
  };

  return (
    <Flex as="ul" justifyContent="center" listStyleType="none">
      <Box
        as="li"
        position="relative"
        mr="1px"
        px={3}
        _after={{
          content: "''",
          position: 'absolute',
          top: '14px',
          right: '-1px',
          bottom: '14px',
          width: '1px',
          backgroundColor: 'background.neutral',
        }}
      >
        <IconButtonComponent
          icon={<ViewIcon boxSize={6} display="block" />}
          label={t('preview')}
          onClick={previewFile}
          data-testid={'Button-Preview-Contract-Icon'}
        />
      </Box>

      <Box as="li" position="relative" px={3}>
        <IconButtonComponent
          icon={<DeleteIcon boxSize={6} display="block" />}
          label={t('delete')}
          onClick={handleDelete}
          data-testid={'Button-Delete-Contract-Icon'}
        />
      </Box>
    </Flex>
  );
};

export const GeneratedContractTableActions = ({ onRemove, selectedFile }: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles.table');

  return (
    <Flex as="ul" justifyContent="center" listStyleType="none">
      <Box as="li" position="relative" px={3}>
        <button onClick={() => onRemove(selectedFile.id)}>
          <IconButtonComponent
            icon={<DeleteIcon boxSize={6} display="block" />}
            label={t('delete')}
          />
        </button>
      </Box>
    </Flex>
  );
};
