import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import paths from 'constants/paths';
import {
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_DATA_SECURITY,
} from 'modules/Inquiry/Form/formFields';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';
import { mustBeTrue } from 'utils/validators';

export const BfsServiceCompletingCompanyDetails: React.FC = () => {
  const tCheckBox = useTranslations(
    'pages.companyDetails.sections.termsAndConditions.bfsService.fields',
  );

  const t = useTranslations();

  return (
    <FormSection
      name={FormSections.companyDetails}
      sectionNumber={2}
      title={t('pages.userProfile.sections.completingCompanyDetails.bfsService.section3')}
    >
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_CONSENT}
          validate={mustBeTrue(t('errors.mustBeTrue'))}
          text={
            <span>
              {tCheckBox('contactPersonSecurityConsent.label', {
                termsAndConditions: (
                  <a href={paths.termsAndConditions}>
                    {tCheckBox('contactPersonSecurityConsent.links.termsAndConditions')}
                  </a>
                ),
                AGB: <a href={paths.privacyPolicy}>AGB</a>,
              })}
            </span>
          }
        />
      </FormRow>
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_DATA_SECURITY}
          validate={mustBeTrue(t('errors.mustBeTrue'))}
          text={
            <span>
              {tCheckBox('legalNotice.label', {
                privacyPolicy: (
                  <a href={paths.privacyPolicy}>{tCheckBox('legalNotice.links.privacyPolicy')}</a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
    </FormSection>
  );
};
